import React from "react";
import {
  Button,
  Container,
  Grid,
  Header
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { routes } from "../router/routes";

/**
 * Public home page
 */
export function Home() {
  return (
    <Container>
      <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header size="huge" color="yellow" textAlign="center">
            Kirjaudu tai tilaa tutkimus
          </Header>
          <div style={{
            display: 'flex',
            flexFlow: 'column',
            maxWidth: '250px',
            margin: '0 auto'
          }}>
            <Button
              primary
              as={ Link }
              to={ routes.CREATE_ORDER_PUBLIC }
            >TILAA TUTKIMUS</Button>
            <Button
              primary
              as={ Link }
              to={ routes.USER_LOGIN }
            >KIRJAUDU SISÄÄN</Button>
          </div>
        </Grid.Column>
      </Grid>
    </Container>
  );
}
