import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { upperFirst, isEmpty, differenceWith, toPairs, isEqual, includes, map } from 'lodash';
import queryString from 'query-string';
import {
  Container,
  Dropdown,
  Grid,
  Header,
  Image,
  Segment,
  Confirm,
  Message,
  Button
} from "semantic-ui-react";
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import { routes } from '../router/routes';
import {
  createOrder,
  clearOrder,
  updateOrder,
  editOrder,
  storeUnregisteredOrder
} from '../actions/order';
import {
  insertOrder,
  checkBusinessId,
  fetchDraft
} from '../actions/orders';
import { OrderForm } from '../components/OrderForm';
import { PageContent } from '../components/PageContent';
import logo from '../assets/images/taklab-logo-tarkenne-musta.png';
import { OrderSubmitModal } from "../components/OrderSubmitModal";
import { AppLoader } from '../components/AppLoader';
import { validateOrder } from '../validation/validate';
import { InfoModal } from "../components/InfoModal";
import { LoginModal } from "../components/LoginModal";
import { usePrevious } from "../utils";

/**
 * Order editor component
 */
export function Order() {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const orderTypeId = parseInt(query.orderTypeId);
  const edit = parseInt(query.edit);
  const [nextLocation, setNextLocation] = useState(false);
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false);
  const [showDraftConfirmation, setShowDraftConfirmation] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showBusinessIdmodal, setShowBusinessIdModal] = useState(false);
  const [navigationData, setNavigationData] = useState({
    confirmed: false,
    location: null
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const order = useSelector((state) => state.order);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isFetching = useSelector((state) => state.metadata.isFetching || state.orders.isFetching);
  const orderTypes = useSelector((state) => state.metadata.data.orderType || []);
  const deliveryType = useSelector((state) => state.metadata.data.deliveryType || []);

  const dispatch = useDispatch();

  const previousOrderValue = usePrevious(order);

  const orderType = order.orderTypeId;

  useEffect(() => {
    if(orderTypeId) {
      dispatch(createOrder(orderTypeId));
    } else {
      dispatch(clearOrder());
    }
    setErrors({});
    setNavigationData({
      confirmed: false,
      location: null
    });
    setNextLocation(null);
  }, [dispatch, orderTypeId, setErrors, setNextLocation, setNavigationData]);

  useEffect(() => {
    const { location } = navigationData;
    if (location) {
      history.push(location);
    }
  }, [history, navigationData]);

  useEffect(() => {
    return function cleanup() {
      dispatch(clearOrder());
    }
  }, [dispatch])

  useEffect(() => {
    async function fetchData(id) {
      try {
        const order = await dispatch(fetchDraft(id));
        dispatch(editOrder(order));
      } catch(error) {
        history.push(routes.ARCHIVE);
      }
    }
    if (edit) {
      fetchData(edit);
    }
  }, [dispatch, edit, history]);

  useEffect(() => {
    if (order && previousOrderValue) {
      const orderDiff = differenceWith(toPairs(order), toPairs(previousOrderValue), isEqual);
      const shouldValidate = (
        !isEmpty(errors) && (
          (includes(map(orderDiff, (item) => item[0]), 'buildingTypeId') && order.buildingTypeId !== 1)
          || (includes(map(orderDiff, (item) => item[0]), 'buildingMaterialId') && order.buildingMaterialId !== 1)
        )
      );
      if (shouldValidate) {
        console.log('should validate', orderDiff);
        setErrors(validateOrder(order, orderTypeId) || {});
      }
    }
  }, [order]);

  let orderForm;
  let formName;
  if (orderType) {
    orderForm = (
      <OrderForm
        key={ orderType }
        errors={ errors }
        isAuthenticated={ isAuthenticated }
        orderTypeId={ orderType }
        onSubmit={ handleSubmit }
        onSaveDraft={ handleSaveDraft }
        onBlur={ handleBlur }
      />
    );
    formName = orderTypes.find(type => type.id === orderType).description;
  }

  const orderTypeOptions = orderTypes.map((type) => ({
      key: type.id,
      text: type.description,
      value: type.id
    }));

  const deliveryTypeOptions = deliveryType.map((type) => ({
      key: type.id,
      text: type.deliveryType,
      value: type.id
    }));

  function handleSubmit() {
    const err = validateOrder(order, orderType);
    setErrors(err || {});
    if (!err) {
      setShowSubmitModal(true);
    }
  }

  async function handleSubmitOrder() {
    try {
      await dispatch(insertOrder(order, 'SUBMITTED'));
      setShowSubmitConfirmation(true);
    } catch(error) {
      // The error's been handled, this is just to let the page know about it
    }
  }

  async function handleSaveDraft() {
    const err = validateOrder(order, orderType);
    setErrors(err || {});
    if (!err) {
      try {
        await dispatch(insertOrder(order, 'DRAFT'));
        setShowDraftConfirmation(true);
      } catch(error) {
        // The error's been handled, this is just to let the page know about it
      }
    }
  }

  async function checkRegisteredBusinessIds(businessId) {
    try {
      await dispatch(checkBusinessId(businessId));
      dispatch(storeUnregisteredOrder(order));
      setShowBusinessIdModal(true);
    } catch(error) {
      // Business id is not registered, continue normally
    }
  }

  function handleBlur(event) {
    if (!isEmpty(errors)) {
      setErrors(validateOrder(order, orderType) || {});
    }
    if (!isAuthenticated && event.target.name === 'businessId' && event.target.value) {
      checkRegisteredBusinessIds(event.target.value);
    }
  }

  function handleNavConfirmation() {
    setNavigationData({
      confirmed: true,
      location: nextLocation
    });
  }

  function handleNavCancel() {
    setNextLocation(null);
  }

  function handleFormChange(value) {
    history.push({ pathname: location.pathname, search: `?orderTypeId=${value}`})
  }

  return (
    <Container className="page-wrapper">
        <AppLoader
          active={ isFetching }
          page={ true }
        />
        <Prompt
          when={ !!orderType && !navigationData.confirmed}
          message={ (location) => {
            setNextLocation(location);
            return false;
          }}
        />
        <PageContent maxWidth={ 1000 }>
          <Grid.Row>
            <Grid.Column width={ 9 }>
              { edit ?
                <Header size='huge' color='yellow'>Muokkaa lomaketta</Header> :
                <Header size='huge' color='yellow'>Tilaa tutkimus</Header>
              }
            </Grid.Column>
            { edit ?
              <Grid.Column
                textAlign="right"
                width={ 7 }>
                <Button
                  onClick={ history.goBack }>
                    Takaisin
                </Button>
              </Grid.Column> :
              <Grid.Column width={ 7 }>
                <Dropdown
                    fluid
                    selection
                    basic
                    placeholder="Valitse tutkimus"
                    value={ orderType || '' }
                    options={ orderTypeOptions }
                    className="order-form-selector"
                    onChange={ (event, { value }) => handleFormChange(value) }
                />
              </Grid.Column>
            }
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={ 16 }>
              <Segment
                className="segment-rectangle"
                padded
              >
                <Grid
                  stackable
                >
                  <Grid.Row>
                    <Grid.Column
                      width={ 4 }
                    >
                      <Image
                        src={ logo }
                        style={{
                          width: '100%',
                          maxWidth: '300px',
                          margin: "0 auto"
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column
                      width={ 12 }
                      verticalAlign={ orderType ? "bottom" : "top" }
                    >
                      { !orderType ?
                        <>
                          <p>Voit lähettää tilauksesi käyttämällä TAKLAB-järjestelmän sähköisiä lomakkeita. Valitse ensin tilattavan lomakkeen tyyppivetovalikosta ja täytä sitten lomakkeeseen tarvittavat tiedot. Voit lisätä, poistaa ja muokata näyterivejä vapaasti ennen lomakkeen lähettämistä. Kirjoita näytepusseihin kohteen osoite tai projektinumero. Numeroi näytteet 1:stä eteenpäin, jotta laboratorio osaa yhdistää näytteet tulleeseen tilaukseen.</p>
                          <p>Lähetä valmis tilaus painamalla <strong>Lähetä tilaus</strong>, minkä jälkeen sinun tulee vielä valita näytteiden toimitustapa. Voit myös tallentaa keskeneräisen lomakkeen TAKLAB-järjestelmään painamalla <strong>Tallenna</strong>. Tilausluonnos tallentuu TAKLAB-järjestelmään, ja voit muokata tilauksen tietoja ja näyterivejä myöhemmin ennen tilauksen lähettämistä.</p>
                          <Header as="h3" textAlign="right">Valitse tilattavan tutkimuksen tyyppi yllä olevasta vetovalikosta</Header>
                        </> :
                        <Header as="h1" textAlign="right">{ upperFirst(formName) }</Header>
                      }
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={ 16 }>
                      { !isAuthenticated &&
                          <Message>
                              Jos sinulla on TAKLAB-käyttäjätili, kirjaudu sisään ennen lomakkeen täyttämistä, niin tilauksesi ja valmistuneet tutkimustulokset tallennetaan käyttäjätilillesi.
                              <Container textAlign="right">
                                <Button
                                  onClick={ () => {
                                    setShowLoginModal(true);
                                  } }
                                >Kirjaudu sisään</Button>
                              </Container>
                          </Message>
                      }
                      <ReactCSSTransitionReplace
                        transitionName="fade-wait"
                        transitionEnterTimeout={1000}
                        transitionLeaveTimeout={200}
                      >
                        { orderForm }
                      </ReactCSSTransitionReplace>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </PageContent>
        <OrderSubmitModal
          options={ deliveryTypeOptions }
          value={ order.deliveryTypeId }
          onClose={ () => setShowSubmitModal(false) }
          onChange={ (deliveryTypeId) => dispatch(updateOrder({ deliveryTypeId })) }
          open={ showSubmitModal }
          onSubmit={ handleSubmitOrder }
          orderType={ orderType }
        />
        <Confirm
          open={ !!nextLocation }
          content={ 'Haluatko varmasti poistua lomakkeesta? Jos poistut, menetät lomakkeeseen täytetyt tallentamattomat tiedot.'}
          confirmButton="Poistu"
          onConfirm={ handleNavConfirmation }
          cancelButton="Palaa lomakkeeseen"
          onCancel={ handleNavCancel }
        />
        <Confirm
          open={ showBusinessIdmodal }
          content={ 'Syöttämäsi Y-tunnus on jo rekisteröity TAKLAB-tilausjärjestelmään. Jos lähetät tilauksen kirjautumatta, et voi tarkastella tätä tilausta tai sen tutkimustuloksia myöhemmin TAKLAB-yritystililläsi.'}
          confirmButton="Kirjaudu sisään"
          onConfirm={ () => {
            setShowLoginModal(true)
            setShowBusinessIdModal(false)
          } }
          cancelButton="Jatka kirjautumatta"
          onCancel={ () => {
            setShowBusinessIdModal(false);
          } }
        />
        <InfoModal
          header={ 'Kiitos tilauksestasi!' }
          content={ 'Tilauksesi on lähetetty.' }
          open={ showSubmitConfirmation }
          onClose={  () => setShowSubmitConfirmation(false) }
          onConfirm={ () => {
            const location = isAuthenticated ? routes.USER_DASHBOARD : routes.HOME;
            setNavigationData({
              confirmed: true,
              location
            });
          } }
        />
        <InfoModal
          header={ 'Lomake tallennettu' }
          content={ 'Lomake on tallennettu luonnoksena. Voit nyt jatkaa lomakkeen muokkaamista. Voit myös palata muokkaamaan lomaketta myöhemmin Omasta tilausarkistosta.' }
          open={ showDraftConfirmation }
          onClose={  () => setShowDraftConfirmation(false) }
          onConfirm={ () => setShowDraftConfirmation(false) }
        />
        <LoginModal
          open={ showLoginModal }
          onClose={ () => {
            setShowLoginModal(false);
          } }
          onLogin={ () => dispatch(storeUnregisteredOrder(order)) }
        />
    </Container>
  );
}

