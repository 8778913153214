import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

/**
 * A modal with info content and a button to close the modal
 * @param {object} props Component props
 * @param {string} props.content Modal body content
 * @param {function} props.onClose Modal close event handler
 * @param {function} props.onConfirm Modal confirm event handler
 * @param {boolean} props.open Indicates if modal is shown
 * @param {string} [props.buttonText=OK] Modal close button content
 * @param {string} [props.header] Tooltip content
 */
export function InfoModal({
    content,
    onClose,
    onConfirm,
    open,
    buttonText = 'OK',
    header,
}) {
    return (
        <Modal
            closeOnDimmerClick={ false }
            open={ open }
            onClose={ onClose }
        >
            { header && <Modal.Header>{ header }</Modal.Header> }
            <Modal.Content>
                <div>{ content }</div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    primary
                    onClick={  onConfirm }>
                    { buttonText }
                </Button>
            </Modal.Actions>
        </Modal>
    )
}