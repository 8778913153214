import React from 'react';
import { capitalize } from 'lodash';
import {
    Checkbox,
    Form,
    Header
} from "semantic-ui-react";
import { OrderType, fieldNames } from '../../enums';
import { microbial } from '../../enums';

/**
 * Order form section with the building data form fields
 * @param {object} props Component props
 * @param {object} props.errors Form field errors.
 * @param {function} props.metadata Order metadata to use for displaying form field options.
 * @param {number} props.onBlur Form field blur event handler.
 * @param {number} props.onChange Form field change event handler.
 * @param {number} props.order The order to edit.
 * @param {number} props.orderTypeId Type of the order to edit.
 */
export function OrderBuilding({
    errors,
    metadata,
    onBlur,
    onChange,
    order,
    orderTypeId
}) {
    const buildingTypeOptions = metadata.buildingType.map((type) => ({
        text: capitalize(type.buildingType),
        value: type.id
    }));

    const buildingMaterialOptions = metadata.buildingMaterial.map((type) => ({
        text: capitalize(type.buildingMaterial),
        value: type.id
    }));

    const ventilationTypeOptions = metadata.ventilationType.map((type) => ({
        text: capitalize(type.ventilationType),
        value: type.id
    }));

    const locationTypeOptions = metadata.locationType.map((type) => ({
        text: capitalize(type.locationType),
        value: type.id
    }));

    return (
        <>
            <Header as="h2">Kohteen tiedot</Header>
            <Form.Group widths='equal'>
                <Form.Input
                    fluid
                    error={ errors.buildingName }
                    name="buildingName"
                    value={ order.buildingName || '' }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    label={ fieldNames.building.BUILDING_NAME }
                    placeholder={ fieldNames.building.BUILDING_NAME }
                    maxLength="255"
                />
                <Form.Input
                    required
                    fluid
                    error={ errors.buildingAddress}
                    name="buildingAddress"
                    value={ order.buildingAddress || '' }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    label={ fieldNames.building.BUILDING_ADDRESS }
                    placeholder={ fieldNames.building.BUILDING_ADDRESS }
                    maxLength="255"
                />
            </Form.Group>
            { (microbial.includes(orderTypeId) || orderTypeId === OrderType.VOLATILE_VOC) &&
                <Form.Group widths='equal'>
                    <Form.Select
                        required
                        fluid
                        error={ errors.buildingTypeId }
                        name="buildingTypeId"
                        value={ order.buildingTypeId }
                        onChange={ onChange }
                        onBlur={ onBlur }
                        label={ fieldNames.building.BUILDING_TYPE }
                        placeholder={ fieldNames.building.BUILDING_TYPE }
                        options={ buildingTypeOptions }
                    />
                    <Form.Input
                        required
                        disabled={ order.buildingTypeId !== 1 }
                        fluid
                        error={ errors.buildingTypeStr }
                        name="buildingTypeStr"
                        value={ order.buildingTypeStr || '' }
                        onChange={ onChange }
                        onBlur={ onBlur }
                        label={ fieldNames.building.BUILDING_TYPE_STR }
                        placeholder={ fieldNames.building.BUILDING_TYPE_STR }
                        maxLength="255"
                    />
                </Form.Group>
            }
            { orderTypeId === OrderType.VOLATILE_MICROBE &&
                <>
                    <Form.Group widths='equal'>
                        <Form.Select
                            required
                            fluid
                            error={ errors.locationTypeId }
                            name="locationTypeId"
                            value={ order.locationTypeId }
                            onChange={ onChange }
                            onBlur={ onBlur }
                            label={ fieldNames.building.LOCATION_TYPE }
                            placeholder={ fieldNames.building.LOCATION_TYPE }
                            options={ locationTypeOptions }
                        />
                        <Form.Field
                            style={{ display: 'flex', alignItems: 'flex-end' }}
                        >
                            <Checkbox
                                name="winterConditions"
                                label={ fieldNames.building.WINTER_CONDITIONS }
                                onChange={ onChange }
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Select
                            required
                            fluid
                            error={ errors.buildingMaterialId}
                            name="buildingMaterialId"
                            value={ order.buildingMaterialId }
                            onChange={ onChange }
                            onBlur={ onBlur }
                            label={ fieldNames.building.BUILDING_MATERIAL }
                            placeholder={ fieldNames.building.BUILDING_MATERIAL }
                            options={ buildingMaterialOptions }
                        />
                        <Form.Input
                            required
                            disabled={ order.buildingMaterialId !== 1 }
                            error={ errors.buildingMaterialStr }
                            fluid
                            name="buildingMaterialStr"
                            value={ order.buildingMaterialStr || '' }
                            onChange={ onChange }
                            onBlur={ onBlur }
                            label={ fieldNames.building.BUILDING_MATERIAL_STR }
                            placeholder={ fieldNames.building.BUILDING_MATERIAL_STR }
                            maxLength="255"
                        />
                    </Form.Group>
                    <Form.Group widths={2}>
                        <Form.Input
                            required
                            fluid
                            error={ errors.timeOfBuilding }
                            name="timeOfBuilding"
                            value={ order.timeOfBuilding || ''}
                            onChange={ onChange }
                            onBlur={ onBlur }
                            label={ fieldNames.building.TIME_OF_BUILDING }
                            placeholder={ fieldNames.building.TIME_OF_BUILDING }
                            maxLength="100"
                        />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Select
                            required
                            fluid
                            error={ errors.ventilationTypeId }
                            name="ventilationTypeId"
                            value={ order.ventilationTypeId }
                            onChange={ onChange }
                            onBlur={ onBlur }
                            label={ fieldNames.building.VENTILATION_TYPE }
                            placeholder={ fieldNames.building.VENTILATION_TYPE }
                            options={ ventilationTypeOptions }
                        />
                        <Form.Field
                            style={{ display: 'flex', alignItems: 'flex-end' }}
                        >
                            <Checkbox
                                name="ventilationRunning"
                                label={ fieldNames.building.VENTILATION_RUNNING }
                                onChange={ onChange }
                            />
                        </Form.Field>
                    </Form.Group>
                </>
            }
        </>
    )
}
