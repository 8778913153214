import React from 'react';
import { partial, uniq } from 'lodash';
import validate from 'validate.js';
import { constraints as itemConstraints } from './itemConstraints';
import { constraints as orderConstraints } from './orderConstraints';
import { constraints as loginConstraints } from './loginConstraints';
import {
    constraints as signupConstraints,
    updateConstraints as profileUpdateConstraints,
    passwordUpdateConstraints,
    initPasswordResetConstraints,
    passwordResetConstraints,
    samplerUpdateConstraints,
    samplerSignupConstraints
} from './signupConstraints';


validate.validators.numericality.notValid = 'Syötä kelvollinen luku';
validate.validators.numericality.notInteger = 'Syötä kokonaisluku';
validate.validators.numericality.notGreaterThan = 'Luvun tulee olla suurempi kuin %{count}';
validate.validators.numericality.notGreaterThanOrEqualTo = 'Luvun tulee olla suurempi tai yhtäsuuri kuin %{count}';
validate.validators.numericality.notLessThanOrEqualTo = 'Luvun tulee olla pienempi tai yhtäsuuri kuin %{count}';
validate.validators.hasUniqueProperty = function hasUniqueProperty(value, options, key, attributes) {
    const duplicates = options.props
        .map(prop => {
            const values = value.map(item => item[prop]).filter(value => value !== undefined);
            return values.length !== uniq(values).length;
        })
        .filter(duplicate => !!duplicate);
    if (duplicates.length) {
        return options.message || `Properties ${ options.props.toString() } must be unique.`;
    }
    return;
}

validate.validators.array = function array(items, itemConstraints) {
    const arrayItemErrors = items.reduce((errors, item, index) => {
      const error = validate.single(item, itemConstraints)
      if (error) {
          return [
              ...errors,
              error
          ];
      }
      return errors
    }, [])

    return arrayItemErrors;
}

function runValidation(constraints, data) {
    const errors = validate(data, constraints, { fullMessages: false });
    return formatErrors(errors);
}

export function validateOrderItem(item, orderType) {
    const constraints = itemConstraints[orderType];
    return runValidation(constraints, item);
}

export function validateOrder(order, orderType) {
    console.log(order);
    const constraints = orderConstraints[orderType];
    return runValidation(constraints, order);
}

export const validateLogin = partial(runValidation, loginConstraints);

export const validateSignup = partial(runValidation, signupConstraints);

export const validateSamplerSignup = partial(runValidation, samplerSignupConstraints);

export const validateProfileUpdate = partial(runValidation, profileUpdateConstraints);

export const validateSamplerProfileUpdate = partial(runValidation, samplerUpdateConstraints);

export const validatePasswordUpdate = partial(runValidation, passwordUpdateConstraints);

export const validateInitPasswordReset = partial(runValidation, initPasswordResetConstraints);

export const validatePasswordReset = partial(runValidation, passwordResetConstraints);

const formatErrors = (errors) => {
    if (errors) {
        const formattedErrors = {};
        Object.entries(errors).forEach(([key, value]) => {
            formattedErrors[key] = value.map((error, index) => <div key={`${key}+${index}`}>{ error }</div>)
        });
        return formattedErrors;
    }
    return errors;
}