import { METADATA_REQUEST, METADATA_FAILURE, METADATA_SUCCESS } from "./actions";
import { get } from "../api";
import { wait } from "../utils";

export function fetchMetadata() {
    return async (dispatch) => {
        dispatch(onFetchMetadata());
        try {
            const data = await get('/orders/metadata/v2');
            dispatch(onFetchMetadataSuccess(data));
        } catch(error) {
            dispatch(onFetchMetadataFailure(error));
        };
    }
}

function onFetchMetadata() {
    return {
        type: METADATA_REQUEST
    };
}

function onFetchMetadataSuccess(data) {
    return {
        type: METADATA_SUCCESS,
        data
    };
}

function onFetchMetadataFailure(error) {
    return {
        type: METADATA_FAILURE,
        error
    };
}