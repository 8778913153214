import React from "react";
import { Link } from "react-router-dom";
import { filter, sortBy } from "lodash";
import { Container, Header, Segment, Button, Message, Form } from "semantic-ui-react";
import { routes } from "../router/routes";
import { clearOrders, fetchOrders, fetchCompanyOrders } from "../actions/orders";
import { clearSamplers, fetchSamplers } from "../actions/samplers";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { AppLoader } from "../components/AppLoader";
import UserOrderTable from "../components/UserOrderTable";
import UserSamplerTable from "../components/UserSamplerTable";
import moment from "moment";
import { UserProfileForm } from "../components/UserProfileForm";
import { useRef, useState } from "react";
import { scrollToRef } from "../utils";
import { InfoModal } from "../components/InfoModal";
import { initSamplerInvitation } from "../actions/auth";
import { validateInitPasswordReset as validateEmailAddress } from "../validation/validate";
import { isEmpty } from "lodash";

/**
 * Customer dashboard page to display latest completed and submitted orders and profile information.
 */
export function Dashboard() {
    const user = useSelector((state) => state.auth.user);
    const isFetching = useSelector((state) => state.orders.isFetching);
    const orders = useSelector((state) => state.orders.orders);
    const companyOrders = useSelector((state) => state.orders.companyOrders);
    const samplers = useSelector((state) => state.samplers.samplers);
    const dispatch = useDispatch();
    const completedRef = useRef(null);
    const completed = orders.filter((order) => order.status === "COMPLETED" && !order.resultSeen);
    const latestSubmittedCompanyOrders = sortBy(
        filter(companyOrders, (order) => order.status === "SUBMITTED"),
        [(order) => moment(order.createdAt)]
    ).slice(-5);
    const latestCompletedCompanyOrders = sortBy(
        filter(companyOrders, (order) => order.status === "COMPLETED"),
        [(order) => moment(order.createdAt)]
    ).slice(-5);

    useEffect(() => {
        dispatch(clearOrders());
        dispatch(fetchOrders());
        dispatch(fetchCompanyOrders());
        dispatch(clearSamplers());
        dispatch(fetchSamplers());
    }, [dispatch]);

    // TODO: koko sampler invitation formi omaksi erilliseksi komponentiksi?
    const [samplerInviteeAddressObj, setSamplerInviteeAddressObj] = useState({});
    const [showInvitationConfirmation, setShowInvitationConfirmation] = useState(false);
    const [errors, setErrors] = useState({});

    async function handleInvitationSubmit() {
        const err = validateEmailAddress(samplerInviteeAddressObj);
        setErrors(err || {});
        if (!err) {
            try {
                await dispatch(initSamplerInvitation(samplerInviteeAddressObj.email));
                setShowInvitationConfirmation(true);
            } catch (error) {
                // Something went wrong
            }
        }
    }

    function handleBlur() {
        if (!isEmpty(errors)) {
            const err = validateEmailAddress(samplerInviteeAddressObj);
            setErrors(err || {});
        }
    }

    function handleInvitationConfirmationClose() {
        setShowInvitationConfirmation(false);
        setSamplerInviteeAddressObj({ email: "" });
    }

    return (
        <Container className="page-wrapper">
            <AppLoader active={isFetching} page={true} />
            {completed.length > 0 && (
                <Message positive>
                    <p>Sinulla on {completed.length} kpl uusia tutkimustuloksia.</p>
                    <Button positive onClick={() => scrollToRef(completedRef, 90)}>
                        Näytä
                    </Button>
                </Message>
            )}
            <Header size="huge" color="yellow">
                TAKLAB-tilausjärjestelmä
            </Header>
            <Segment className="segment-rectangle">
                <p>
                    Tervetuloa käyttämään TAKLAB-tilausjärjestelmää! TAKLAB-tilausjärjestelmässä
                    voit täyttää tilauslomakkeen, lisätä haluamasi määrän näyterivejä ja valita
                    näytteiden toimitustavan. Kun toimitat näytteet valitsemaasi näytepisteeseen,
                    saat tulokset PDF-tiedostona suoraan TAKLAB-järjestelmään sekä sähköpostiisi
                    heti tutkimusten valmistuttua. Asbestinäytteiden tuloksista lähetämme myös
                    tekstiviestin puhelimeen välittömästi tulosten valmistuttua.
                </p>
                <p>
                    TAKLAB-järjestelmään voit myös tallentaa luonnoksina keskeneräisiä
                    tilauslomakkeita. Siten pystyt myöhemmin muokkaamaan lomakkeen tietoja ja
                    näyterivejä ennen tilauksen lähettämistä.
                </p>
                <p>
                    TAKLAB-järjestelmän tilausarkistossa voit selata kaikkia lähettämiäsi tilauksia
                    sekä valmistuneita tutkimuksia. Voit myös muokata tai poistaa järjestelmään
                    tallentamiasi keskeneräisiä tilauslomakkeita.
                </p>
                <Container textAlign="right" className="button-wrapper">
                    <Button primary as={Link} to={routes.USER_HELP}>
                        Lue ohjeet
                    </Button>
                    <Button primary as={Link} to={routes.CREATE_ORDER}>
                        Tilaa tutkimus
                    </Button>
                </Container>
            </Segment>
            <div style={{ visibility: "hidden" }} ref={completedRef}></div>

            <Header size="huge" color="yellow">
                Yrityksen valmistuneet tutkimukset
            </Header>
            <Segment className="segment-rectangle">
                <Header as="h3">Viimeisimmät valmistuneet tutkimukset</Header>
                <UserOrderTable
                    orders={latestCompletedCompanyOrders}
                    status={"completed"}
                    showResultCount={false}
                    missingSpecifier="Ei valmistuneita tutkimuksia"
                />
                <Container textAlign="right" className="button-wrapper">
                    <Button primary as={Link} to={`${routes.COMPANY_ARCHIVE}/completed`}>
                        Näytä kaikki
                    </Button>
                </Container>
            </Segment>

            <Header size="huge" color="yellow">
                Yrityksen lähetetyt tilaukset
            </Header>
            <Segment className="segment-rectangle">
                <Header as="h3">Viimeisimmät lähetetyt tilaukset</Header>
                <UserOrderTable
                    orders={latestSubmittedCompanyOrders}
                    status={"submitted"}
                    showResultCount={false}
                    missingSpecifier="Ei lähetettyjä tilauksia"
                />
                <Container
                    textAlign="left"
                    className="button-wrapper"
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <div style={{ fontSize: "17px" }}>
                        Huom! Kun tilaus on valmistunut, löydät sen{" "}
                        <Link
                            to={`${routes.COMPANY_ARCHIVE}/completed`}
                            style={{ color: "blue", textDecoration: "underline" }}
                        >
                            valmistuneista tutkimuksista.
                        </Link>
                    </div>
                    <div style={{ minWidth: "150px", textAlign: "right" }}>
                        <Button primary as={Link} to={`${routes.COMPANY_ARCHIVE}/submitted`}>
                            Näytä kaikki
                        </Button>
                    </div>
                </Container>
            </Segment>

            <Header size="huge" color="yellow">
                Näytteenottajat
            </Header>
            <Segment className="segment-rectangle">
                <UserSamplerTable
                    samplers={samplers}
                    status={"completed"}
                    missingSpecifier="Ei näytteenottajia"
                />
            </Segment>
            <Header size="huge" color="yellow">
                Näytteenottajien lisääminen
            </Header>
            <Segment className="segment-rectangle">
                <Form style={{ maxWidth: 450 }} size="large" onSubmit={handleInvitationSubmit}>
                    <Segment textAlign="left">
                        <Form.Field>
                            <Form.Input
                                name="password"
                                label="Sähköpostiosoite"
                                error={errors.email}
                                value={samplerInviteeAddressObj.email || ""}
                                fluid
                                icon="user"
                                iconPosition="left"
                                placeholder="Kutsuttavan näytteenottajan sähköpostiosoite"
                                onChange={(event) =>
                                    setSamplerInviteeAddressObj({
                                        email: event.target.value,
                                    })
                                }
                                onBlur={handleBlur}
                                maxLength="100"
                            />
                        </Form.Field>
                        <Button primary>Lähetä kutsu</Button>
                    </Segment>
                </Form>
            </Segment>

            <Header size="huge" color="yellow">
                Yritystilin tiedot
            </Header>
            <Segment className="segment-rectangle">
                <UserProfileForm editProfile={false} user={user} />
                <Container textAlign="right" className="button-wrapper">
                    <Button primary as={Link} to={routes.PROFILE}>
                        Yritystilin hallinta
                    </Button>
                </Container>
            </Segment>
            <InfoModal
                content={`Kutsu rekisteröityä näytteenottajaksi on lähetetty osoitteeseen ${samplerInviteeAddressObj.email}.`}
                open={showInvitationConfirmation}
                onClose={handleInvitationConfirmationClose}
                onConfirm={handleInvitationConfirmationClose}
            />
        </Container>
    );
}
