import React from 'react';
import {
    Checkbox,
    Grid,
    Header
} from "semantic-ui-react";
import { fieldNames } from '../../enums';

export function OrderBuildingView({ order }) {
    const {
        buildingName,
        buildingAddress,
        buildingType,
        buildingTypeStr,
        buildingMaterial,
        buildingMaterialStr,
        locationType,
        timeOfBuilding,
        ventilationType,
        ventilationRunning,
        winterConditions
    } = order;

    return (
        <Grid padded="vertically">
            <Header as="h2">Kohteen tiedot</Header>
            <Grid.Row columns="2">
                <Grid.Column>
                    <label>{ fieldNames.building.BUILDING_NAME }</label>
                    <p>{ buildingName || '-' }</p>
                </Grid.Column>
                <Grid.Column>
                    <label>{ fieldNames.building.BUILDING_ADDRESS }</label>
                    <p>{ buildingAddress }</p>
                </Grid.Column>
            </Grid.Row>
            { buildingType &&
                <Grid.Row columns="2">
                    <Grid.Column>
                        <label>{ fieldNames.building.BUILDING_TYPE }</label>
                        <p>{ buildingType }</p>
                    </Grid.Column>
                    <Grid.Column>
                        <label>{ fieldNames.building.BUILDING_TYPE_STR }</label>
                        <p>{ buildingTypeStr || '-' }</p>
                    </Grid.Column>
                </Grid.Row>
            }
            { locationType &&
                <Grid.Row columns="2">
                    <Grid.Column>
                        <label>{ fieldNames.building.LOCATION_TYPE }</label>
                        <p>{ locationType }</p>
                    </Grid.Column>
                    <Grid.Column>
                        <Checkbox
                            readOnly
                            checked={ winterConditions }
                            label={ fieldNames.building.WINTER_CONDITIONS }
                        />
                    </Grid.Column>
                </Grid.Row>
            }
            { buildingMaterial &&
                <Grid.Row columns="2">
                    <Grid.Column>
                        <label>{ fieldNames.building.BUILDING_MATERIAL }</label>
                        <p>{ buildingMaterial }</p>
                    </Grid.Column>
                    <Grid.Column>
                        <label>{ fieldNames.building.BUILDING_MATERIAL_STR }</label>
                        <p>{ buildingMaterialStr || '-' }</p>
                    </Grid.Column>
                </Grid.Row>
            }
            { timeOfBuilding &&
                <Grid.Row columns="1">
                    <Grid.Column>
                        <label>{ fieldNames.building.TIME_OF_BUILDING }</label>
                        <p>{ timeOfBuilding }</p>
                    </Grid.Column>
                </Grid.Row>
            }
            { ventilationType &&
                <Grid.Row columns="2">
                    <Grid.Column>
                        <label>{ fieldNames.building.VENTILATION_TYPE }</label>
                        <p>{ ventilationType }</p>
                    </Grid.Column>
                    <Grid.Column>
                        <Checkbox
                            readOnly
                            checked={ ventilationRunning }
                            label={ fieldNames.building.VENTILATION_RUNNING }
                        />
                    </Grid.Column>
                </Grid.Row>
            }
        </Grid>
    )
}
