import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Confirm
} from 'semantic-ui-react';
import { routes } from '../router/routes';
import { useState } from 'react';
import { deleteOrder, downloadResult } from '../actions/orders';
import { InfoModal } from './InfoModal';
import { InterActiveTable } from './InterActiveTable';


/**
 * Component to display a list of orders for a customer
 * @param {object} props Component props
 * @param {object[]} props.orders The orders to display in the table
 * @param {integer} props.status The status of orders to display
 */
export default function UserOrderTable({
    orders,
    status,
    showResultCount = true,
    missingSpecifier = ""
}) {

    const columns = [
        {
            content: "Tilaus-\nnumero",
            name: "id",
            sortable: true
        },
        {
            content: "Pvm",
            name: 'updatedAt',
            sortable: true
        },
        {
            content: "Kohteen osoite",
            name: "buildingAddress",
            sortable: true
        },
        {
            content: "Tutkimus",
            name: "description",
            sortable: true
        },
        {
            content: "Näytteen-\nottaja",
            name: "contactName",
            sortable: true
        },        
        {
            content: "Näytteitä",
            name: "itemsCount",
            sortable: true
        }
    ];

    // Remove id from drafts list
    if (status === 'draft') {
        columns.shift();
    }

    orders = orders.filter(order => order.status === status.toUpperCase());

    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
    const [orderToDelete, setOrderToDelete] = useState();
    const history = useHistory();
    const role = useSelector((state) => state.auth.user.role);

    const dispatch = useDispatch();

    function handleDeleteOrder(id) {
        setOrderToDelete(id);
        setShowConfirmDelete(true);
    }

    async function handleConfirmDeleteOrder() {
        try {
          await dispatch(deleteOrder(orderToDelete));
          setShowConfirmDelete(false);
          setShowDeleteSuccessModal(true);
        } catch(error) {
        }
        setOrderToDelete(null);
    }

    async function handleDownloadResult(id, buildingAddress) {
        dispatch(downloadResult(id, buildingAddress));
    }

    function handleEdit(id) {
        history.push(role === 'CUSTOMER' ? `${routes.CREATE_ORDER}?edit=${id}` : `${routes.SAMPLER_CREATE_ORDER}?edit=${id}` );
    }

    return (
        <>
            <InterActiveTable
                title={ status === 'draft' ? 'Lomakkeet' : 'Tilaukset' }
                onView={ (id) => history.push(role === 'CUSTOMER' ? `${routes.VIEW_ORDER}/${id}` : `${routes.SAMPLER_VIEW_ORDER}/${id}` ) }
                onDownload={ status === 'completed' && handleDownloadResult }
                onDelete={ status === 'draft' && handleDeleteOrder }
                onEdit={ status === 'draft' && handleEdit }
                columns={ columns }
                defaultSort={{
                    column: 'updatedAt',
                    direction: 'descending'
                }}
                data={ orders }
                showResultCount={ showResultCount }
                missingSpecifier= { missingSpecifier }
            />
            <Confirm
                open={ showConfirmDelete }
                content={ `Haluatko varmasti poistaa tallennetun lomakkeen?`}
                confirmButton="Poista"
                onConfirm={ handleConfirmDeleteOrder }
                cancelButton="Peruuta"
                onCancel={ () => setShowConfirmDelete(false) }
            />
            <InfoModal
                content={ `Tallennettu lomake poistettu onnistuneesti.` }
                open={ showDeleteSuccessModal }
                onClose={  () => setShowDeleteSuccessModal(false) }
                onConfirm={ () => setShowDeleteSuccessModal(false) }
            />
        </>
    )
}

