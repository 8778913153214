export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_CLIENT = 'LOGOUT_CLIENT';
export const CONFIRM_LOGOUT = 'CONFIRM_LOGOUT';
export const CANCEL_LOGOUT = 'CANCEL_LOGOUT';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const SINGLE_ORDER_REQUEST = 'SINGLE_ORDER_REQUEST';
export const SINGLE_ORDER_REQUEST_SUCCESS = 'SINGLE_ORDER_REQUEST_SUCCESS';
export const SINGLE_ORDER_REQUEST_FAILURE = 'SINGLE_ORDER_REQUEST_FAILURE';
export const ORDERS_REQUEST = 'ORDERS_REQUEST';
export const ORDERS_REQUEST_SUCCESS = 'ORDERS_REQUEST_SUCCESS';
export const COMPANY_ORDERS_REQUEST_SUCCESS = 'COMPANY_ORDERS_REQUEST_SUCCESS';
export const ORDERS_REQUEST_FAILURE = 'ORDERS_REQUEST_FAILURE';
export const INSERT_DRAFT = 'INSERT_DRAFT';
export const INSERT_DRAFT_SUCCESS = 'INSERT_DRAFT_SUCCESS';
export const INSERT_DRAFT_FAILURE = 'INSERT_DRAFT_FAILURE';
export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE';
export const INSERT_ORDER = 'INSERT_ORDER';
export const INSERT_ORDER_SUCCESS = 'INSERT_ORDER_SUCCESS';
export const INSERT_ORDER_FAILURE = 'INSERT_ORDER_FAILURE';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const EDIT_ORDER = 'EDIT_ORDER';
export const ADD_ORDER_ITEM = 'ADD_ORDER_ITEM';
export const CLEAR_ORDER_ERRORS = 'CLEAR_ORDER_ERRORS';
export const DELETE_ORDER_ITEM = 'DELETE_ORDER_ITEM';
export const UPDATE_ORDER_ITEM = 'UPDATE_ORDER_ITEM';
export const METADATA_REQUEST = 'METADATA_REQUEST';
export const METADATA_SUCCESS = 'METADATA_SUCCESS';
export const METADATA_FAILURE = 'METADATA_FAILURE';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const FETCH_SINGLE_USER = 'FETCH_SINGLE_USER';
export const FETCH_SINGLE_USER_SUCCESS = 'FETCH_SINGLE_USER_SUCCESS';
export const FETCH_SINGLE_USER_FAILURE = 'FETCH_SINGLE_USER_FAILURE';
export const UPLOAD_RESULT = 'UPLOAD_RESULT';
export const UPLOAD_RESULT_SUCCESS = 'UPLOAD_RESULT_SUCCESS';
export const UPLOAD_RESULT_FAILURE = 'UPLOAD_RESULT_FAILURE';
export const FETCH_RESULT = 'FETCH_RESULT';
export const FETCH_RESULT_SUCCESS = 'FETCH_RESULT_SUCCESS';
export const FETCH_RESULT_FAILURE = 'FETCH_RESULT_FAILURE';
export const CLEAR_USER = 'CLEAR_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const VALIDATE_RESET_TOKEN = 'VALIDATE_RESET_TOKEN';
export const VALIDATE_RESET_TOKEN_SUCCESS = 'VALIDATE_RESET_TOKEN_SUCCESS';
export const VALIDATE_RESET_TOKEN_FAILURE = 'VALIDATE_RESET_TOKEN_FAILURE';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_REQUEST_END = 'AUTH_REQUEST_END';
export const CHECK_BUSINESS_ID = 'CHECK_BUSINESS_ID';
export const ORDER_REQUEST = 'ORDER_REQUEST';
export const ORDER_REQUEST_END = 'ORDER_REQUEST_END';
export const MIXIN_ORDER = 'MIXIN_ORDER';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const STORE_UNREGISTERED_ORDER = 'STORE_UNREGISTERED_ORDER';
export const CLEAR_UNREGISTERED_ORDER = 'CLEAR_UNREGISTERED_ORDER';
export const PRINT_ORDER = 'PRINT_ORDER';
export const PRINT_ORDER_SUCCESS = 'PRINT_ORDER_SUCCESS';
export const PRINT_ORDER_FAILURE = 'PRINT_ORDER_FAILURE';
export const GET_ME_REQUEST = 'GET_ME_REQUEST';
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const GET_ME_FAILURE = 'GET_ME_FAILURE';
export const SAMPLERS_REQUEST = 'SAMPLERS_REQUEST';
export const SAMPLERS_REQUEST_SUCCESS = 'SAMPLERS_REQUEST_SUCCESS';
export const SAMPLERS_REQUEST_FAILURE = 'SAMPLERS_REQUEST_FAILURE';
export const DELETE_SAMPLER = 'DELETE_SAMPLER';
export const DELETE_SAMPLER_SUCCESS = 'DELETE_SAMPLER_SUCCESS';
export const DELETE_SAMPLER_FAILURE = 'DELETE_SAMPLER_FAILURE';
export const CLEAR_SAMPLERS = 'CLEAR_SAMPLERS';
export const FETCH_SINGLE_SAMPLER_BY_USER = 'FETCH_SINGLE_SAMPLER_BY_USER';
export const FETCH_SINGLE_SAMPLER_BY_USER_SUCCESS = 'FETCH_SINGLE_SAMPLER_BY_USER_SUCCESS';
export const FETCH_SINGLE_SAMPLER_BY_USER_FAILURE = 'FETCH_SINGLE_SAMPLER_BY_USER_FAILURE';
export const CLEAR_SINGLE_SAMPLER_BY_USER = 'CLEAR_SINGLE_SAMPLER_BY_USER';
export const UPDATE_SAMPLER_PROFILE = 'UPDATE_SAMPLER_PROFILE';
export const UPDATE_SAMPLER_PROFILE_SUCCESS = 'UPDATE_SAMPLER_PROFILE_SUCCESS';
export const UPDATE_SAMPLER_PROFILE_FAILURE = 'UPDATE_SAMPLER_PROFILE_FAILURE';


