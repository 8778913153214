import {
    SAMPLERS_REQUEST,
    SAMPLERS_REQUEST_SUCCESS,
    SAMPLERS_REQUEST_FAILURE,
    DELETE_SAMPLER,
    DELETE_SAMPLER_SUCCESS,
    DELETE_SAMPLER_FAILURE,
    CLEAR_SAMPLERS,
    FETCH_SINGLE_SAMPLER_BY_USER,
    FETCH_SINGLE_SAMPLER_BY_USER_FAILURE,
    FETCH_SINGLE_SAMPLER_BY_USER_SUCCESS,
    CLEAR_SINGLE_SAMPLER_BY_USER, // <--- HOX! keksi tälle käyttöä
    UPDATE_SAMPLER_PROFILE,
    UPDATE_SAMPLER_PROFILE_SUCCESS,
    UPDATE_SAMPLER_PROFILE_FAILURE
} from "./actions";
import { post, get, del, put, postFile } from "../api";

export function updateSamplerProfile(id, update) {
    return async dispatch => {
        dispatch(onUpdateSamplerProfile());
        try {
            await put(`/users/sampler/${id}`, update);
            dispatch(onUpdateSamplerProfileSuccess(update));
        } catch(error) {
            dispatch(onUpdateSamplerProfileFailure(error));
        }
    }
}

function onUpdateSamplerProfile() {
    return {
        type: UPDATE_SAMPLER_PROFILE
    };
}

function onUpdateSamplerProfileSuccess(update) {
    return {
        type: UPDATE_SAMPLER_PROFILE_SUCCESS,
        update
    };
}

function onUpdateSamplerProfileFailure(error) {
    return {
        type: UPDATE_SAMPLER_PROFILE_FAILURE,
        error
    };
}

export function fetchOneSampler(id) {
    console.log("fetchOneSampler");
    return async dispatch => {
        dispatch(onFetchOneSampler());
        try {
            const response = await get(`/users/sampler/${id}`, true);
            dispatch(onFetchOneSamplerSuccess(response));
        } catch(error) {
            dispatch(onFetchOneSamplerFailure(error));
        }
    }
}

function onFetchOneSampler() {
    console.log('[onFetchOneSampler]');
    return {
        type: FETCH_SINGLE_SAMPLER_BY_USER
    };
}

function onFetchOneSamplerSuccess(response) {
    console.log('[onFetchOneSamplerSuccess]', response);
    const userSampler = response.user;
    return {
        type: FETCH_SINGLE_SAMPLER_BY_USER_SUCCESS,
        userSampler
    };
}

function onFetchOneSamplerFailure(error) {
    console.log('[onFetchOneSamplerFailure]');
    return {
        type: FETCH_SINGLE_SAMPLER_BY_USER_FAILURE,
        error
    };
}

// GET
// Get all samplers by the logged in user
export function fetchSamplers(status) {
    console.log("fetchSamplers: " + status);
    return async dispatch => {
        let uri = `/user/samplers`;
        if (status) {
            uri = `${uri}/${status}`;
        }
        dispatch(onFetchUserSamplers());
        try {
            const result = await get(uri, true);
            console.log("fetchSamplers result:");
            console.log(JSON.stringify(result));
            dispatch(onFetchUserSamplersSuccess(result));
        } catch(error) {
            dispatch(onFetchUserSamplersFailure(error));
        }
    }
}

function onFetchUserSamplers() {
    console.log('[onFetchUserSamplers]');
    return {
        type: SAMPLERS_REQUEST
    }
}

function onFetchUserSamplersSuccess(result) {
    console.log('[onFetchUserSamplersSuccess]', result);
    const { samplers } = result;
    return {
        type: SAMPLERS_REQUEST_SUCCESS,
        samplers
    }
}

function onFetchUserSamplersFailure(error) {
    console.log('[onFetchUserSamplersFailure]', error);
    return {
        type: SAMPLERS_REQUEST_FAILURE,
        error
    }
}

// DELETE
export function deleteSampler(id) {
    console.log("deleteSampler: " + id);
    return async (dispatch, getState) => {
        //const { isAdmin } = getState().auth;
        try {
            //const uri = isAdmin ? `/orders/${id}` : `/user/orders/${id}`;
            dispatch(ondeleteSampler());
            await del(`/users/sampler/${id}`);
            dispatch(ondeleteSamplerSuccess(id));
        } catch(error) {
            dispatch(ondeleteSamplerFailure(error));
            throw new Error();
        }
    }
}

function ondeleteSampler() {
    return {
        type: DELETE_SAMPLER
    }
}

function ondeleteSamplerSuccess(id) {
    return {
        type: DELETE_SAMPLER_SUCCESS,
        id
    }
}

function ondeleteSamplerFailure(error) {
    return {
        type: DELETE_SAMPLER_FAILURE,
        error
    }
}

export function clearSamplers() {
    console.log('[clearSamplers]');
    return {
        type: CLEAR_SAMPLERS
    }
}
