import { required, singleDecimal, doubleDecimal } from './rules';
import { OrderType } from '../enums';

const airFlow = {
    ...singleDecimal,
    numericality: {
        onlyInteger: true,
        greaterThan: 0
    }
};
const airVolume = {
    presence: {
        ...required
    },
    ...singleDecimal,
    numericality: {
        onlyInteger: true,
        greaterThan: 0
    }
};
const date = {
    presence: {
        ...required,
        message: 'Valitse näytteenottopäivä'
    },
};
const description = {
    presence: {
        ...required
    }
};
const sampleId = {
    presence: {
        ...required,
        message: 'Syötä valitsemasi näytetunnus'
    }
}
const relativeHumidity = {
    ...singleDecimal,
    numericality: {
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 100
    }
};
const sampleHeight = {
    presence: {
        ...required
    }
};
const sampleLocation = {
    presence: {
        ...required
    }
};
const samplingPeriod = {
    numericality: {
        onlyInteger: true,
        greaterThan: 0
    }
};

const sampleTypes = {
    presence: {
        message: 'Valitse tutkimus'
    },
    length: {
        minimum: 1,
        message: 'Valitse tutkimus'
    }
};
const surfaceArea = {
    presence: {
        ...required
    },
    numericality: {
        onlyInteger: true,
        greaterThan: 0
    }
}
const roomTemperature = {
    presence: {
        ...required
    },
    ...singleDecimal
};
const extTemperature = {
    presence: {
        ...required
    },
    ...singleDecimal
};
const pipeNumber = {
    presence: {
        ...required
    },
    numericality: {
        onlyInteger: true,
        greaterThan: 99999,
        lessThan: 1000000
    }    
};
const samplingTime = {
    presence: {
        ...required
    },
    ...doubleDecimal
};
const pumpNumber = {
    presence: {
        ...required
    },
};
const pumpFlow = {
    presence: {
        ...required
    },
    ...singleDecimal    
};

export const constraints = {
    [OrderType.MATERIAL_FIBER]: {
        date,
        sampleId,
        sampleTypes,
        description
    },
    [OrderType.VOLATILE_FIBER]: {
        date,
        sampleId,
        sampleLocation,
        samplingPeriod,
        airFlow,
        airVolume
    },
    [OrderType.MATERIAL_MICROBE]: {
        date,
        sampleId,
        sampleTypes,
        description
    },
    [OrderType.VOLATILE_MICROBE]: {
        date,
        sampleId,
        sampleLocation,
        samplingPeriod: {
            ...samplingPeriod,
            presence: {
                ...required
            }
        },
        roomTemperature,
        extTemperature,
        relativeHumidity,
        sampleHeight
    },
    [OrderType.BRUSHING_MICROBE]: {
        date,
        sampleId,
        surfaceArea,
        sampleTypes,
        description
    },
    [OrderType.BRUSHING_FIBER]: {
        date,
        sampleId,
        sampleTypes,
        description
    },
    [OrderType.MATERIAL_VOC]: {
        date,
        sampleId,
        description
    },
    [OrderType.VOLATILE_VOC]: {
        date,
        sampleId,
        description,
        pipeNumber,
        samplingTime,
        pumpNumber,
        pumpFlow
    }    
}
