import taklabStore from './store';
import { logoutClient } from './actions/auth';

function ApiError(message, statusCode) {
    this.message = message;
    this.statusCode = statusCode;
}
ApiError.prototype = Object.create(Error.prototype);
ApiError.prototype.name = "ApiError";

async function send(url, options = {}) {
    const response = await fetch(url, options);
    const responseType = response.headers.get('Content-Type');
    if (response.ok) {
        try {
            if (responseType === 'application/pdf') {
                return await response.blob();
            }
            return await response.json();
        } catch(error) {
            return;
        }
    } else {
        if (response.status === 401) {
            localStorage.removeItem('token');
            taklabStore.dispatch(logoutClient());
        }
        const error = await response.json();
        console.error(error.original);
        throw new ApiError(error.message, response.status);
    }
}

export async function postFile(uri, payload) {
    const url = `${process.env.REACT_APP_BASE_URL}${uri}`;
    const options = {
        method: 'POST',
        body: payload,
        headers: {}
    };

    const token = localStorage.getItem('token');
    if (!token) {
        throw new ApiError('Ole hyvä ja kirjaudu sisään', 401);
    }
    options.headers['Authorization'] = `Bearer ${token}`;

    return send(url, options);
}

export async function post(uri, payload, auth = false) {
    const url = `${process.env.REACT_APP_BASE_URL}${uri}`;
    const options = {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json'
        }
    };
    if (auth) {
        const token = localStorage.getItem('token');
        if (!token) {
            throw new ApiError('Ole hyvä ja kirjaudu sisään', 401);
        }
        options.headers['Authorization'] = `Bearer ${token}`;
    }

    return send(url, options);
}

export async function put(uri, payload, auth = false) {
    const url = `${process.env.REACT_APP_BASE_URL}${uri}`;
    const token = localStorage.getItem('token');
    if (!token) {
        throw new ApiError('Ole hyvä ja kirjaudu sisään', 401);
    }
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
    };
    return send(url, options);
}

export async function get(uri, auth = false) {
    const url = `${process.env.REACT_APP_BASE_URL}${uri}`;
    const options = {
        method: 'GET'
    };
    if (auth) {
        const token = localStorage.getItem('token');
        if (!token) {
            throw new ApiError('Ole hyvä ja kirjaudu sisään', 401);
        }
        options.headers = {
            'Authorization': `Bearer ${token}`
        };
    }
    return send(url, options);
}

export async function del(uri) {
    const url = `${process.env.REACT_APP_BASE_URL}${uri}`;
    const token = localStorage.getItem('token');
    if (!token) {
        throw new ApiError('Ole hyvä ja kirjaudu sisään', 401);
    }
    const options = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };
    return send(url, options);
}