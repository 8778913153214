export const businessId = {
    format: {
        pattern: /^([0-9]{6,7}-[0-9]{1}|)$/,
        message: "Anna kelvollinen Y-tunnus"
    }
}

export const email = {
    email: {
        message: "Anna kelvollinen sähköpostiosoite"
    }
}

export const required = {
    allowEmpty: false,
    message: 'Täytä vaadittu kenttä'
}

const singleDecimalPattern = /^(-?[0-9]+)([.,]([0-9]){1})?$/
const doubleDecimalPattern = /^(-?[0-9]+)([.,]([0-9]){1,2})?$/

export const singleDecimal = {
    numericality: true,
    format: {
        pattern: singleDecimalPattern,
        message: 'Syötä luku enintään yhden desimaalin tarkkuudella'
    }
}

export const doubleDecimal = {
    numericality: true,
    format: {
        pattern: doubleDecimalPattern,
        message: 'Syötä luku enintään kahden desimaalin tarkkuudella'
    }
}
