import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { routes } from './routes';

const PrivateCustomerRoute = ({
    isAuthenticated,
    isAdmin,
    isSampler,
    user,
    children,
    ...rest
}) => {
    return (
        <Route { ...rest } render={ ({ location }) => {
            return isAuthenticated && !isAdmin && !isSampler ?
                (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: routes.USER_LOGIN,
                            state: { from: location }
                        }}
                    />
                );
            }}
        />
    )
};

export default connect((state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isAdmin: state.auth.isAdmin,
        isSampler: state.auth.isSampler // <-- TODO: not sure if this is needed
    };
})(PrivateCustomerRoute);