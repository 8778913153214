import React from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { OrderType } from "../enums";

/**
 * A modal component to display sample delivery method selection and to submit a finished order
 * @param {object} props Component props
 * @param {function} props.onClose Close modal handler
 * @param {function} props.onChange Change handler for sample delivery method selection.
 * @param {boolean} props.open Indicates whether to show the modal.
 * @param {function} props.onSubmit Handler for submitting the finished order.
 * @param {object[]} props.options Sample delivery options.
 * @param {number} props.value Selected sample delivery option index.
 */

/*
taklab=# select * from delivery_type;
 id |                 delivery_type                  
----+------------------------------------------------
  1 | Vastaanottolaatikko
  2 | Jyväskylän laboratorio
  3 | Jyväskylän laboratorio, matkahuolto
  4 | Jyväskylän laboratorio, posti
  5 | Tampereen laboratorio
  6 | Tampereen laboratorio, matkahuolto
  7 | Tampereen laboratorio, posti
 11 | Helsingin Roihupellon laboratorio
 12 | Helsingin Roihupellon laboratorio, matkahuolto
 13 | Helsingin Roihupellon laboratorio, posti
  8 | Helsingin Konalan laboratorio
  9 | Helsingin Konalan laboratorio, matkahuolto
 10 | Helsingin Konalan laboratorio, posti
(13 rows)
*/

export function OrderSubmitModal({ onClose, onChange, open, onSubmit, options, value, orderType }) {
    let deliveryTypeIdsToBeFiltered;

    if (
        orderType === OrderType.FLEC_VOC ||
        orderType === OrderType.MATERIAL_VOC ||
        orderType === OrderType.VOLATILE_VOC
    ) {
        //asiakkaan pyynnöstä Jyväskylän ja Helsingin matkahuollot ja postit pois
        deliveryTypeIdsToBeFiltered = [3, 4, 12, 13, 9, 10];
    } else {
        // asiakkaan pyynnöstä Helsingin labrojen matkahuollot pois valikosta:
        deliveryTypeIdsToBeFiltered = [9, 12];
    }
    options = options.filter((opt) => !deliveryTypeIdsToBeFiltered.includes(opt.value));

    let ttString = "Valitse, mitä toimitustapaa käytät näytteiden toimittamiseen.";

    if (orderType === OrderType.FLEC_VOC) {
        ttString =
            "Valitse, mitä toimitustapaa käytät näytteiden toimittamiseen ja FLEC-laitteiston palauttamiseen.";
    }

    return (
        <Modal closeOnDimmerClick={false} open={open} onClose={onClose}>
            <Modal.Header>Valitse toimitustapa</Modal.Header>
            <Modal.Content>
                <p>{ttString}</p>
                <p>
                    Lisätietoja toimitustavoista löydät osoitteesta:{" "}
                    <a href="https://www.asbestilaboratorio.fi/naytepisteet/" target="_blank">
                        https://www.asbestilaboratorio.fi/naytepisteet/
                    </a>
                </p>
                <Form.Select
                    fluid
                    name="deliveryTypeId"
                    value={value || ""}
                    onChange={(event, { value }) => onChange(value)}
                    placeholder="Toimitustapa"
                    options={options}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => {
                        onChange(null);
                        onClose();
                    }}
                >
                    Takaisin
                </Button>
                <Button disabled={!value} primary onClick={onSubmit}>
                    Lähetä
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
