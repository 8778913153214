import {
    CLEAR_ORDER,
    CREATE_ORDER,
    UPDATE_ORDER,
    EDIT_ORDER,
    ADD_ORDER_ITEM,
    DELETE_ORDER_ITEM,
    UPDATE_ORDER_ITEM,
    MIXIN_ORDER,
    STORE_UNREGISTERED_ORDER,
    CLEAR_UNREGISTERED_ORDER
} from '../actions/actions'

export function createOrder(orderTypeId) {
    return (dispatch, getState) => {
        const { user } = getState().auth;
        const unregisteredOrder = getState().unregisteredOrder;
        dispatch(clearUnregisteredOrder());
        dispatch({
            type: CREATE_ORDER,
            orderTypeId,
            unregisteredOrder,
            user
        });
    }
}

export function editOrder(order) {
    return {
        type: EDIT_ORDER,
        order
    }
}

export function clearOrder() {
    return {
        type: CLEAR_ORDER
    }
}

export function updateOrder(update) {
    return {
        type: UPDATE_ORDER,
        update
    };
}

export function addOrderItem(item) {
    return {
        type: ADD_ORDER_ITEM,
        item
    }
}

export function deleteOrderItem(id) {
    return {
        type: DELETE_ORDER_ITEM,
        id
    }
}

export function updateOrderItem(id, update) {
    return {
        type: UPDATE_ORDER_ITEM,
        id,
        update
    };
}

export function storeUnregisteredOrder(order) {
    return {
        type: STORE_UNREGISTERED_ORDER,
        order
    }
}

export function clearUnregisteredOrder() {
    return {
        type: CLEAR_UNREGISTERED_ORDER
    }
}

export function mixinOrder(order) {
    return (dispatch, getState) => {
        const { user } = getState().auth;
        dispatch({
            type: MIXIN_ORDER,
            order,
            user
        });
    }
}