import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Container,
  Grid,
  Header,
  Segment,
  Divider
} from "semantic-ui-react";
import { updateProfile } from '../actions/auth';
import { PageContent } from "../components/PageContent";
import { useState } from "react";
import { InfoModal } from "../components/InfoModal";
import { AppLoader } from '../components/AppLoader';
import { UpdatePasswordForm } from "../components/UpdatePasswordForm";
import { SamplerProfileForm } from "../components/SamplerProfileForm";

/**
 * User profile page component.
 */
export function SamplerProfile() {

  const [editProfile, setEditProfile] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const isFetching = useSelector((state) => state.auth.isFetching);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  async function handleUpdateProfile(update) {
    try {
      await dispatch(updateProfile(update));
      setEditProfile(false);
      setShowConfirmation(true);
    } catch(error) {
      // Let this component know it failed
    }
  }

  return (
    <Container className="page-wrapper">
      <AppLoader
        active={ isFetching }
        page={ true }
      />
      <PageContent>
          <Grid.Row>
              <Grid.Column width={ 9 }>
                <Header
                  size='huge'
                  color='yellow'>
                    Näytteenottajan tiedot
                </Header>
              </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={ 16 }>
              <Segment
                className="segment-rectangle"
                padded="very"
              >
              <SamplerProfileForm
                editProfile={ editProfile }
                onCancel={ () => setEditProfile(false) }
                user={ user }
                onUpdate={ handleUpdateProfile }
              />
              { !editProfile &&
                <Button
                  primary
                  onClick={ () => setEditProfile(true)}>
                      Muokkaa tietoja
                </Button>
              }
              <Divider />
              <UpdatePasswordForm />
              </Segment>
            </Grid.Column>
          </Grid.Row>
      </PageContent>
      <InfoModal
          content={ 'Näytteenottajan tiedot tallennettu' }
          open={ showConfirmation }
          onClose={  () => setShowConfirmation(false) }
          onConfirm={ () => setShowConfirmation(false) }
        />
    </Container>
  );
}
