import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Message, Button } from "semantic-ui-react";

/**
 * Component to display a modal with an error message
 */
export function ErrorModal() {

    const error = useSelector((state) => state.error.error);
    const dispatch = useDispatch();

    function handleClose() {
        dispatch({ type: 'DISMISS_ERROR' });
    }

    const errorMessage = error && error.message;
    return (
        <Modal
            size={ 'tiny' }
            open={ !!errorMessage }
            onClose={ handleClose }
        >
            <Modal.Content>
                <Message
                    error
                    visible
                >
                    { errorMessage }
                </Message>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={ handleClose }>
                    Sulje
                </Button>
            </Modal.Actions>
      </Modal>
    );
}



