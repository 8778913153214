import React from 'react';
import { Popup, Icon } from 'semantic-ui-react';

/**
 * An icon with a tooltip
 * @param {object} props Component props
 * @param {enum} props.position Relative position of the tooltip ('top center' 'top left' 'top right' 'bottom center' 'bottom left' 'bottom right' 'right center' 'left center')
 * @param {string} props.tooltip Tooltip content
 * @param {object} props.rest Props to be forwarded to the Semantic UI Icon component (https://react.semantic-ui.com/elements/icon/)
 */
export function IconWithTooltip({
    position,
    tooltip,
    ...rest
}) {
    return (
        <Popup
            basic
            size="mini"
            position={ position }
            content={ tooltip }
            trigger={
                <Icon { ...rest } />
            }
        />
    )
}