import { useEffect, useRef } from 'react';

/**
 * Extract the value from a Semantic UI React component event.
 * @param {Object} event An event from a Semantic UI React component.
 * @param {Object} data The data from a Semantic UI React component event.
 * @returns {(string|boolean)}
 */
export function getFormFieldValue(event, data) {
    let value;
    if ('checked' in data) {
        value = data.checked;
    } else {
        value = data.value;
    }
    if (typeof value === 'string') {
        value = value.trimLeft();
        const type = event.target.type;
        if (type === 'number') {
            return handleNumberValue(value);
        }
        return value;
    }
    return value;
}

/**
 * Format number string.
 * @param {string} value The string to format.
 * @returns {string}
 */
function handleNumberValue(value) {
    return value.replace(/,/gi, '.');
}

/**
 * Format form field errors to a string.
 * @param {string[]} errors array of error messages.
 * @returns {string}
 */
export function formatFieldError(errors) {
    return errors.join('\n');
}

/**
 * Get first available integer for a sample id.
 * @param {string[]} ids An array of current sample ids input by the user.
 * @returns {string} The first available integer not found in the input array (as a string).
 */
export function getFirstAvailableSampleId(ids) {
    const numbers = ids
        .map(id => Number(id))
        .filter(number => Number.isInteger(number))
        .sort((a, b) => a - b);

    for (let i = 0; i < numbers.length; i++) {
        const value = i + 1;
        if (numbers[i] !== value) {
            return '' + value;
        }
    }
    const value = numbers.length + 1;
    return '' + value;
}

/**
 * Get string labels for sample types in an order sample item.
 * @param {Object} item A sample item.
 * @param {Object[]} sampleTypes An array of possible sample types.
 * @returns {string[]} An array of string labels for the sample types present in the order sample item.
 */
export function getSampleTypeLabels(item, sampleTypes) {
    return sampleTypes
        .filter(type => item.sampleTypes.includes(type.id))
        .map(type => type.label);
}

/**
 * Scroll the view to a React ref.
 * @param {Object} ref The React ref to scroll to.
 * @param {number} [navbarHeight] The height of the navbar for the current view.
 */
export function scrollToRef(ref, navbarHeight = 0) {
    const rect = ref.current.getBoundingClientRect();
    window.scrollTo(0, rect.top - navbarHeight);
}

/**
 * Get a paginated section of a body of data
 * @param {*[]} data An array of any data.
 * @param {number} offset Offset to define the page to retrieve.
 * @param {number} limit Maximum number if data items per page.
 * @returns {*[]}
 */
export function getPaginatedData(data, offset, limit) {
    const start = (limit) * (offset - 1);
    const end = start + limit;
    return data.slice(start, end);
}

/**
 * Get a wrapped version of a function with a running index parameter added
 * @param {function} fn The function to run
 * @returns {function} A wrapper function adding an index parameter
 */
export function withIndex(fn) {
    let index = 0
    return function(...args) {
        return fn(...args, index++)
    };
}

export function wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {

      ref.current = value;

    }, [value]); // Only re-run if value changes

    return ref.current;

  }

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}