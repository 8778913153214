import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { routes } from './routes';

const PublicOnlyRoute = ({
    isAuthenticated,
    isAdmin,
    user,
    children,
    ...rest
}) => {
    return (
    <Route { ...rest } render={ ({ location }) => {
        const pathname = isAdmin ? routes.ADMIN_DASHBOARD : `${routes.USER}${location.pathname}`;
        const search = location.search;
        return (
            !isAuthenticated ? (
                children
            ) : (
                <Redirect
                    to={{
                        pathname,
                        search
                    }}
                />
            )
        )
    }
} />
)};

export default connect((state) => {
    return {
        isAdmin: state.auth.isAdmin,
        isAuthenticated: state.auth.isAuthenticated
    };
})(PublicOnlyRoute);