import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { routes } from './routes';

const AdminRoute = ({
    isAuthenticated,
    children,
    isAdmin,
    ...rest
}) => {
    return (
    <Route { ...rest } render={ ({ location }) => (
        isAuthenticated && isAdmin ? (
            children
        ) : (
            <Redirect
                to={{
                    pathname: routes.ADMIN_LOGIN,
                    state: { from: location }
                }}
            />
        )
    )} />
)};

export default connect((state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isAdmin: state.auth.isAdmin
    };
})(AdminRoute);