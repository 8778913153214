import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { pickBy, identity } from 'lodash';
import {
  Container,
  Header,
  Grid,
  Segment,
  Confirm
} from "semantic-ui-react";
import {
  fetchUsers,
  deleteUser
} from '../../actions/users';
import { useEffect } from "react";
import { getVisibleUsers } from "../../reducers/users";
import { AppLoader } from "../../components/AppLoader";
import { PageContent } from "../../components/PageContent";
import { CustomerTable } from "../../components/CustomerTable";
import { useState } from "react";
import { InfoModal } from "../../components/InfoModal";
import { UserFilterForm } from "../../components/UserFilterForm";
import { useLocation, useHistory } from "react-router-dom";

/**
 * Admin users page with all users listed
 */
export function AdminUsersView() {

  const location = useLocation();
  const history = useHistory();

  const users = useSelector((state) => state.users.customers);
  const isFetching = useSelector((state) => state.users.isFetching);
  const dispatch = useDispatch();

  const query = location.search;
  const filters = queryString.parse(query);

  function handleUpdateFilters(filters) {
    const activeFilters = pickBy(filters, identity);
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(activeFilters)
    });
  }

  useEffect(() => {
      dispatch(fetchUsers());
  }, [dispatch]);

  const visibleUsers = getVisibleUsers(users, filters);

  return (
    <Container className="page-wrapper">
        <AppLoader
          active={ isFetching }
          page={ true }
        />
        <PageContent>
            <Grid.Row>
                <Grid.Column width={ 16 }>
                    <Header size='huge' color='yellow'>Asiakkaat</Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={ 16 }>
                <Segment
                  className="segment-rectangle"
                >
                  <UserFilterForm
                    defaultFilters={ filters }
                    onSubmit={ handleUpdateFilters }
                  />
                  <CustomerTable
                    customers={ visibleUsers }
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
        </PageContent>
    </Container>
  );
}
