import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

/**
 * Loader component with a full screen dimmer and a spinning loader
 * @param {object} props Props to be forwarded to Semantic UI dimmer (https://react.semantic-ui.com/modules/dimmer/)
 */
export function AppLoader(props) {
    return (
        <Dimmer {...props}>
            <Loader />
        </Dimmer>
    );
}



