import React from "react";
import {
  Container,
  Header,
  Segment,
  Button,
  Grid,
  Divider
} from "semantic-ui-react";
import { fetchOneSampler, updateSamplerProfile, deleteSampler } from "../actions/samplers";
import { clearUser } from '../actions/users';
import { useDispatch, useSelector } from "react-redux";
import { AppLoader } from "../components/AppLoader";
import { SamplerProfileForm } from "../components/SamplerProfileForm";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { InfoModal } from "../components/InfoModal";
import { PageContent } from "../components/PageContent";
import { DeleteSamplerAccountSection } from "../components/DeleteSamplerAccountSection";

export function Sampler() {

  const { samplerId } = useParams();
  const history = useHistory();

  const sampler = useSelector((state) => state.samplers.userSampler);
  const isFetching = useSelector((state) => state.samplers.isFetching);
  const dispatch = useDispatch();

  const [editProfile, setEditProfile] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);

  useEffect(() => {
    if (samplerId) {
      dispatch(fetchOneSampler(samplerId));
    }
    return function cleanup() {
        dispatch(clearUser());
    }
  }, [dispatch, samplerId]);  

  
  async function handleUpdateProfile(update) {
    try {
      await dispatch(updateSamplerProfile(samplerId, update));
      setEditProfile(false);
      setShowConfirmation(true);
    } catch(error) {
      // Let this component know it failed
    }
  }

  async function handleConfirmDeleteSampler() {
    try {
      await dispatch(deleteSampler(samplerId));
      setShowDeleteSuccessModal(true);
    } catch(error) {
    }
  }  

  return (
    <Container className="page-wrapper">
      <AppLoader
        active={ isFetching }
        page={ true }
      />
      <PageContent>
          <Grid.Row>
              <Grid.Column width={ 10 }>
                <Header
                  size='huge'
                  color='yellow'>
                    Näytteenottaja
                </Header>
              </Grid.Column>
              <Grid.Column
                textAlign="right"
                width={ 6 }
                className="single-order-actions"
              >
                <Button
                  onClick={ history.goBack }>
                    Takaisin
                </Button>
              </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={ 16 }>
                <Header size="huge" color="yellow">Näytteenottajan tiedot</Header>
                <Segment
                    className="segment-rectangle"
                >
                    <SamplerProfileForm
                    editProfile={ editProfile }
                    onCancel={ () => setEditProfile(false) }
                    user={ sampler }
                    onUpdate={ handleUpdateProfile }
                    />
                    { !editProfile &&
                    <Button
                        primary
                        onClick={ () => setEditProfile(true)}>
                            Muokkaa tietoja
                    </Button>
                    }
                <Divider />
                <DeleteSamplerAccountSection
                  onSubmit={ handleConfirmDeleteSampler }
                  type='customer'
                />
                </Segment>
            </Grid.Column>
          </Grid.Row>
      </PageContent>
      <InfoModal
        content={ 'Näytteenottajan tiedot tallennettu' }
        open={ showConfirmation }
        onClose={  () => setShowConfirmation(false) }
        onConfirm={ () => setShowConfirmation(false) }
      />
      <InfoModal
          content={ `Näytteenottaja poistettu onnistuneesti.` }
          open={ showDeleteSuccessModal }
          onClose={ () => history.goBack() }
          onConfirm={ () => history.goBack() }
      />      
    </Container>    
  );
}
