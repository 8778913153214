import {
    CLEAR_USER,
    FETCH_USERS,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE,
    DELETE_USER,
    DELETE_USER_FAILURE,
    DELETE_USER_SUCCESS,
    FETCH_SINGLE_USER,
    FETCH_SINGLE_USER_FAILURE,
    FETCH_SINGLE_USER_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_FAILURE,
    UPDATE_USER_SUCCESS
} from '../actions/actions'

export const users = (
    state = {
        user: null,
        customers: [],
        admins: [],
        isFetching: false
    },
    action
) => {
    switch (action.type) {
        case FETCH_USERS:
        case FETCH_SINGLE_USER:
        case DELETE_USER:
        case UPDATE_USER: {
            return {
                ...state,
                isFetching: true
            }
        }
        case FETCH_USERS_FAILURE:
        case FETCH_SINGLE_USER_FAILURE:
        case DELETE_USER_FAILURE:
        case UPDATE_USER_FAILURE: {
            return {
                ...state,
                isFetching: false
            }
        }
        case FETCH_SINGLE_USER_SUCCESS: {
            const { user } = action;
            return {
                ...state,
                user,
                isFetching: false
            }
        }
        case FETCH_USERS_SUCCESS: {
            const { users } = action;
            let customers = users.filter(user => user.role === 'CUSTOMER' || user.role === 'SAMPLER');
            customers = customers.map(user => {
                if(user.role === 'SAMPLER') {
                    return {
                        rooli: 'näytteenottaja',
                        ...user
                        }
                }
                else {
                    return {
                        rooli: 'tilinhaltija',
                        ...user
                        }
                }
            });
            const admins = users.filter(user => user.role === 'ADMIN');
            return {
                ...state,
                customers,
                admins,
                isFetching: false
            }
        }
        case UPDATE_USER_SUCCESS: {
            const { update } = action;
            const user = {
                ...state.user,
                update
            };
            return {
                ...state,
                user,
                isFetching: false
            };
        }
        case DELETE_USER_SUCCESS: {
            const { id } = action;
            const customers = state.customers.filter(customer => customer.id !== id);
            return {
                ...state,
                customers,
                isFetching: false
            }
        }
        case CLEAR_USER: {
            return {
                ...state,
                user: null
            }
        }
        default: {
            return state;
        }
    }
}

export function getVisibleUsers(users, { string = '' }) {
    const searchString = string.toLowerCase();
    const filteredUsers = users.filter(user => {
        // const orderDate = moment(order.createdAt);
        // const startDateMatch = !startDate || orderDate.isSameOrAfter(startDate, 'day');
        // const endDateMatch = !endDate || orderDate.isSameOrBefore(endDate, 'day');
        // const statusMatch = !status || order.status === status;
        // const orderTypeIdMatch = !orderTypeId || order.orderTypeId === orderTypeId;
        const stringMatch = !string || 
            user.companyName.toLowerCase().includes(searchString) || 
            user.email.toLowerCase().includes(searchString) ||
            user.userName.toLowerCase().includes(searchString) ||
            user.rooli.toLowerCase().includes(searchString);
        return stringMatch;
    });
    return filteredUsers;
}