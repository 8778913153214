import React from 'react';
import { Form } from 'semantic-ui-react';

/**
 * Component to view a single order
 * @param {object} props Component props
 * @param {object} props.options Sample type options to display as dropdown.
 * @param {object} props.onChange Change handler for sample type selection.
 * @param {number} props.value Selected sample type index.
 * @param {number} [props.maxSelection=1] Maximum number of selected items.
 * @param {object} [props.rest] Props to forward to Semantic UI Form.Select item (https://react.semantic-ui.com/collections/form/)
 */
export function SampleTypeSelect({
    options,
    onChange,
    value,
    maxSelection = 1,
    ...rest
}) {
    return (
        <Form.Select
            value={ value || [] }
            multiple
            { ...rest }
            options={ value && value.length >= maxSelection ? options.filter(option => value.includes(option.value)) : options }
            onChange={ (event, data) => {
                if (data.value.length <= maxSelection) {
                    onChange(event, data);
                }
            }}
        />
    )
}