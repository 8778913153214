import * as moment from 'moment';
import {
    INSERT_ORDER,
    INSERT_ORDER_SUCCESS,
    INSERT_ORDER_FAILURE,
    ORDERS_REQUEST,
    ORDERS_REQUEST_SUCCESS,
    COMPANY_ORDERS_REQUEST_SUCCESS,
    ORDERS_REQUEST_FAILURE,
    SINGLE_ORDER_REQUEST,
    SINGLE_ORDER_REQUEST_SUCCESS,
    SINGLE_ORDER_REQUEST_FAILURE,
    DELETE_ORDER_FAILURE,
    DELETE_ORDER,
    DELETE_ORDER_SUCCESS,
    FETCH_RESULT,
    FETCH_RESULT_SUCCESS,
    FETCH_RESULT_FAILURE,
    UPLOAD_RESULT,
    UPLOAD_RESULT_FAILURE,
    UPLOAD_RESULT_SUCCESS,
    ORDER_REQUEST,
    ORDER_REQUEST_END,
    CLEAR_ORDERS,
    PRINT_ORDER_SUCCESS,
    PRINT_ORDER_FAILURE,
    PRINT_ORDER,
} from '../actions/actions'

export const orders = (
    state = {
        orders: [],
        companyOrders: [], // this is only for CUSTOMER users
        isFetching: false
    },
    action
) => {
    switch (action.type) {
        case DELETE_ORDER:
        case INSERT_ORDER:
        case ORDERS_REQUEST:
        case SINGLE_ORDER_REQUEST:
        case FETCH_RESULT:
        case UPLOAD_RESULT:
        case ORDER_REQUEST:
        case PRINT_ORDER: {
            return {
                ...state,
                isFetching: true
            }
        }
        case INSERT_ORDER_SUCCESS:
        case SINGLE_ORDER_REQUEST_SUCCESS:
        case FETCH_RESULT_SUCCESS:
        case DELETE_ORDER_FAILURE:
        case INSERT_ORDER_FAILURE:
        case SINGLE_ORDER_REQUEST_FAILURE:
        case ORDERS_REQUEST_FAILURE:
        case FETCH_RESULT_FAILURE:
        case UPLOAD_RESULT_FAILURE:
        case UPLOAD_RESULT_SUCCESS:
        case ORDER_REQUEST_END:
        case PRINT_ORDER_SUCCESS:
        case PRINT_ORDER_FAILURE: {
            return {
                ...state,
                isFetching: false
            }
        }
        case ORDERS_REQUEST_SUCCESS: {
            const { orders } = action;
            return {
                ...state,
                orders,
                isFetching: false
            }
        }
        case COMPANY_ORDERS_REQUEST_SUCCESS: {
            const { companyOrders } = action;
            return {
                ...state,
                companyOrders,
                isFetching: false
            }
        }        
        case DELETE_ORDER_SUCCESS: {
            const { id } = action;
            const orders = state.orders.filter(order => order.id !== id);
            return {
                ...state,
                orders,
                isFetching: false
            }
        }
        case CLEAR_ORDERS: {
            return {
                ...state,
                orders: [],
                companyOrders: []
            }
        }
        default: {
            return state;
        }
    }
}

export function getVisibleOrders(orders, { status, orderTypeId, string, startDate, endDate }) {
    const searchString = string ? string.toLowerCase() : '';
    const filteredOrders = orders.filter(order => {
        const orderDate = moment(order.updatedAt);
        const startDateMatch = !startDate || orderDate.isSameOrAfter(startDate, 'day');
        const endDateMatch = !endDate || orderDate.isSameOrBefore(endDate, 'day');
        const statusMatch = !status || order.status === status;
        const orderTypeIdMatch = !orderTypeId || order.orderTypeId == orderTypeId;

        const stringMatch = !string ||
            (
                order.buildingAddress.toLowerCase().includes(searchString) ||
                order.id.toString().includes(searchString) ||
                order.customerName.toLowerCase().includes(searchString) ||
                ( order.businessId && order.businessId.toLowerCase().includes(searchString) ) ||
                order.contactName.toLowerCase().includes(searchString)
            );
        return statusMatch && orderTypeIdMatch && stringMatch & startDateMatch && endDateMatch;
    });
    return filteredOrders;
}