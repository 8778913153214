import React from "react";
import { AppRouter } from "./router/AppRouter";
import { useSelector } from "react-redux";
import { AppLoader } from "./components/AppLoader";

function App() {
    const isFetchingMetadata = useSelector(state => state.metadata.isFetching);
    const isFetchingMe = useSelector(state => state.auth.isFetchingMe);

    if (isFetchingMetadata || isFetchingMe) {
        return <AppLoader active={ true } />;
    }

    return <AppRouter />;
}

export default App;
