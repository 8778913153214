import React from "react";
//import { capitalize } from 'lodash';
import { Form, Header } from "semantic-ui-react";
import { fieldNames } from "../../enums";
//import { microbial } from '../../enums';

/**
 * Order form section with the building data form fields
 * @param {object} props Component props
 * @param {object} props.errors Form field errors.
 * @param {function} props.metadata Order metadata to use for displaying form field options.
 * @param {number} props.onBlur Form field blur event handler.
 * @param {number} props.onChange Form field change event handler.
 * @param {number} props.order The order to edit.
 * @param {number} props.orderTypeId Type of the order to edit.
 */
export function ZeroPipe({ errors, onBlur, onChange, order }) {
    return (
        <>
            <Header as="h2">Nollaputken tiedot</Header>
            <Form.Group widths="equal">
                <Form.Input
                    required
                    fluid
                    error={errors.zeroPipeNumber}
                    name="zeroPipeNumber"
                    type="number"
                    step="1"
                    min="100000"
                    max="999999"
                    value={order.zeroPipeNumber || ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    label={fieldNames.voc.ZERO_PIPE_NUMBER}
                    placeholder={fieldNames.voc.ZERO_PIPE_NUMBER}
                    maxLength="6"
                />
                <Form.Input
                    fluid
                    error={errors.zeroPipeOtherNotes}
                    name="zeroPipeOtherNotes"
                    value={order.zeroPipeOtherNotes || ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    label={fieldNames.voc.OTHER_NOTES}
                    placeholder={fieldNames.voc.OTHER_NOTES}
                    maxLength="255"
                />
            </Form.Group>
        </>
    );
}
