import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Confirm
} from 'semantic-ui-react';
import { routes } from '../router/routes';
import { useState } from 'react';
import {
    deleteOrder,
    downloadResult,
    fetchCustomerOrders,
    uploadResult
} from '../actions/orders';
import { InfoModal } from './InfoModal';
import { InterActiveTable } from './InterActiveTable';

/**
 * Component to display a list of orders for an admin user
 * @param {object} props Component props
 * @param {object[]} props.orders The orders to display in the table
 * @param {integer} props.status The status of orders to display
 */
export default function OrderTableComponent({
    orders,
    status
}) {

    const dispatch = useDispatch();

    let dateHeader = 'Pvm';
    if (status === 'submitted') {
        dateHeader = 'Tilattu';
    } else if (status === 'completed') {
        dateHeader = 'Valmistunut';
    }

    const columns = [
        {
            content: "Tilaus-\nnumero",
            name: "id",
            sortable: true
        },
        {
            content: dateHeader,
            name: 'updatedAt',
            sortable: true
        },
        {
            content: "Asiakas",
            name: "customerName",
            sortable: true
        },
        {
            content: "Y-tunnus",
            name: "businessId",
            sortable: true
        },
        {
            content: "Kohteen osoite",
            name: "buildingAddress",
            sortable: true
        },
        {
            content: "Tutkimus",
            name: "description",
            sortable: true
        },
        {
            content: "Näytteen-\nottaja",
            name: "contactName",
            sortable: true
        },        
        {
            content: "Näytteitä",
            name: "itemsCount",
            sortable: true
        }
    ];

    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
    const [showUploadSuccessModal, setShowUploadSuccessModal] = useState(false);
    const [orderToDelete, setOrderToDelete] = useState();
    const history = useHistory();

    function handleDeleteOrder(id) {
        setOrderToDelete(id);
        setShowConfirmDelete(true);
    }

    async function handleConfirmDeleteOrder() {
        try {
          await dispatch(deleteOrder(orderToDelete));
          setShowConfirmDelete(false);
          setShowDeleteSuccessModal(true);
        } catch(error) {
        }
        setOrderToDelete(null);
    }

    async function handleDownloadResult(id, buildingAddress) {
        dispatch(downloadResult(id, buildingAddress));
    }

    async function handleUploadResult(id, data) {
        try {
            await dispatch(uploadResult(id, data));
            await dispatch(fetchCustomerOrders(status));
            setShowUploadSuccessModal(true);
        } catch(error) {
            // Let component know it failed
        }
    }

    return (
        <>
            <InterActiveTable
                title="Tilaukset"
                columns={ columns }
                onView={ (id) => history.push(`${routes.ADMIN_VIEW_ORDER}/${id}`) }
                onDownload={ handleDownloadResult }
                onDelete={ handleDeleteOrder }
                onUpload={ handleUploadResult }
                defaultSort={{
                    column: 'date',
                    direction: 'descending'
                }}
                data={ orders }
            />
            <Confirm
                open={ showConfirmDelete }
                content={ `Haluatko varmasti poistaa tilauksen #${orderToDelete }?`}
                confirmButton="Poista"
                onConfirm={ handleConfirmDeleteOrder }
                cancelButton="Peruuta"
                onCancel={ () => setShowConfirmDelete(false) }
            />
            <InfoModal
                content={ `Tilaus poistettu onnistuneesti.` }
                open={ showDeleteSuccessModal }
                onClose={  () => setShowDeleteSuccessModal(false) }
                onConfirm={ () => setShowDeleteSuccessModal(false) }
            />
            <InfoModal
                content={
                     <>
                        { 'Tulokset on tallennettu ja tilaus on siirretty valmistuneisiin tutkimuksiin'}
                    </>
                }
                open={ showUploadSuccessModal }
                onClose={  () => setShowUploadSuccessModal(false) }
                onConfirm={ () => setShowUploadSuccessModal(false) }
            />
        </>
    )
}

