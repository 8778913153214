import {
    LOGIN_SUCCESS,
    LOGOUT_CLIENT,
    SIGNUP_SUCCESS,
    UPDATE_PROFILE_SUCCESS,
    AUTH_REQUEST,
    AUTH_REQUEST_END,
    SIGNUP_FAILURE,
    LOGIN_FAILURE,
    CONFIRM_LOGOUT,
    CANCEL_LOGOUT,
    GET_ME_REQUEST,
    GET_ME_SUCCESS,
    GET_ME_FAILURE
} from './actions';
import {
    post,
    put,
    get,
    del
} from '../api';

export function login(credentials, admin = false) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            const uri = admin ? '/auth/admin/login' : '/auth/login';
            const response = await post(uri, credentials);;
            localStorage.setItem('token', response.token);
            dispatch(onLoginSuccess(response));
        } catch(error) {
            dispatch(onLoginFailure(error));
            throw new Error();
        }
    }
}

export function getMe() {
    return async dispatch => {
        dispatch(onGetMeRequest());
        try {
            const uri = '/users/me';
            const response = await get(uri, true);;
            dispatch(onGetMeSuccess(response));
        } catch(error) {
            localStorage.removeItem('token');
            dispatch(onGetMeFailure(error));
        }
    }
}

function onGetMeRequest() {
    return {
        type: GET_ME_REQUEST
    };
}

function onGetMeSuccess(response) {
    const user = response.user;
    return {
        type: GET_ME_SUCCESS,
        user
    };
}

function onGetMeFailure(error) {
    return {
        type: GET_ME_FAILURE,
        error
    }
}

function onLoginSuccess(response) {
    const user = response.user;
    return {
        type: LOGIN_SUCCESS,
        user
    };
}

function onLoginFailure(error) {
    return {
        type: LOGIN_FAILURE,
        error
    }
}

export function confirmLogout() {
    return {
        type: CONFIRM_LOGOUT
    }
}

export function cancelLogout() {
    return {
        type: CANCEL_LOGOUT
    }
}

export function logout(allDevices = false) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            const uri = allDevices ? '/auth/logout-all' : '/auth/logout';
            await post(uri, {}, true);
            localStorage.removeItem('token');
            dispatch(logoutClient());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    };
}

export function logoutClient() {
    return {
        type: LOGOUT_CLIENT
    };
}

export function signup(userData) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            const response = await post('/users', userData , false);
            localStorage.setItem('token', response.token);
            dispatch(onSignupSuccess(response));
        } catch(error) {
            dispatch(onSignupFailure(error));
            throw new Error();
        }
    }
}

function onSignupSuccess(response) {
    const user = response.user;
    const token = response.token;
    return {
        type: SIGNUP_SUCCESS,
        user,
        token
    };
}

function onSignupFailure(error) {
    return {
        type: SIGNUP_FAILURE,
        error
    }
}

export function updateProfile(update) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await put(`/users/me`, update);
            dispatch(onUpdateProfileSuccess(update));
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

function onUpdateProfileSuccess(update) {
    return {
        type: UPDATE_PROFILE_SUCCESS,
        update
    };
}

export function updatePassword(update) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await put(`/users/me/password`, update);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

export function initPasswordReset(data) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await post(`/auth/password-reset`, data);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

export function initSamplerInvitation(address) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await post(`/auth/sampler-invitation`, {email: address}, true);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

export function validateSamplerInvitationToken(data) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await get(`/auth/sampler-signup/${data.token}`);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

export function signupSampler(userData) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            const response = await post('/auth/signup-sampler', userData);
            localStorage.setItem('token', response.token);
            dispatch(onSignupSuccess(response));
        } catch(error) {
            dispatch(onSignupFailure(error));
            throw new Error();
        }
    }
}

export function validateResetToken(data) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await get(`/auth/reset-password/${data.id}/${data.token}`);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

export function resetPassword(data) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await post(`/auth/reset-password`, data);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

export function deleteCustomerProfile(id) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await del(`/users/${ id }`, true);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

export function deleteProfile() {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await del(`/users/me`, true);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

function onAuthRequest() {
    return {
        type: AUTH_REQUEST
    }
}

function onAuthSuccess() {
    return {
        type: AUTH_REQUEST_END
    }
}


function onAuthError(error) {
    return {
        type: AUTH_REQUEST_END,
        error
    }
}

