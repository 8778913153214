import React from 'react';
import { Table } from 'semantic-ui-react';
import { IconWithTooltip } from './IconWithTooltip';
import { UploadResultForm } from './UploadResultForm';

export function DesktopTable({
    columns,
    data,
    direction,
    handleSort,
    onDownload,
    onEdit,
    onView,
    onUpload,
    onDelete,
    sortBy
}) {
    const hasActions = (onDownload || onDelete || onEdit || onUpload);

    return (
        <Table
            sortable
            striped
            selectable
            celled
            style={{ fontSize: '0.9em' }}
            textAlign="center"
        >
            <Table.Header>
                <Table.Row>
                    { columns.map((dataColumn) => {
                        const name = dataColumn.name;
                        return (
                            <Table.HeaderCell
                                style={{whiteSpace: "pre"}}
                                className={ dataColumn.sortable ? '' : 'not-sortable'}
                                key={ name }
                                sorted={ sortBy === name ? direction : null }
                                onClick={ () => dataColumn.sortable ? handleSort(name) : null }
                            >{ dataColumn.content }</Table.HeaderCell>
                        )
                    })
                    }
                    { hasActions && <Table.HeaderCell className="not-sortable"></Table.HeaderCell> }
                </Table.Row>
            </Table.Header>

            <Table.Body>
                { data.map((item) => {
                    return (
                        <Table.Row
                            className={ (
                                (item.status === 'COMPLETED' && !item.resultSeen) ||
                                (item.hasOwnProperty('seen') && !item.seen)
                                ) ? 'not-seen' : ''
                            }
                            onClick={ () => {
                                item.status === 'DRAFT' ? onEdit(item.id) : onView(item.id)
                            } }
                            key={item.id}
                        >
                            { columns.map((dataColumn) => {
                                const name = dataColumn.name;
                                let data = item[name];
                                if(name==='itemsCount' && data == 0) {
                                    data = "-";
                                }
                                if (['updatedAt', 'submittedAt'].includes(name)) {
                                    data = new Date(data).toLocaleDateString('fi-FI');
                                }
                                return <Table.Cell key={ name }>{ data }</Table.Cell>;
                            })
                            }
                            { hasActions &&
                                <Table.Cell singleLine textAlign="center">
                                    { onUpload && item.status === 'SUBMITTED' &&
                                        <UploadResultForm
                                            onSubmit={ (data) => onUpload(item.id, data) }
                                        />
                                    }
                                    { onDownload && item.status === 'COMPLETED' &&
                                        <IconWithTooltip
                                            tooltip="Lataa tulokset"
                                            link
                                            color="black"
                                            size="large"
                                            name="download"
                                            onClick={ (event) => {
                                                event.stopPropagation();
                                                onDownload(item.id, item.buildingAddress);
                                            } }
                                        />
                                    }
                                    { onDelete &&
                                        <IconWithTooltip
                                            tooltip="Poista"
                                            link
                                            color="black"
                                            size="large"
                                            name="trash"
                                            onClick={ (event) => {
                                                event.stopPropagation();
                                                onDelete(item.id);
                                            } }
                                        />
                                    }
                                </Table.Cell>
                            }

                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>
    )
}
