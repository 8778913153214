import React, { useState, useRef } from 'react';
import * as moment from 'moment';
import { sortBy } from 'lodash';
import queryString from 'query-string';
import { Message, Pagination, Header, Responsive } from 'semantic-ui-react';
import { getPaginatedData } from '../utils';
import { useLocation, useHistory } from 'react-router-dom';
import { DesktopTable } from './DesktopTable';
import { MobileList } from './MobileList';
import { SortingDropdowns } from './SortingDropdowns';
import { useEffect } from 'react';

/**
 * Component to display items in a table with interactive actions (e.g. view, delete)
 * @param {object} props Component props
 * @param {object[]} props.data Items to display in the interactive table
 * @param {function} [props.onDelete] Handler for a delete item action. The action is available only when the handler is present.
 * @param {function} [props.onDownload] Handler for a download attachment action. The action is available only when the handler is present.
 * @param {function} [props.onEdit] Handler for an edit item action. The action is available only when the handler is present.
 * @param {function} [props.onUpload] Handler for an upload attachment action. The action is available only when the handler is present.
 * @param {function} [props.onView=() => {}] Handler for a view item action.
 * @param {object[]} [props.columns=[]] Columns to display as objects in the format:
 *  { content: <sortByColumn header (string)>, name: <property to display (string)>, sortable: <use to sort table (boolean> }
 * @param {object} [props.defaultSort={}] Data to sort the table by on the format:
 *  { sortByColumn: <sortByColumn name to sort by (string)>, direction: <'ascending' | 'descending'> }
 * @param {number} [props.limit=25] Maximum number of items per page.
 * @param {string} [props.noResultsMessage=Ei näytettäviä tuloksia] Text to display when no results are found.
 */
export function InterActiveTable({
    data,
    onDelete,
    onDownload,
    onEdit,
    onUpload,
    onView = () => {},
    columns = [],
    defaultSort = {},
    limit = 25,
    missingSpecifier = "",
    noResultsMessage = missingSpecifier ? `${missingSpecifier}` : 'Ei näytettäviä tuloksia',
    showResultCount = true
}) {

    const sortableColumns = columns.filter(column => column.sortable);

    const sortByOptions = sortableColumns.map(column => {
        return {
            key: column.name,
            text: column.content,
            value: column.name
        };
    });


    const history = useHistory();
    const location = useLocation();
    const listRef = useRef(null);
    const [direction, setDirection] = useState(defaultSort.direction || 'ascending');
    const [sortByColumn, setSortByColumn] = useState(defaultSort.sortByColumn || 'updatedAt');

    const query = location.search;
    const filters = queryString.parse(query);

    const page = filters.page || 1;

    useEffect(() => {
        updatePage(1);
    }, [sortByColumn]);

    function handleSort(columnName) {
        if (columnName !== sortByColumn) {
            setSortByColumn(columnName);
            setDirection('ascending');
        return;
        }
        const directionUpdate = direction === 'ascending' ? 'descending' : 'ascending';
        setDirection(directionUpdate);
    }

    function updatePage(page) {
        filters.page = page;
        history.push({
            pathname: location.pathname,
            search: queryString.stringify(filters)
        });
        if (listRef.current) {
            // scrollToRef(listRef, 90);
        }
    }

    function handlePageChange(e, { activePage }) {
        updatePage(activePage);
    }

    let visibleData = data;

    if (['updatedAt, submittedAt'].includes(sortByColumn)) {
        visibleData = sortBy(data, [(item) => moment(item[sortByColumn])] );
    } else {
        visibleData = sortBy(data, [(item) => typeof item[sortByColumn] === "string" ? item[sortByColumn].toLowerCase() : item[sortByColumn]]);
    }

    if (direction === 'descending') {
        visibleData.reverse();
    }

    const totalPages = Math.ceil(visibleData.length/limit);
    visibleData = getPaginatedData(visibleData, page, limit);

    const content = visibleData.length > 0 ?
        <>
            <Header
                as="h4"
            >
                { showResultCount && <> Tuloksia ({data.length}) </> }
            </Header>

            <Responsive
                maxWidth={ 767 }
            >
                <SortingDropdowns
                    sortByOptions={ sortByOptions }
                    sortBy={ sortByColumn }
                    directionValue={ direction }
                    onChangeSortBy={ setSortByColumn }
                    onChangeDirection={ setDirection }
                />
            </Responsive>
            {  totalPages > 1 &&
                <div style={{ display: 'flex', justifyContent: 'center'}}>
                    <Pagination
                        style={{
                            margin: "1rem auto"
                        }}
                        secondary
                        activePage={ page }
                        firstItem={ null }
                        lastItem={ null }
                        totalPages={ totalPages }
                        onPageChange={ handlePageChange }
                    ></Pagination>
                </div>

            }
            <span ref={ listRef }></span>

            <Responsive
                minWidth={ 768 }
            >
                <DesktopTable
                    columns={ columns }
                    direction={ direction }
                    handleSort={ handleSort }
                    data={ visibleData }
                    onDelete={ onDelete }
                    onDownload={ onDownload }
                    onEdit={ onEdit }
                    onView={ onView }
                    onUpload={ onUpload }
                    sortBy={ sortByColumn }
                />
            </Responsive>
            <Responsive
                maxWidth={ 767 }
            >
                <MobileList
                    columns={ columns }
                    direction={ direction }
                    handleSort={ handleSort }
                    data={ visibleData }
                    onDelete={ onDelete }
                    onDownload={ onDownload }
                    onEdit={ onEdit }
                    onView={ onView }
                    onUpload={ onUpload }
                    sortBy={ sortByColumn }
                />
            </Responsive>
            {  totalPages > 1 &&
                <Responsive
                    maxWidth={ 767 }
                >
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                        <Pagination
                            style={{
                                margin: "1rem auto"
                            }}
                            secondary
                            activePage={ page }
                            firstItem={ null }
                            lastItem={ null }
                            totalPages={ totalPages }
                            onPageChange={ handlePageChange }
                        ></Pagination>
                    </div>
                </Responsive>
            }
        </> :
        <Message>{ noResultsMessage }</Message>

    return content;
}
