import React from "react";
import { Form, Grid, Header, Segment } from "semantic-ui-react";
import { microbial } from "../enums";
import { OrderGeneralView } from "./order-view/OrderGeneralView";
import { OrderBuildingView } from "./order-view/OrderBuildingView";
import { OrderItems } from "./order-form/OrderItems";
import { MicrobeInfoView } from "./order-view/MicrobeInfoView";
import { OrderZeroPipeView } from "./order-view/OrderZeroPipeView";
import { OrderFlecVocView } from "./order-view/OrderFlecVocView";
import { OrderType } from "../enums";

/**
 * Component to view a single order
 * @param {object} props Component props
 * @param {object} props.order The order to display
 */
export function OrderView({ order }) {
    return (
        <>
            <Segment vertical>
                <OrderGeneralView order={order} />
                {microbial.includes(order.orderTypeId) && <MicrobeInfoView order={order} />}
            </Segment>
            <Segment vertical>
                <OrderBuildingView
                    order={order}
                    extended={order.orderTypeId === OrderType.VOLATILE_MICROBE}
                />
            </Segment>
            {order.orderTypeId === OrderType.VOLATILE_VOC && (
                <Segment vertical>
                    <OrderZeroPipeView order={order} />
                </Segment>
            )}
            {order.orderTypeId === OrderType.FLEC_VOC && (
                <Segment vertical>
                    <OrderFlecVocView order={order} />
                </Segment>
            )}
            {order.orderTypeId !== OrderType.FLEC_VOC && (
                <Segment vertical>
                    <Form>
                        <OrderItems
                            items={order.items}
                            orderTypeId={order.orderTypeId}
                            readOnly={true}
                        />
                    </Form>
                </Segment>
            )}
        </>
    );
}

export function SingleItem({ label, value }) {
    return (
        <Grid.Column>
            {label && <Header as="h3">{label}</Header>}
            <p>{value}</p>
        </Grid.Column>
    );
}
