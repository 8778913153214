export const OrderType = {
    MATERIAL_FIBER: 1,
    VOLATILE_FIBER: 2,
    MATERIAL_MICROBE: 3,
    VOLATILE_MICROBE: 4,
    BRUSHING_MICROBE: 5,
    BRUSHING_FIBER: 6,
    MATERIAL_VOC: 7,
    VOLATILE_VOC: 8,
    FLEC_VOC: 9,
};

export const FlecVocDeliveryType = {
    TAKLAB_TOIMITTAA: 1,
    ASIAKAS_NOUTAA: 2,
};

export const microbial = [
    OrderType.MATERIAL_MICROBE,
    OrderType.VOLATILE_MICROBE,
    OrderType.BRUSHING_MICROBE,
];

export const fieldNames = {
    voc: {
        ZERO_PIPE_NUMBER: "Putken numero",
        OTHER_NOTES: "Muut huomiot (olosuhdetiedot)",
        FLEC_VOC_SAMPLE_COUNT: "Otettavien näytteiden määrä",
        FLEC_VOC_DELIVERY_TYPE: "Laitteiston toimitustapa",
        FLEC_VOC_EQUIPMENT_DELIVERY_ADDRESS: "Laitteiston toimitusosoite",
        FLEC_VOC_FETCHING_SITE: "Laitteiston noutopaikka",
    },
    general: {
        CUSTOMER_NAME: "Tilaajan nimi",
        BUSINESS_ID: "Tilaajan Y-tunnus",
        CONTACT_NAME: "Näytteenottajan nimi",
        CONTACT_EMAIL: "Sähköpostiosoite",
        BILLING_ADDRESS: "Laskutusosoite",
        CONTACT_PHONE: "Puhelinnumero",
        DELIVERY_TYPE: "Näytteiden toimitustapa",
        SUBMIT_DATE: "Tilattu",
        COMPLETED_DATE: "Valmistunut",
        ADDITIONAL_EMAILS: "Muut sähköpostiosoitteet (erota pilkulla)",
        ADDITIONAL_EMAILS_READONLY: "Muut sähköpostiosoitteet",
        ADDITIONAL_INFORMATION: "Lisätietoa laboratoriolle",
    },
    building: {
        BUILDING_NAME: "Kohteen nimi tai projektinumero",
        BUILDING_ADDRESS: "Kohteen osoite",
        BUILDING_TYPE: "Kohteen tyyppi",
        BUILDING_TYPE_STR: "Muu kohteen tyyppi",
        LOCATION_TYPE: "Kohteen sijainti",
        WINTER_CONDITIONS: "Lumi- tai jääpeite maassa",
        BUILDING_MATERIAL: "Rakennusmateriaali",
        BUILDING_MATERIAL_STR: "Muu rakennusmateriaali",
        TIME_OF_BUILDING: "Rakennusvuosikymmen",
        VENTILATION_TYPE: "Ilmanvaihto",
        VENTILATION_RUNNING: "Ilmanvaihtojärjestelmä toiminnassa näytteenoton aikana",
    },
    orderItem: {
        DATE: "Näytteenottopvm",
        SAMPLE_ID: "Asiakkaan näytetunnus",
        SAMPLE_TYPE: "Tutkimus",
        DESCRIPTION: "Näytetiedot",
        SPACE_NUMBER: "Tilan numero",
        OTHER_NOTES: "Muut huomiot (olosuhdetiedot)",
        PIPE_NUMBER: "Putken numero",
        SAMPLING_TIME: "Keräysaika (min)",
        PUMP_NUMBER: "Pumpun numero",
        PUMP_FLOW: "Pumpun virtaus (ml/min)",
        DESCRIPTION_TIP:
            " (materiaali, näytteenottokohta, vaurioepäily- vai vertailunäyte, muut oleelliset tiedot)",
        SAMPLE_LOCATION: "Näytteenottotila",
        SAMPLING_PERIOD: "Näytteenottoaika (min)",
        AIR_FLOW: "Virtausnopeus (l/min)",
        AIR_VOLUME: "Ilmamäärä (l)",
        TIME_OF_DAY: "Kellonaika",
        ROOM_TEMPERATURE: "Sisälämpötila",
        EXT_TEMPERATURE: "Ulkolämpötila",
        RELATIVE_HUMIDITY: "Ilmankosteus (RH%)",
        SAMPLE_HEIGHT: "Korkeus (m)",
        PERSONS_ANIMALS: "Henkilöiden tai eläinten määrä tilassa",
        ADDITIONAL_INFO:
            "Kerro tekijöistä, jotka voivat vaikuttaa mittaustulokseen: Onko huoneistossa näytteenottopäivänä käsitelty mm. polttopuita, tekstiileitä, multaisia elintarvikkeita, siivottu, tuuletettu, pidetty lemmikkieläimiä tai käytetty ilmanpuhdistinta? Tai onko tiedossa muita mahdollisia mikrobilähteitä, jotka voivat vaikuttaa tulokseen?",
        PREVIOUS_WATER_DAMAGE: "Huoneistossa on ollut aikaisempia vesivauriokorjauksia.",
        WATER_DAMAGE_DATE: "Korjausten ajankohta",
        SURFACE_AREA: "Pinta-ala",
        CONTROL_SAMPLE: "Kyseessä on vertailunäyte",
    },
};

export const labels = {
    [OrderType.MATERIAL_MICROBE]: `Merkitse tähän rasti, jos näytteet on toimitettu laboratorioon yli viiden vuorokauden kuluttua näytteenotosta ja haluat kuitenkin, että ne viljellään. Huomioi myös, että tässä tapauksessa raporttiin tulee merkintä: "Näytteitä ei toimitettu laboratorioon Asumisterveysasetuksen soveltamisohjeessa osa IV 8/2016, päivitysversio 19.2.2020 (Asumisterveysasetus § 20) esitetyssä viidessä vuorokaudessa".`,
    [OrderType.BRUSHING_MICROBE]: `Merkitse tähän rasti, jos näytteet on toimitettu laboratorioon yli yhden vuorokauden kuluttua näytteenotosta ja haluat kuitenkin, että ne viljellään. Huomioi myös, että tässä tapauksessa raporttiin tulee merkintä: "Näytteitä ei toimitettu laboratorioon Asumisterveysasetuksen soveltamisohjeessa osa IV 8/2016, päivitysversio 19.2.2020 (Asumisterveysasetus § 20) esitetyssä yhdessä vuorokaudessa".`,
};

export const flecVocDeliveryTypeOptions = [
    {
        text: "Laitteisto toimitetaan sovittuun osoitteeseen",
        value: 1,
    },
    {
        text: "Asiakas noutaa laitteiston laboratoriolta",
        value: 2,
    },
];

export const flecVocFetchingSites = [
    {
        text: "Tampereen laboratorio",
        value: 1,
    },
    {
        text: "Jyväskylän laboratorio",
        value: 2,
    },
    {
        text: "Helsingin Konalan laboratorio",
        value: 3,
    },
    {
        text: "Helsingin Roihupellon laboratorio",
        value: 4,
    },
];
