import React from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../router/routes';
import { InterActiveTable } from './InterActiveTable';

/**
 * Table component to display a list of users for an admin
 * @param {object} props Component props
 * @param {object[]} props.customers The users to display in the table
 * @param {integer} props.onDelete Handler for a delete user button
 */
export function CustomerTable({
    customers,
    onDelete
}) {

    const history = useHistory();

    const columns = [
        {
            content: "Asiakas",
            name: "userName",
            sortable: true
        },        
        {
            content: "Yritys",
            name: "companyName",
            sortable: true
        },
        {
            content: "Rooli",
            name: "rooli",
            sortable: true
        },
        {
            content: "Sähköposti",
            name: "email",
            sortable: true
        },
        {
            content: "Puhelin",
            name: "phone"
        }
    ];

    return (
        <InterActiveTable
            title="Asiakkaat"
            columns={ columns }
            data={ customers }
            onView={ (id) => { 
                    const customer = customers.find( x => x.id === id );
                    history.push(customer.role === 'CUSTOMER' ? `${routes.ADMIN_VIEW_USER}/${id}` : `${routes.ADMIN_VIEW_SAMPLER}/${id}`);
                } 
            }
            onDelete={ onDelete }
        />
    );
}