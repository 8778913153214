import { email, required } from './rules';

export const constraints = {
    email: {
        presence: {
            ...required
        },
        ...email
    },
    password: {
        presence: {
            ...required
        }
    }
}