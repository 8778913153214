import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_CLIENT,
    SIGNUP_SUCCESS,
    SIGNUP_FAILURE,
    UPDATE_PROFILE_SUCCESS,
    AUTH_REQUEST,
    AUTH_REQUEST_END,
    CONFIRM_LOGOUT,
    CANCEL_LOGOUT,
    GET_ME_REQUEST,
    GET_ME_SUCCESS,
    GET_ME_FAILURE
} from '../actions/actions'

const initialState = {
    isFetching: false,
    isFetchingMe: false,
    isAuthenticated: false,
    isAdmin: false,
    isSampler: false,
    user: null,
    showConfirmLogout: false
};

export const auth = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case AUTH_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case AUTH_REQUEST_END: {
            return {
                ...state,
                isFetching: false
            };
        }
        case SIGNUP_SUCCESS:
        case LOGIN_SUCCESS: {
            const { user } = action;
            const isAdmin = user.role === 'ADMIN';
            const isSampler = user.role === 'SAMPLER';
            return {
                ...state,
                isFetching: false,
                isAuthenticated: true,
                isAdmin,
                isSampler,
                user
            };
        }
        case CONFIRM_LOGOUT: {
            return {
                ...state,
                showConfirmLogout: true
            }
        }
        case CANCEL_LOGOUT: {
            return {
                ...state,
                showConfirmLogout: false
            }
        }
        case SIGNUP_FAILURE:
        case LOGIN_FAILURE:
        case LOGOUT_CLIENT:
        case GET_ME_FAILURE: {
            return initialState;
        }
        case UPDATE_PROFILE_SUCCESS: {
            const { update } = action;
            const user = {
                ...state.user,
                ...update
            };
            return {
                ...state,
                user,
                isFetching: false
            };
        }
        case GET_ME_REQUEST: {
            return {
                ...state,
                isFetchingMe: true
            }
        }
        case GET_ME_SUCCESS: {
            const { user } = action;
            const isAdmin = user.role === 'ADMIN';
            const isSampler = user.role === 'SAMPLER';
            return {
                ...state,
                isFetchingMe: false,
                isAuthenticated: true,
                isAdmin,
                isSampler,
                user
            };
        }
        default: {
            return state;
        }
    }
}
