import React from "react";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Container,
  Header,
  Icon
} from "semantic-ui-react";
import { routes } from "../router/routes";

/**
 * Page to display when route not found
 */
export function NotFound() {
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  let home = routes.HOME;
  if (isAuthenticated && isAdmin) {
      home = routes.ADMIN_DASHBOARD;
  } else if (isAuthenticated) {
      home = routes.USER_DASHBOARD;
  }
  return (
    <Container className="page-wrapper">
        <Header size='huge' color='yellow'>Sivua ei löydy</Header>
        <Header as={ Link } to={ home } size='medium' color="yellow">Siirry kotisivulle<Icon name="caret right"></Icon></Header>
    </Container>
  );
}
