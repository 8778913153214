import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { LOGOUT_CLIENT } from './actions/actions';
import { auth } from './reducers/auth';
import { error } from './reducers/error';
import { order } from './reducers/order';
import { unregisteredOrder } from './reducers/unregisteredOrder';
import { orders } from './reducers/orders';
import { samplers } from './reducers/samplers';
import { users } from './reducers/users';
import { metadata } from './reducers/metadata';

const appReducer = combineReducers({
    auth,
    error,
    order,
    unregisteredOrder,
    orders,
    users,
    metadata,
    samplers
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_CLIENT) {
        state = {
            auth: undefined,
            error: undefined,
            order: undefined,
            unregisteredOrder: undefined,
            orders: undefined,
            users: undefined,
            samplers: undefined,
            metadata: state.metadata
        };
    }

    return appReducer(state, action);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer,
    {},
    composeEnhancers(
        applyMiddleware(
        ReduxThunk
    ))
);

export default store;

