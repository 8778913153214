import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  useHistory,
  Link
} from "react-router-dom";
import {
  Container,
  Grid,
  Header
} from "semantic-ui-react";
import { login, logout } from '../actions/auth';
import { LoginForm } from "../components/LoginForm";
import { AppLoader } from "../components/AppLoader";
import { routes } from "../router/routes";

/**
 * Login page
 */
export default function Login() {
  const history = useHistory();
  const isFetching = useSelector((state) => state.auth.isFetching);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isSampler = useSelector((state) => state.auth.isSampler);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    async function logoutAdmin() {
      await dispatch(logout());
    }
    if(isAdmin) {    
      logoutAdmin();
      return;
    }
    if(isAuthenticated) {
      history.replace(isSampler ? "/sampler" : "/user");
    }
  }, [dispatch,isAuthenticated]);  

  async function handleLogin(credentials) {
    try {
      await dispatch(login(credentials));
    } catch(error) {
      // The error's been handled, this is just to let login page know about it
    }
  }

  return (
    <Container>
      <AppLoader
        active={ isFetching }
        page={ true }
      />
      <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header size="huge" color="yellow" textAlign="center">
            Kirjaudu TAKLAB-tilausjärjestelmään
          </Header>
          <LoginForm onSubmit={ handleLogin } />
          <p style={{ marginTop: '1rem' }}>
            <Link to={ routes.USER_SIGNUP } color="red">
                Ei tunnuksia? Luo yritystili tästä
            </Link>
          </p>
          <p>
            <Link to={ routes.FORGOT_PASSWORD }>
                Unohtuiko salasana? Uusi salasanasi täällä
            </Link>
          </p>
        </Grid.Column>
      </Grid>
    </Container>
  );
}
