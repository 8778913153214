import { partial } from 'lodash';
import { OrderType } from '../enums';
import { businessId, email , required} from './rules';

const orderData = {
    customerName: {
        presence: {
            ...required
        }
    },
    billingAddress: {
        presence: {
            ...required
        }
    },
    businessId: {
        ...businessId
    },
    contactEmail: {
        presence: {
            ...required
        },
        ...email
    },
    contactName: {
        presence: {
            ...required
        }
    },
    contactPhone: {
        presence: {
            ...required
        }
    },
    items: {
        hasUniqueProperty: {
            props: ['sampleId'],
            message: "Asiakkaan näytetunnusten tulee olla yksilöllisiä. Tarkista asiakkaan näytetunnukset."
        }
    },
    additionalEmails: {
        array: {
            email: {
                message: '"%{value}" ei ole hyväksytty sähköpostiosoite.'
            }
        }
    }
}

const microbeInfo = {
    petriDishTemperature: {
        presence: {
            ...required,
            message: 'Valitse säilytyslämpötila'
        }
    }
}

const zeroPipeData = {
    zeroPipeNumber: {
        presence: {
            ...required
        },
        numericality: {
            onlyInteger: true,
            greaterThan: 99999,
            lessThan: 1000000
        }           
    }
}

const flecVocData = {
    flecVocSampleCount: {
        presence: {
            ...required
        },
        numericality: {
            onlyInteger: true,
            greaterThan: 0
        }        
    },
    flecVocDeliveryTypeId: {
        presence: {
            ...required,
            message: 'Valitse laitteiston toimitustapa'
        }
    },
    flecVocEquipmentDeliveryAddress: partial(conditionalPresence, 'flecVocDeliveryTypeId'),
    flecVocFetchingSiteId: partial(conditionalPresence_2, 'flecVocDeliveryTypeId')
}

const buildingData = {
    buildingAddress: {
        presence: {
            ...required
        }
    }
}

const microbeBuildingData = {
    ...buildingData,
    buildingTypeId: {
        presence: {
            ...required,
            message: 'Valitse kohteen tyyppi'
        }
    },
    buildingTypeStr: partial(conditionalPresence, 'buildingTypeId')
}

const extendedBuildingData = {
    buildingMaterialId: {
        presence: {
            ...required,
            message: "Valitse rakennusmateriaali"
        }
    },
    locationTypeId: {
        presence: {
            ...required,
            message: "Valitse kohteen sijainti"
        }
    },
    timeOfBuilding: {
        presence: {
            ...required
        }
    },
    ventilationTypeId: {
        presence: {
            ...required,
            message: "Valitse ilmanvaihdon tyyppi"
        }
    },
    buildingMaterialStr: partial(conditionalPresence, 'buildingMaterialId')
}

export const constraints = {
    [OrderType.MATERIAL_FIBER]: {
        ...orderData,
        ...buildingData
    },
    [OrderType.VOLATILE_FIBER]: {
        ...orderData,
        ...buildingData
    },
    [OrderType.MATERIAL_MICROBE]: {
        ...orderData,
        ...microbeBuildingData
    },
    [OrderType.VOLATILE_MICROBE]: {
        ...orderData,
        ...microbeBuildingData,
        ...extendedBuildingData,
        ...microbeInfo
    },
    [OrderType.BRUSHING_MICROBE]: {
        ...orderData,
        ...microbeBuildingData
    },
    [OrderType.BRUSHING_FIBER]: {
        ...orderData,
        ...buildingData
    },
    [OrderType.MATERIAL_VOC]: {
        ...orderData,
        ...buildingData
    },
    [OrderType.VOLATILE_VOC]: {
        ...orderData,
        ...microbeBuildingData,
        ...zeroPipeData

    },    
    [OrderType.FLEC_VOC]: {
        ...orderData,
        ...buildingData,
        ...flecVocData
    },    
}

function conditionalPresence(referenceName, value, attributes) {
    if (attributes[referenceName] == 1) {
        return {
            presence: {
                ...required
            }
        };
    }
    return null;
}

function conditionalPresence_2(referenceName, value, attributes) {
    if (attributes[referenceName] == 2) {
        return {
            presence: {
                ...required,
                message: 'Valitse laitteiston noutopaikka'
            }
        };
    }
    return null;
}