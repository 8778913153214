
import {
    SAMPLERS_REQUEST,
    SAMPLERS_REQUEST_SUCCESS,
    SAMPLERS_REQUEST_FAILURE,
    DELETE_SAMPLER,
    DELETE_SAMPLER_SUCCESS,
    DELETE_SAMPLER_FAILURE,
    CLEAR_SAMPLERS,
    FETCH_SINGLE_SAMPLER_BY_USER,
    FETCH_SINGLE_SAMPLER_BY_USER_FAILURE,
    FETCH_SINGLE_SAMPLER_BY_USER_SUCCESS,
    CLEAR_SINGLE_SAMPLER_BY_USER, // <-- HOX! Keksi tälle käyttö
    UPDATE_SAMPLER_PROFILE,
    UPDATE_SAMPLER_PROFILE_SUCCESS,
    UPDATE_SAMPLER_PROFILE_FAILURE
} from '../actions/actions'

export const samplers = (
    state = {
        userSampler: null, //sampler viewed by user (customer)        
        samplers: [],
        isFetching: false
    },
    action
) => {
    switch (action.type) {
        case DELETE_SAMPLER:
        case FETCH_SINGLE_SAMPLER_BY_USER:
        case UPDATE_SAMPLER_PROFILE:
        case SAMPLERS_REQUEST: {
            return {
                ...state,
                isFetching: true
            }
        }
        case DELETE_SAMPLER_FAILURE:
        case FETCH_SINGLE_SAMPLER_BY_USER_FAILURE:
        case UPDATE_SAMPLER_PROFILE_FAILURE:
        case SAMPLERS_REQUEST_FAILURE: {
            return {
                ...state,
                isFetching: false
            }
        }
        case UPDATE_SAMPLER_PROFILE_SUCCESS: {
            const { update } = action;
            const userSampler = {
                ...state.userSampler,
                update
            };
            return {
                ...state,
                userSampler,
                isFetching: false
            };
        }
        case FETCH_SINGLE_SAMPLER_BY_USER_SUCCESS: {
            const { userSampler } = action;
            console.log("userSampler:");
            console.log(JSON.stringify(userSampler));
            return {
                ...state,
                userSampler,
                isFetching: false
            }
        }                
        case SAMPLERS_REQUEST_SUCCESS: {
            const { samplers } = action;
            return {
                ...state,
                samplers,
                isFetching: false
            }
        }
        case DELETE_SAMPLER_SUCCESS: {
            const { id } = action;
            const samplers = state.samplers.filter(sampler => sampler.id !== id);
            return {
                ...state,
                samplers,
                isFetching: false
            }
        }
        case CLEAR_SAMPLERS: {
            return {
                ...state,
                samplers: []
            }
        }
        default: {
            return state;
        }
    }
}
