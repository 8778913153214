import React, { useState } from "react";
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Header,
  Grid,
  Form,
  Segment,
  Button
} from "semantic-ui-react";
import { AppLoader } from "../components/AppLoader";
import { validateInitPasswordReset } from "../validation/validate";
import { InfoModal } from "../components/InfoModal";
import { useHistory } from "react-router-dom";
import { routes } from "../router/routes";
import { initPasswordReset } from "../actions/auth";

/**
 * Forgot password page. Lets user input an email address to send further instructions.
 */
export function ForgotPassword() {

    const isFetching = useSelector((state) => state.auth.isFetching);
    const dispatch = useDispatch();

    const [data, setData] = useState({});
    const [errors, setErrors] = useState({});
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const history = useHistory();

    async function handleSubmit() {
        const err = validateInitPasswordReset(data);
        setErrors(err || {});
        if (!err) {
            try {
                await dispatch(initPasswordReset(data));
                setShowSuccessModal(true);
            } catch(error) {
                // Something went wrong
            }
        }
    }

    function handleBlur() {
        if (!isEmpty(errors)) {
            const err = validateInitPasswordReset(data);
            setErrors(err || {});
        }
    }

  return (
    <Container>
      <AppLoader
        active={ isFetching }
        page={ true }
      />
      <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
            <Header size="huge" color="yellow" textAlign="center">
                Aloita salasanan palautus
            </Header>
            <Form
                size='large'
                onSubmit={ handleSubmit }
            >
                <Segment textAlign="left">
                    <Form.Field>
                        <Form.Input
                            name="password"
                            label="Sähköpostiosoite"
                            error={ errors.email }
                            value={ data.email || '' }
                            fluid
                            icon='user'
                            iconPosition='left'
                            placeholder='Sähköpostiosoite'
                            onChange={ (event) => setData({
                                ...data,
                                email: event.target.value
                            }) }
                            onBlur={ handleBlur }
                            maxLength="100"
                        />
                    </Form.Field>
                    <Button
                        fluid
                    >
                        Lähetä
                    </Button>
                </Segment>
            </Form>
        </Grid.Column>
      </Grid>
      <InfoModal
            content="Antamaasi sähköpostiosoitteeseen on lähetetty ohjeet salasanan palautusta varten."
            open={ showSuccessModal }
            onClose={  () => history.push(routes.HOME) }
            onConfirm={ () =>  history.push(routes.HOME) }
        />
    </Container>
  );
}
