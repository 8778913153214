import React from 'react';
import {
    Grid,
    Checkbox,
    Header
} from "semantic-ui-react";
import { labels } from '../../enums';

export function MicrobeInfoView({ order }) {
    const {
        petriDishTemperature,
        orderTypeId,
        threeDays
    } = order;

    const petriDishTemperatureStr = petriDishTemperature === 1 ? 'huoneenlämmössä': 'viileässä';

    return (
        <>
        <Header as="h3">Mikrobiologisen tutkimuksen lisätiedot</Header>
            <Grid.Row columns="1">
                <Grid.Column>
                    { typeof threeDays === 'boolean' &&
                        <Checkbox
                            name="threeDays"
                            checked={ threeDays}
                            label={ labels[orderTypeId] }
                        />
                    }
                    { petriDishTemperature &&
                        <p>
                            Elatusainemaljoja on säilytetty ennen laboratorioon toimittamista <span style={{ fontWeight: 700 }}>{ petriDishTemperatureStr }</span>
                        </p>
                    }
                </Grid.Column>
            </Grid.Row>
        </>
    )
}