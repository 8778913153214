import React, { useRef } from 'react';
import { Form } from 'semantic-ui-react';
import { IconWithTooltip } from './IconWithTooltip';

/**
 * A component that allows uploading an attachment file with an icon trigger
 * @param {object} props Component props
 * @param {object} props.onSubmit Form submit handler
 */
export function UploadResultForm({
    onSubmit
}) {

    const fileInputRef = useRef();

    return (
        <>
            <Form
                style={{ display: 'none' }}
                onSubmit={ (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.target);
                    onSubmit(formData);
                }}
            >
                <input
                    ref={ fileInputRef }
                    name="result-upload"
                    type="file"
                    onClick={ (event) => {
                        event.stopPropagation();
                    } }
                    onChange={ (event) => {
                        event.target.form.requestSubmit();
                    }}
                />
            </Form>
            <IconWithTooltip
                tooltip="Tallenna tulokset (PDF)"
                link
                color="black"
                size="large"
                name="upload"
                onClick={ (event) => {
                    event.stopPropagation();
                    fileInputRef.current.click();
                } }
            />
        </>
    )
}