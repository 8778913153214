import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Container,
  Grid,
  Header,
  Segment,
  Divider
} from "semantic-ui-react";
import { updateUser } from '../../actions/users';
import { fetchUser, clearUser } from '../../actions/users';
import { deleteSampler } from '../../actions/samplers';
import { PageContent } from "../../components/PageContent";
import { useState } from "react";
import { InfoModal } from "../../components/InfoModal";
import { AppLoader } from '../../components/AppLoader';
import { useEffect } from "react";
import { SamplerProfileForm } from "../../components/SamplerProfileForm";
import { CompanyProfile } from "../../components/CompanyProfile";
import { DeleteSamplerAccountSection } from "../../components/DeleteSamplerAccountSection";

/**
 * Admin single user page with sampler profile
 */
export function SingleSampler() {

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  const user = useSelector((state) => state.users.user);
  const isFetching = useSelector((state) => state.users.isFetching);

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchUser(id));
    }
    return function cleanup() {
        dispatch(clearUser());
    }
  }, [dispatch, id]);

  async function handleUpdateProfile(update) {
    try {
      await dispatch(updateUser(id, update));
      setEditProfile(false);
      setShowConfirmation(true);
    } catch(error) {
      // Let this component know it failed
    }
  }

  async function handleConfirmDeleteSampler() {
    try {
      await dispatch(deleteSampler(id));
      setShowDeleteSuccessModal(true);
    } catch(error) {
    }
  }  

  return (
    <Container className="page-wrapper">
      <AppLoader
        active={ isFetching }
        page={ true }
      />
      <PageContent>
          <Grid.Row>
              <Grid.Column width={ 10 }>
                <Header
                  size='huge'
                  color='yellow'>
                    Näytteenottaja
                </Header>
              </Grid.Column>
              <Grid.Column
                textAlign="right"
                width={ 6 }
                className="single-order-actions"
              >
                <Button
                  onClick={ history.goBack }>
                    Takaisin
                </Button>
              </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={ 16 }>
                <Header size="huge" color="yellow">Yrityksen tiedot</Header>
                <Segment
                    className="segment-rectangle"
                >
                    <CompanyProfile
                    company={ user }
                    />
                </Segment>
                <Header size="huge" color="yellow">Näytteenottajan tiedot</Header>
                <Segment
                    className="segment-rectangle"
                >
                    <SamplerProfileForm
                    editProfile={ editProfile }
                    onCancel={ () => setEditProfile(false) }
                    user={ user }
                    onUpdate={ handleUpdateProfile }
                    />
                    { !editProfile &&
                    <Button
                        primary
                        onClick={ () => setEditProfile(true)}>
                            Muokkaa tietoja
                    </Button>
                    }
                <Divider />
                <DeleteSamplerAccountSection
                  onSubmit={ handleConfirmDeleteSampler }
                />
                </Segment>
            </Grid.Column>
          </Grid.Row>
      </PageContent>
      <InfoModal
        content={ 'Näytteenottajan tiedot tallennettu' }
        open={ showConfirmation }
        onClose={  () => setShowConfirmation(false) }
        onConfirm={ () => setShowConfirmation(false) }
      />
      <InfoModal
          content={ `Näytteenottaja poistettu onnistuneesti.` }
          open={ showDeleteSuccessModal }
          onClose={ () => history.goBack() }
          onConfirm={ () => history.goBack() }
      />      
    </Container>
  );
}
