import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Button, Form, Modal } from 'semantic-ui-react';
import {
    MaterialForm,
    VolatileFiberForm,
    VolatileMicrobeForm,
    BrushingMicrobeForm,
    MaterialVocForm,
    VolatileVocForm
} from './items/OrderItemForms';
import { OrderType } from '../../enums';
import { validateOrderItem } from '../../validation/validate';
import { getFormFieldValue } from '../../utils';

/**
 * Modal component for displaying a form to add sample items to an order
 * @param {object} props Component props
 * @param {string} props.defaultSampleId The string to use as a default customer sample id.
 * @param {object} props.edit A sample item object to edit.
 * @param {object} props.metadata Sample item metadata to use for sample type form field options.
 * @param {function} props.onClose Modal close handler
 * @param {function} props.onSubmit Sample item submit handler
 * @param {boolean} props.open Indicates whether to show the modal
 * @param {number} props.orderTypeId Indicates the order type to use to display a correct sample item form.
 */
export function AddOrderItemModal({
    defaultSampleId,
    edit,
    metadata,
    onClose,
    onSubmit,
    open,
    orderTypeId
}) {
    const [orderItem, updateOrderItem] = useState({
        date: new Date()
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const defaultItem = {
            date: new Date()
        };
        if (defaultSampleId) {
            defaultItem.sampleId = defaultSampleId;
        }
        updateOrderItem(defaultItem);
        setErrors({});
    }, [open, defaultSampleId]);

    useEffect(() => {
        if (edit) {
            updateOrderItem(edit);
        }
    }, [edit]);

    function handleChange(event, data) {
        const name = data.name;
        const value = getFormFieldValue(event, data);
        let waterDamageUpdate = {};
        if (name === 'previousWaterDamage' && !value) {
            waterDamageUpdate.waterDamageDate = '';
        }
        updateOrderItem({
            ...orderItem,
            [name]: value,
            ...waterDamageUpdate
        });
    }

    function handleSubmit() {
        const isValid = validate(orderItem);
        if (isValid) {
            onSubmit(orderItem);
        }
    }

    function handleBlur() {
        if (!isEmpty(errors)) {
          validate(orderItem);
        }
      }

    function validate(item) {
        const errors = validateOrderItem(item, orderTypeId);
        if (errors) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
            return true;
        }
    }

    let formContent;
    switch(orderTypeId) {
        case OrderType.MATERIAL_FIBER: {
            formContent = (
                <MaterialForm
                    item={ orderItem }
                    errors={ errors }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                    maxSampleTypes={ 3 }
                    sampleTypes={ metadata.sampleType.filter(type => type.orderTypeId === orderTypeId)}
                />
            )
            break;
        }
        case OrderType.BRUSHING_FIBER: {
            formContent = (
                <MaterialForm
                    item={ orderItem }
                    errors={ errors }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                    maxSampleTypes={ 1 }
                    sampleTypes={ metadata.sampleType.filter(type => type.orderTypeId === orderTypeId)}
                />
            )
            break;
        }
        case OrderType.MATERIAL_MICROBE: {
            formContent = (
                <MaterialForm
                    item={ orderItem }
                    errors={ errors }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                    maxSampleTypes={ 1 }
                    microbe={ true }
                    sampleTypes={ metadata.sampleType.filter(type => type.orderTypeId === orderTypeId)}
                />
            )
            break;
        }
        case OrderType.VOLATILE_FIBER: {
            formContent = (
                <VolatileFiberForm
                    item={ orderItem }
                    errors={ errors }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                />
            )
            break;
        }
        case OrderType.VOLATILE_MICROBE: {
            formContent = (
                <VolatileMicrobeForm
                    item={ orderItem }
                    errors={ errors }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                />
            )
            break;
        }
        case OrderType.BRUSHING_MICROBE: {
            formContent = (
                <BrushingMicrobeForm
                    item={ orderItem }
                    errors={ errors }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                    sampleTypes={ metadata.sampleType.filter(type => type.orderTypeId === orderTypeId)}
                />
            )
            break;
        }
        case OrderType.MATERIAL_VOC: {
            formContent = (
                <MaterialVocForm
                    item={ orderItem }
                    errors={ errors }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                />
            )
            break;
        }
        case OrderType.VOLATILE_VOC: {
            formContent = (
                <VolatileVocForm
                    item={ orderItem }
                    errors={ errors }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                />
            )
            break;
        }
        default: {
            formContent = null;
        }
    }

    return (
        <Modal
            size="small"
            closeOnDimmerClick={ false }
            open={ open }
            onClose={ () => {
                updateOrderItem({});
                onClose();
            }}
            onOpen={ () => console.log('open modal') }
        >
            <Modal.Header>Lisää näyte</Modal.Header>
            <Modal.Content>
                <Form>
                    { formContent }
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={ () => {
                        updateOrderItem({});
                        onClose();
                    } }>
                    Peruuta
                </Button>
                <Button
                    primary
                    onClick={ handleSubmit }>
                    Lisää
                </Button>
            </Modal.Actions>
        </Modal>
    )
}