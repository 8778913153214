import React from 'react';
import { Form } from 'semantic-ui-react';
import { useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { validateSamplerProfileUpdate } from "../validation/validate";

/**
 * User profile section to display and edit sampler profile information
 * @param {object} props Component props
 * @param {boolean} props.editProfile Indicates if editing the profile information
 * @param {function} props.onCancel Handler for cancel updates button
 * @param {function} props.onUpdate Handler for submitting the profile updates
 * @param {object} props.user The sampler to display
 */
export function SamplerProfileForm({
    editProfile,
    onCancel,
    onUpdate,
    user,
}) {

    const [errors, setErrors] = useState({});
    const [profileUpdate, setProfileUpdate] = useState({});

    function handleChange(event, data) {
        const {
          name,
          value
        } = data;
        setProfileUpdate({
          ...profileUpdate,
          [name]: value
        });
    }

    async function handleUpdateProfile() {
        const userProfile = {
          ...user,
          ...profileUpdate
        };
        const isValid = validate(userProfile);
        if (isValid) {
            onUpdate(profileUpdate);
        }
    }

    function handleCancel() {
        setProfileUpdate({});
        setErrors({});
        onCancel();
    }

    function handleBlur() {
        if (!isEmpty(errors)) {
            const userProfile = {
            ...user,
            ...profileUpdate
            };
            validate(userProfile);
        }
    }

    function validate(userProfile) {
        const errors = validateSamplerProfileUpdate(userProfile);
        if (errors) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
            return true;
        }
    }

    if(!user) {
        return null;
    }

    return (
        <Form>
            <Form.Group widths={ 2 }>
            <Form.Input
                fluid
                required={ editProfile }
                readOnly={ !editProfile }
                error={ errors.email }
                name="userName"
                value={ 'userName' in profileUpdate ? profileUpdate.userName : user.userName }
                onChange={ handleChange }
                onBlur={ handleBlur }
                label='Näytteenottajan nimi'
                placeholder='Nimi'
                maxLength="100"
            />
            </Form.Group>
            <Form.Group widths={ 2 }>
            <Form.Input
                fluid
                required={ editProfile }
                readOnly={ !editProfile }
                error={ errors.email }
                name="email"
                value={ 'email' in profileUpdate ? profileUpdate.email : user.email }
                onChange={ handleChange }
                onBlur={ handleBlur }
                label='Näytteenottajan sähköpostiosoite'
                placeholder='Sähköpostiosoite'
                maxLength="100"
            />
            <Form.Input
                fluid
                required={ editProfile }
                readOnly={ !editProfile }
                error={ errors.phone }
                name="phone"
                value={ 'phone' in profileUpdate ? profileUpdate.phone : user.phone }
                onChange={ handleChange }
                onBlur={ handleBlur }
                label='Näytteenottajan puhelinnumero'
                placeholder='Puhelinnumero'
                maxLength="50"
            />
            </Form.Group>
            { editProfile &&
                <Form.Group>
                    <Form.Button
                        type="button"
                        onClick={ handleCancel }
                    >Peruuta</Form.Button>
                    <Form.Button
                        disabled={ isEqual(user, profileUpdate) }
                        type="button"
                        onClick={ handleUpdateProfile }
                        primary
                    >Tallenna</Form.Button>
                </Form.Group>
            }
        </Form>
    )
}
