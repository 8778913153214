import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Header,
  Grid
} from "semantic-ui-react";
import { AppLoader } from "../components/AppLoader";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "../router/routes";
import { signupSampler, validateSamplerInvitationToken } from "../actions/auth";
import SamplerSignupForm from '../components/SamplerSignupForm';

export function SamplerSignup() {
    const history = useHistory();
    const { token } = useParams();

    const isFetching = useSelector((state) => state.auth.isFetching);
    const dispatch = useDispatch();

    useEffect(() => {
        async function validateToken() {
            try {
                await dispatch(validateSamplerInvitationToken({ token }));
                // TODO: tässä oli (kts. ResetPassword.jsx) isValid boolean-staten asetus, 
                // jolla estettiin formin renderöinti ennen validoinnin tuloksen selviämistä
            } catch(error) {
                history.replace(routes.HOME);
            }
        }
        validateToken();
    }, [dispatch, history, token]);

    async function handleSignup(data) {
        try {
            await dispatch(signupSampler({
                ...data,
                token
            }));
            history.replace(routes.SAMPLER_DASHBOARD);
        } catch(error) {
            // Something went wrong
        }
    }

  return (
    <Container className="page-wrapper">
        <AppLoader
        active={ isFetching }
        page={ true }
        />
        <Grid textAlign="center">
        <Grid.Column style={{ maxWidth: 450 }}>
            <Header size="huge" color="yellow" textAlign="center">
            Rekisteröidy näytteenottajaksi Taklab-järjestelmään
            </Header>
            <SamplerSignupForm onSignup={ handleSignup } />
        </Grid.Column>
        </Grid>
    </Container>
  );
}
