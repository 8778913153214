import React from "react";
import { Link } from "react-router-dom";
import { filter, sortBy } from "lodash";
import { Container, Header, Segment, Button, Message } from "semantic-ui-react";
import { routes } from "../router/routes";
import { clearOrders, fetchOrders } from "../actions/orders";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { AppLoader } from "../components/AppLoader";
import UserOrderTable from "../components/UserOrderTable";
import moment from "moment";
import { useRef } from "react";
import { scrollToRef } from "../utils";
import { SamplerProfileForm } from "../components/SamplerProfileForm";
import { CompanyProfile } from "../components/CompanyProfile";

/**
 * Customer dashboard page to display latest completed and submitted orders and profile information.
 */
export function SamplerDashboard() {
    const user = useSelector((state) => state.auth.user);
    const isFetching = useSelector((state) => state.orders.isFetching);
    const orders = useSelector((state) => state.orders.orders);
    const dispatch = useDispatch();
    const completedRef = useRef(null);
    const completed = orders.filter((order) => order.status === "COMPLETED" && !order.resultSeen);
    const latestOrders = sortBy(
        filter(orders, (order) => order.status === "SUBMITTED"),
        [(order) => moment(order.createdAt)]
    ).slice(-5);

    useEffect(() => {
        console.log("SamplerDashboard useEffect");
        dispatch(clearOrders());
        dispatch(fetchOrders());
    }, [dispatch]);

    return (
        <Container className="page-wrapper">
            <AppLoader active={isFetching} page={true} />
            {completed.length > 0 && (
                <Message positive>
                    <p>Sinulla on {completed.length} kpl uusia tutkimustuloksia.</p>
                    <Button positive onClick={() => scrollToRef(completedRef, 90)}>
                        Näytä
                    </Button>
                </Message>
            )}
            <Header size="huge" color="yellow">
                TAKLAB-tilausjärjestelmä
            </Header>
            <Segment className="segment-rectangle">
                <p>
                    Tervetuloa käyttämään TAKLAB-tilausjärjestelmää! TAKLAB-tilausjärjestelmässä
                    voit täyttää tilauslomakkeen, lisätä haluamasi määrän näyterivejä ja valita
                    näytteiden toimitustavan. Kun toimitat näytteet valitsemaasi näytepisteeseen,
                    saat tulokset PDF-tiedostona suoraan TAKLAB-järjestelmään sekä sähköpostiisi
                    heti tutkimusten valmistuttua. Asbestinäytteiden tuloksista lähetämme myös
                    tekstiviestin puhelimeen välittömästi tulosten valmistuttua.
                </p>
                <p>
                    TAKLAB-järjestelmään voit myös tallentaa luonnoksina keskeneräisiä
                    tilauslomakkeita. Siten pystyt myöhemmin muokkaamaan lomakkeen tietoja ja
                    näyterivejä ennen tilauksen lähettämistä.
                </p>
                <p>
                    TAKLAB-järjestelmän tilausarkistossa voit selata kaikkia lähettämiäsi tilauksia
                    sekä valmistuneita tutkimuksia. Voit myös muokata tai poistaa järjestelmään
                    tallentamiasi keskeneräisiä tilauslomakkeita.
                </p>
                <Container textAlign="right" className="button-wrapper">
                    <Button primary as={Link} to={routes.SAMPLER_HELP}>
                        Lue ohjeet
                    </Button>
                </Container>
            </Segment>
            <div style={{ visibility: "hidden" }} ref={completedRef}></div>
            <Header size="huge" color="yellow">
                Valmistuneet tutkimukset
            </Header>
            <Segment className="segment-rectangle">
                <Header as="h3">Uudet valmistuneet tutkimukset ({completed.length})</Header>
                {completed.length ? (
                    <UserOrderTable orders={completed} status={"completed"} />
                ) : (
                    <Message>Ei uusia valmistuneita tutkimuksia</Message>
                )}
                <Container textAlign="right" className="button-wrapper">
                    <Button primary as={Link} to={`${routes.SAMPLER_ARCHIVE}/completed`}>
                        Näytä kaikki
                    </Button>
                    <Button primary as={Link} to={routes.SAMPLER_CREATE_ORDER}>
                        Tilaa tutkimus
                    </Button>
                </Container>
            </Segment>
            <Header size="huge" color="yellow">
                Tilausarkisto
            </Header>
            <Segment className="segment-rectangle">
                <Header as="h3">Viimeisimmät lähetetyt tilaukset</Header>
                {latestOrders.length ? (
                    <UserOrderTable orders={latestOrders} status={"submitted"} />
                ) : (
                    <Message>Ei lähetettyjä tilauksia</Message>
                )}
                <Container
                    textAlign="left"
                    className="button-wrapper"
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <div style={{ fontSize: "17px" }}>
                        Huom! Kun tilauksesi on valmistunut, löydät sen{" "}
                        <Link
                            to={`${routes.SAMPLER_ARCHIVE}/completed`}
                            style={{ color: "blue", textDecoration: "underline" }}
                        >
                            valmistuneista tutkimuksista.
                        </Link>
                    </div>
                    <div style={{ minWidth: "150px", textAlign: "right" }}>
                        <Button primary as={Link} to={`${routes.SAMPLER_ARCHIVE}/submitted`}>
                            Näytä kaikki
                        </Button>
                    </div>
                </Container>
            </Segment>
            <Header size="huge" color="yellow">
                Yrityksen tiedot
            </Header>
            <Segment className="segment-rectangle">
                <CompanyProfile company={user} />
            </Segment>
            <Header size="huge" color="yellow">
                Näytteenottajan tiedot
            </Header>
            <Segment className="segment-rectangle">
                <SamplerProfileForm editProfile={false} user={user} />
                <Container textAlign="right" className="button-wrapper">
                    <Button primary as={Link} to={routes.SAMPLER_PROFILE}>
                        Tietojen hallinta
                    </Button>
                </Container>
            </Segment>
        </Container>
    );
}
