import React from "react";
import { Grid, Header } from "semantic-ui-react";
import {
    fieldNames,
    flecVocDeliveryTypeOptions,
    flecVocFetchingSites,
    FlecVocDeliveryType,
} from "../../enums";

function getTextById(inputArray, id) {
    let found = inputArray.find((item) => item.value === id);
    return found ? found.text : "";
}

export function OrderFlecVocView({ order }) {
    const {
        flecVocSampleCount,
        flecVocDeliveryTypeId,
        flecVocEquipmentDeliveryAddress,
        flecVocFetchingSiteId,
    } = order;

    return (
        <Grid padded="vertically">
            <Header as="h2">Flec-laitteiston toimitus</Header>
            <Grid.Row columns="2">
                <Grid.Column>
                    <label>{fieldNames.voc.FLEC_VOC_SAMPLE_COUNT}</label>
                    <p>{flecVocSampleCount}</p>
                </Grid.Column>
                <Grid.Column>
                    <label>{fieldNames.voc.FLEC_VOC_DELIVERY_TYPE}</label>
                    <p>{getTextById(flecVocDeliveryTypeOptions, flecVocDeliveryTypeId)}</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
                {flecVocDeliveryTypeId === FlecVocDeliveryType.TAKLAB_TOIMITTAA && (
                    <Grid.Column>
                        <label>{fieldNames.voc.FLEC_VOC_EQUIPMENT_DELIVERY_ADDRESS}</label>
                        <p>{flecVocEquipmentDeliveryAddress}</p>
                    </Grid.Column>
                )}
                {flecVocDeliveryTypeId === FlecVocDeliveryType.ASIAKAS_NOUTAA && (
                    <Grid.Column>
                        <label>{fieldNames.voc.FLEC_VOC_FETCHING_SITE}</label>
                        <p>{getTextById(flecVocFetchingSites, flecVocFetchingSiteId)}</p>
                    </Grid.Column>
                )}
            </Grid.Row>
        </Grid>
    );
}
