import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { pickBy, identity } from 'lodash';
import {
  Container,
  Header,
  Grid,
  Segment,
} from "semantic-ui-react";
import { fetchCustomerOrders, clearOrders } from '../../actions/orders';
import { getVisibleOrders } from '../../reducers/orders';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { AppLoader } from "../../components/AppLoader";
import { PageContent } from "../../components/PageContent";
import { OrderFilterForm } from "../../components/OrderFilterForm";
import OrderTable from "../../components/AdminOrderTable";

/**
 * Admin orders page to display orders (by status optionally)
 */
export function AdminOrdersView() {
  const { status } = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state.orders.isFetching);
  const orders = useSelector((state) => state.orders.orders);

  const query = location.search;
  const filters = queryString.parse(query);
  const visibleOrders = getVisibleOrders(orders, filters);

  function handleUpdateFilters(filters) {
    const activeFilters = pickBy(filters, identity);
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(activeFilters)
    });
  }

  let header = '';
  switch(status) {
    case 'submitted': {
      header = 'Saapuneet tilaukset';
      break;
    }
    case 'completed': {
      header = 'Valmistuneet tutkimukset';
      break;
    }
    default: {
      header = 'Tilaukset';
    }
  }

  // Refresh the orders store on order status change
  useEffect(() => {
    if (status) {
      dispatch(clearOrders());
      dispatch(fetchCustomerOrders(status));
    }
  }, [dispatch, status]);

  return (
    <Container className="page-wrapper">
        <AppLoader
          active={ isFetching }
          page={ true }
        />
        <PageContent>
            <Grid.Row>
                <Grid.Column width={ 16 }>
                    <Header size='huge' color='yellow'>{ header }</Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={ 16 }>
                <Segment
                  key={ status }
                  className="segment-rectangle"
                >
                  <OrderFilterForm
                    defaultFilters={ filters }
                    onSubmit={ handleUpdateFilters }
                  />
                  <OrderTable
                    status={ status }
                    orders={ visibleOrders }
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
        </PageContent>
    </Container>
  );
}
