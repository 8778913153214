import React from "react";
import {
    Grid
} from "semantic-ui-react";

/**
 * A single page wrapper component
 * @param {object} props Component props
 * @param {React.Component[]} [props.children] React components to display as children
 * @param {string} [props.maxWidth=100%] Css compatible value for page wrapper maximum width.
 */
export function PageContent({
  children,
  maxWidth = '100%'
}) {
  return (
    <Grid
        stackable
        style={{ maxWidth, margin: '0 auto' }}
    >
        { children }
    </Grid>
  );
}
