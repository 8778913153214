import React from 'react';
import {
    Button,
    Header,
    Segment,
    Transition,
    Container,
    Message
} from "semantic-ui-react";
import {
    MaterialItem,
    VolatileFiberItem,
    VolatileMicrobeItem,
    BrushingMicrobeItem,
    MaterialVocItem,
    VolatileVocItem
} from './items/OrderItemViews';
import { OrderType } from '../../enums';

/**
 * Component to display the order items section in an order
 * @param {object} props Component props
 * @param {object[]} props.items The order items array
 * @param {integer} props.orderTypeId Type of order item to display (see ../../enums/OrderType)
 * @param {function} props.onDelete Delete order item handler
 * @param {function} props.onEdit Edit order item handler
 * @param {boolean} [props.readOnly] Indicates if the user can edit the order
 */
export function OrderItems({
    errors,
    items,
    orderTypeId,
    onDelete,
    onEdit,
    readOnly = false
}) {
    const itemRows = items.map((item) =>
        <Segment
            key={ item.id }
        >
            <ItemRow
                readOnly={ readOnly }
                orderTypeId={ orderTypeId }
                item={ item }
                onDeleteItem={ () => onDelete(item.id) }
                onEditItem={ () => onEdit(item.id) }
            />
        </Segment>
    );

    return (
        <>
            <Header as="h2">
                Näytteet { `(${items.length})` }
            </Header>
            { errors &&
                <Message negative>
                    { errors }
                </Message>
            }
            <Transition.Group
                animation="scale"
                duration={500}
            >
                { itemRows }
            </Transition.Group>
        </>
    )
}

/**
 * Single order item row with variations for different order types
 * @param {object} props Component props
 * @param {object} props.item Single order item data
 * @param {integer} props.orderTypeId Type of order item to display Type of order item to display (see ../../enums/OrderType)
 * @param {boolean} [props.readOnly] Indicates if the user can edit or delete the order item.
 * @param {function} [props.onDeleteItem] Callback function for delete item action.
 * @param {function} [props.onEditItem] Callback function for delete item action.
 */
function ItemRow({
    item,
    orderTypeId,
    onDeleteItem,
    onEditItem,
    readOnly = false
}) {
    let content;
    if (typeof item.date === 'string') {
        item = {
            ...item,
            date: new Date(item.date)
        };
    }
    switch(orderTypeId) {
        case OrderType.MATERIAL_FIBER:
        case OrderType.BRUSHING_FIBER:
        case OrderType.MATERIAL_MICROBE:  {
            content = <MaterialItem item={ item } />
            break;
        }
        case OrderType.VOLATILE_FIBER: {
            content = <VolatileFiberItem item={ item } />
            break;
        }
        case OrderType.VOLATILE_MICROBE: {
            content = <VolatileMicrobeItem item={ item } />
            break;
        }
        case OrderType.BRUSHING_MICROBE: {
            content = <BrushingMicrobeItem item={ item } />
            break;
        }
        case OrderType.MATERIAL_VOC: {
            content = <MaterialVocItem item={ item } />
            break;
        }
        case OrderType.VOLATILE_VOC: {
            content = <VolatileVocItem item={ item } />
            break;
        }                
        default: {
            content = null;
        }
    }
    return (
        <>
            { content }
            { !readOnly &&
                <Container textAlign="right">
                    <Button
                        type="button"
                        className="edit-item-button"
                        icon="edit"
                        onClick={ onEditItem }
                    >
                    </Button>
                    <Button
                        type="button"
                        className="delete-item-button"
                        negative
                        icon="trash alternate"
                        onClick={ onDeleteItem }
                    >
                    </Button>
                </Container>
            }
        </>
    )
}