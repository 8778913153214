const USER_ROUTE = '/user';
const ADMIN_ROUTE = '/admin';
const SAMPLER_ROUTE = '/sampler';

export const routes = {
    HOME: '/',
    USER_LOGIN: '/login',
    ADMIN_LOGIN: `${ADMIN_ROUTE}/login`,
    USER_SIGNUP: '/signup',
    HELP: '/help',
    FORGOT_PASSWORD: '/forgot-password',
    PASSWORD_RESET: '/reset-password',
    CREATE_ORDER_PUBLIC: `/order/create`,
    SAMPLER_SIGNUP: '/sampler-signup',
    // Customer user
    USER: USER_ROUTE,
    USER_DASHBOARD: `${USER_ROUTE}/`,
    USER_HELP: `${USER_ROUTE}/help`,
    PROFILE: `${USER_ROUTE}/profile`,
    ARCHIVE: `${USER_ROUTE}/archive`,
    COMPANY_ARCHIVE: `${USER_ROUTE}/company_archive`,
    EDIT_ORDER: `${USER_ROUTE}/order/edit`,
    VIEW_ORDER: `${USER_ROUTE}/order`,
    CREATE_ORDER: `${USER_ROUTE}/order/create`,
    VIEW_SAMPLER: `${USER_ROUTE}/sampler`,
    // Sampler user
    SAMPLER: SAMPLER_ROUTE,
    SAMPLER_DASHBOARD: `${SAMPLER_ROUTE}/`,
    SAMPLER_HELP: `${SAMPLER_ROUTE}/help`,
    SAMPLER_ARCHIVE: `${SAMPLER_ROUTE}/archive`,
    SAMPLER_EDIT_ORDER: `${SAMPLER_ROUTE}/order/edit`,
    SAMPLER_VIEW_ORDER: `${SAMPLER_ROUTE}/order`,
    SAMPLER_CREATE_ORDER: `${SAMPLER_ROUTE}/order/create`,
    SAMPLER_PROFILE: `${SAMPLER_ROUTE}/profile`,
    // Admin user
    ADMIN: ADMIN_ROUTE,
    ADMIN_DASHBOARD: `${ADMIN_ROUTE}/`,
    ADMIN_USER_VIEW: `${ADMIN_ROUTE}/users`,
    ADMIN_ORDERS: `${ADMIN_ROUTE}/orders`,
    ADMIN_SUBMITTED: `${ADMIN_ROUTE}/orders/submitted`,
    ADMIN_COMPLETED: `${ADMIN_ROUTE}/orders/completed`,
    ADMIN_VIEW_ORDER: `${ADMIN_ROUTE}/order`,
    ADMIN_VIEW_USER: `${ADMIN_ROUTE}/user`,
    ADMIN_VIEW_SAMPLER: `${ADMIN_ROUTE}/sampler`,
    ADMIN_PROFILE: `${ADMIN_ROUTE}/profile`,
};
