import {
    FETCH_USERS,
    FETCH_USERS_FAILURE,
    FETCH_USERS_SUCCESS,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    FETCH_SINGLE_USER,
    FETCH_SINGLE_USER_SUCCESS,
    FETCH_SINGLE_USER_FAILURE,
    CLEAR_USER,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE
} from "./actions";
import { get, del, put } from "../api";

export function clearUser() {
    return {
        type: CLEAR_USER
    }
}

export function fetchUser(id) {
    return async dispatch => {
        dispatch(onFetchUser());
        try {
            const response = await get(`/users/${id}`, true);
            dispatch(onFetchUserSuccess(response));
        } catch(error) {
            dispatch(onFetchUserFailure(error));
        }
    }
}

function onFetchUser() {
    console.log('[onFetchUser');
    return {
        type: FETCH_SINGLE_USER
    };
}

function onFetchUserSuccess(response) {
    console.log('[onFetchUserSuccess]', response);
    const { user } = response;
    return {
        type: FETCH_SINGLE_USER_SUCCESS,
        user
    };
}

function onFetchUserFailure(error) {
    console.log('[onFetchUserFailure]');
    return {
        type: FETCH_SINGLE_USER_FAILURE,
        error
    };
}

export function fetchUsers() {
    return async dispatch => {
        dispatch(onFetchUsers());
        try {
            const response = await get('/users', true);
            dispatch(onFetchUsersSuccess(response));
        } catch(error) {
            dispatch(onFetchUsersFailure(error));
            throw new Error();
        }
    }
}

function onFetchUsers() {
    console.log('[onFetchUsers');
    return {
        type: FETCH_USERS
    };
}

function onFetchUsersSuccess(response) {
    console.log('[onFetchUsersSuccess]', response);
    const { users } = response;
    return {
        type: FETCH_USERS_SUCCESS,
        users
    };
}

function onFetchUsersFailure(error) {
    console.log('[onFetchUsersFailure]');
    return {
        type: FETCH_USERS_FAILURE,
        error
    };
}

export function deleteUser(userId) {
    return async dispatch => {
        dispatch(onDeleteUser());
        try {
            await del(`/users/${userId}`, true);
            dispatch(onDeleteUserSuccess(userId));
        } catch(error) {
            dispatch(onDeleteUserFailure(error));
            throw new Error();
        }
    }
}

function onDeleteUser() {
    console.log('[onDeleteUser');
    return {
        type: DELETE_USER
    };
}

function onDeleteUserSuccess(id) {
    console.log('[onDeleteUserSuccess]');
    return {
        type: DELETE_USER_SUCCESS,
        id
    };
}

function onDeleteUserFailure(error) {
    console.log('[onDeleteUserFailure]');
    return {
        type: DELETE_USER_FAILURE,
        error
    };
}

export function updateUser(id, update) {
    return async dispatch => {
        dispatch(onUpdateUser());
        try {
            await put(`/users/${id}`, update);
            dispatch(onUpdateUserSuccess(update));
        } catch(error) {
            dispatch(onUpdateUserFailure(error));
            throw new Error();
        }
    }
}

function onUpdateUser() {
    console.log('[onUpdateUser]');
    return {
        type: UPDATE_USER
    };
}

function onUpdateUserSuccess(update) {
    console.log('[onUpdateUserSuccess]');
    return {
        type: UPDATE_USER_SUCCESS,
        update
    };
}

function onUpdateUserFailure(error) {
    console.log('[onUpdateUserFailure]');
    return {
        type: UPDATE_USER_FAILURE,
        error
    };
}