import React from 'react';
import { Form } from 'semantic-ui-react';

/**
 * User profile section to display and edit company profile information
 * @param {object} props Component props
 * @param {object} props.company The company to display
 */
export function CompanyProfile({
    company
}) {

    if(!company) {
        return null;
    }

    return (
        <Form>
            <Form.Group widths='equal'>
            <Form.Input
                fluid
                readOnly={ true }
                name="companyName"
                value={ company.companyName }
                label='Yrityksen nimi'
                placeholder='Yrityksen nimi'
                maxLength="100"
            />
            <Form.Input
                fluid
                readOnly={ true }
                name="businessId"
                value={ company.businessId }
                label='Yrityksen Y-tunnus'
                placeholder='Yrityksen Y-tunnus'
                maxLength="15"
            />
            </Form.Group>
            <Form.Group widths='equal'>
            <Form.TextArea
                rows="5"
                readOnly={ true }
                name="billingAddress"
                value={ company.billingAddress }
                label='Laskutusosoite'
                placeholder='Laskutusosoite'
                maxLength="255"
            />
            </Form.Group>
        </Form>
    )
}
