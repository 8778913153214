import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Container,
  Grid,
  Header,
  Segment,
  Divider
} from "semantic-ui-react";
import { deleteProfile, updateProfile, logoutClient } from '../actions/auth';
import { PageContent } from "../components/PageContent";
import { useState } from "react";
import { routes } from '../router/routes';
import { InfoModal } from "../components/InfoModal";
import { AppLoader } from '../components/AppLoader';
import { UpdatePasswordForm } from "../components/UpdatePasswordForm";
import { UserProfileForm } from "../components/UserProfileForm";
import { DeleteAccountSection } from "../components/DeleteAccountSection";
import { useHistory } from "react-router-dom";
import { LogoutSection } from "../components/LogoutSection";

/**
 * User profile page component.
 */
export function AdminProfile() {

  const isFetching = useSelector((state) => state.auth.isFetching);
  const user = useSelector((state) => state.auth.user);

  return (
    <Container className="page-wrapper">
      <AppLoader
        active={ isFetching }
        page={ true }
      />
      <PageContent>
          <Grid.Row>
              <Grid.Column width={ 9 }>
                <Header
                  size='huge'
                  color='yellow'>
                    Käyttäjätilin tiedot
                </Header>
              </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={ 16 }>
              <Segment
                className="segment-rectangle"
                padded="very"
              >
              <h2>Käyttäjä</h2>
              <p>{ user.email }</p>
              <Divider />
              <UpdatePasswordForm />
              </Segment>
            </Grid.Column>
          </Grid.Row>
      </PageContent>
    </Container>
  );
}
