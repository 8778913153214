import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
  Container,
  Grid,
  Header
} from "semantic-ui-react";
import { signup } from '../actions/auth';
import { routes } from '../router/routes';
import SignupForm from '../components/SignupForm';
import { AppLoader } from '../components/AppLoader';

/**
 * Customer signup page
 */
export default function Signup() {
  const history = useHistory();
  const isFetching = useSelector((state) => state.auth.isFetching)
  const dispatch = useDispatch();

  async function handleSignup(credentials) {
    try {
      await dispatch(signup(credentials));
      history.replace(routes.USER_DASHBOARD);
    } catch(error) {
      // The error's been handled, this is just to let login page know about it
    }
  }

  return (
    <Container className="page-wrapper">
      <AppLoader
        active={ isFetching }
        page={ true }
      />
      <Grid textAlign="center">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header size="huge" color="yellow" textAlign="center">
            Luo yritystili TAKLAB-järjestelmään
          </Header>
          <SignupForm onSignup={ handleSignup } />
        </Grid.Column>
      </Grid>
    </Container>
  );
}
