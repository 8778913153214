import React from "react";
import { Grid, Header } from "semantic-ui-react";
import { fieldNames } from "../../enums";

export function OrderZeroPipeView({ order }) {
    const { zeroPipeNumber, zeroPipeOtherNotes } = order;

    return (
        <Grid padded="vertically">
            <Header as="h2">Nollaputken tiedot</Header>
            <Grid.Row columns="2">
                <Grid.Column>
                    <label>{fieldNames.voc.ZERO_PIPE_NUMBER}</label>
                    <p>{zeroPipeNumber}</p>
                </Grid.Column>
                <Grid.Column>
                    <label>{fieldNames.voc.OTHER_NOTES}</label>
                    <p>{zeroPipeOtherNotes ? zeroPipeOtherNotes : "-"}</p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
