import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react';


export function SortingDropdowns({
    sortByOptions,
    sortBy,
    directionValue,
    onChangeSortBy,
    onChangeDirection
}) {
    return (
        <Form style={{ marginBottom: ".5rem" }}>
            <Form.Field>
                <label>Järjestä</label>
                <Dropdown
                    style={{ marginBottom: '.5rem' }}
                    fluid
                    selection
                    basic
                    name="sortByColumn"
                    options={ sortByOptions }
                    value={ sortBy }
                    onChange={ (event, { value }) => {
                        onChangeSortBy(value);
                    }}
                />
                <Dropdown
                    fluid
                    selection
                    basic
                    name="direction"
                    options={ [
                        {
                            key: 'ascending',
                            text: 'Nouseva',
                            value: 'ascending'
                        },
                        {
                            key: 'descending',
                            text: 'Laskeva',
                            value: 'descending'
                        }
                    ] }
                    value={ directionValue }
                    onChange={ (event, { value }) => {
                        onChangeDirection(value);
                    }}
                />
            </Form.Field>
        </Form>
    )
}