import { METADATA_REQUEST, METADATA_FAILURE, METADATA_SUCCESS } from '../actions/actions'

export const metadata = (
    state = {
        isFetching: true
    },
    action
) => {
    switch (action.type) {
        case METADATA_REQUEST: {
            return {
                ...state,
                isFetching: true
            }
        }
        case METADATA_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                data,
                isFetching: false
            }
        }
        case METADATA_FAILURE: {
            return {
                ...state,
                isFetching: false
            }
        }
        default: {
            return state;
        }
    }
}