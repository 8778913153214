import React, { useState } from 'react';
import {
    Button,
    Form,
    Segment
} from 'semantic-ui-react';
import { isEmpty, omit } from 'lodash';
import { validateSamplerSignup } from '../validation/validate';

/**
 * Form component to sign up a new sampler
 * @param {object} props Component props
 * @param {object} props.onSignup Handler for the signup event
 */

export default function SignupForm({
    onSignup
}) {
    const [userName, setUserName] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [errors, setErrors] = useState({});

    function handleSubmit() {
        const userData = {
            userName,
            phone,
            password,
            repeatPassword
        };
        const isValid = validate(userData);
        if (isValid) {
            onSignup(omit(userData, 'repeatPassword'));
        } 
    }

    function handleBlur() {
        const userData = {
            userName,
            phone,
            password,
            repeatPassword
        };
        if (!isEmpty(errors)) {
            validate(userData);
        }
    }

    function validate(user) {
        const errors = validateSamplerSignup(user);
        if (errors) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
            return true;
        }
    }

    return (
        <Form
            size='large'
        >
            <Segment textAlign="left">
                <Form.Input
                    name="userName"
                    required
                    label="Nimi"
                    error={ errors.userName }
                    onBlur={ handleBlur }
                    value={ userName }
                    fluid
                    placeholder='Nimi'
                    onChange={ (event) => setUserName(event.target.value) }
                    maxLength="100"
                />
                <Form.Input
                    name="phone"
                    required
                    label="Puhelinnumero"
                    error={ errors.phone }
                    onBlur={ handleBlur }
                    value={ phone }
                    fluid
                    placeholder='Puhelinnumero'
                    onChange={ (event) => setPhone(event.target.value) }
                    maxLength="50"
                />
                <Form.Input
                    name="password"
                    required
                    error={ errors.password }
                    onBlur={ handleBlur }
                    value={ password }
                    label="Salasana"
                    fluid
                    placeholder='Salasana'
                    type='password'
                    onChange={  (event) => setPassword(event.target.value) }
                    maxLength="100"
                />
                <Form.Input
                    name="repeatPassword"
                    required
                    error={ errors.repeatPassword }
                    onBlur={ handleBlur }
                    value={ repeatPassword }
                    fluid
                    label="Vahvista salasana"
                    placeholder='Vahvista salasana'
                    type='password'
                    onChange={  (event) => setRepeatPassword(event.target.value) }
                    maxLength="100"
                />
                <Button
                    fluid
                    type="button"
                    onClick={ handleSubmit }
                >
                    Rekisteröidy
                </Button>
            </Segment>
          </Form>
        )
}
