import React from "react";
import {
  Container,
  Header,
  Accordion,
  Icon,
  Segment,
  Grid
} from "semantic-ui-react";
import { useState } from "react";

/**
 * Help page
 */
export function Help() {
  const [activeIndex, setActiveIndex] = useState(-1);

  function handleClick(e, titleProps) {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex);
  }

  return (
    <Container className="page-wrapper">
      <Header size='huge' color='yellow'>Ohjeet</Header>
      <Accordion>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
        >
          <Header size="large" color="yellow">
            <Icon name='dropdown' />
            TAKLAB-tilausjärjestelmä
          </Header>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <Segment className="segment-rectangle">
            <p>Voit luoda TAKLAB-yritystilin rekisteröitymällä TAKLAB-järjestelmään verkkoselaimessa. Saat vahvistuksen rekisteröitymisestä sähköpostiisi. Yhdellä Y-tunnuksella voidaan luoda yksi TAKLAB-yritystili. Yritystililtä lähetetyt tilaukset ja niihin saapuneet tutkimustulokset tallentuvat TAKLAB-järjestelmään ja ne lähetetään sinulle sähköpostilla. Voit tilata tutkimuksen myös kirjautumatta järjestelmään. Tällöin saat tiedot tilauksestasi ja valmistuneet tutkimustulokset sähköpostilla.</p>
            <p>Yritystilin haltijana voit kutsua TAKLAB-järjestelmään näytteenottajia lähettämällä heille kutsulinkin TAKLAB-järjestelmästä. Näytteenottajat pystyvät lähettämään tilauksia ja vastaanottamaan tutkimustuloksia kuten yritystilin haltija. Yritystilin haltija hallinnoi näytteenottajia ja pystyy tarkastelemaan näytteenottajien lähettämiä tilauksia ja tutkimustuloksia.</p>
            <p>Kun olet luonut TAKLAB-yritystilin verkkoselaimessa, voit kirjautua samoilla tunnuksilla myös TAKLAB-mobiilisovellukseen. Mobiilisovelluksessa voit tilata uusia tutkimuksia ja tarkastella tekemiäsi tilauksia ja valmistuneita tutkimuksia aivan kuten verkkoselaimessa. Mobiilisovellus mahdollistaa lisäksi tilausluonnosten tallentamisen laitteen tallennustilaan. Voit siis täyttää ja tallentaa tilauslomakkeen, vaikka laitteesi ei olisi yhteydessä verkkoon.</p>
            <p>Voit tarkastella ja muokata TAKLAB-yritystilisi tietoja Omat tiedot -sivulla. Omat tiedot -sivulla voit myös vaihtaa salasanasi, kirjautua ulos kaikilta laitteilta (myös mobiilisovelluksesta) sekä tarvittaessa poistaa yritystilisi. Huomaa, että jos poistat TAKLAB-yritystilin, et voi enää tarkastella tekemiäsi tilauksia tai vastaanottamiasi tutkimustuloksia TAKLAB-tilausjärjestelmässä. Yritystilin poistaminen poistaa myös kaikki yritystilin alaiset näytteenottajat. Yritystilin haltija voi poistaa yksittäisiä näytteenottajia, minkä jälkeen yritystililtä ei voida enää tarkastella poistetun näytteenottajan lähettämiä tilauksia.</p>
          </Segment>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={handleClick}
        >
          <Header size="large" color="yellow">
            <Icon name='dropdown' />
            Uuden tilauksen lähettäminen
          </Header>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <Segment className="segment-rectangle">
            <p>Voit tilata uusia tutkimuksia käyttämällä TAKLAB-järjestelmän sähköisiä lomakkeita. Siirry Tilaa tutkimus -sivulle, valitse tilattavan tutkimuksen tyyppi ja täytä sitten lomakkeeseen tarvittavat tiedot. Voit lisätä, poistaa ja muokata näyterivejä vapaasti ennen lomakkeen lähettämistä. Jokaisella näyterivillä täytyy olla yksilöllinen näytetunnus (esim. 1, 2, 3...). <strong>Käytä samaa näytetunnusta pakatessasi näytteet toimitusta varten. Kirjoita lähetykseesi lisäksi kohteen osoite tai projektinumero.</strong></p>
            <p>Lähetä valmis tilauslomake painamalla <strong>Lähetä tilaus</strong>, minkä jälkeen sinun tulee valita näytteiden toimitustapa. Mikrobinäytteiden toimittamisesta vastaanottolaatikoihin pyydämme sopimaan erikseen laboratorion kanssa. Mikrobiologian laboratoriomme puhelinnumero on 050 563 6543.</p>
            <p>TAKLAB-yritystilille kirjautuneena voit tallentaa keskeneräisen lomakkeen TAKLAB-järjestelmään painamalla <strong>Tallenna</strong>. Tilausluonnos tallentuu TAKLAB-järjestelmään, ja voit muokata tilauksen tietoja ja näyterivejä myöhemmin ennen tilauksen lähettämistä.</p>
          </Segment>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 3}
          index={3}
          onClick={handleClick}
        >
          <Header size="large" color="yellow">
            <Icon name='dropdown' />
            Tulosten valmistuminen
          </Header>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 3}>
          <Segment className="segment-rectangle">
            <p>Noudamme toimittamasi näytteet Matkahuollosta heti näytteiden saavuttua. Vastaanottolaatikoiden tyhjennysten ajankohdat näet verkkosivuiltamme. Voit toimittaa näytteet myös postitse tai henkilökohtaisesti suoraan laboratorioihimme.</p>
            <p>Asbesti-ilmanäytteet analysoidaan ja raportoidaan välittömästi näytteiden saavuttua laboratorioon. Asbestimateriaalinäytteet tutkitaan ja tulokset raportoidaan saman arkipäivän aikana, kun näytteet ovat saapuneet laboratorioon. Lyijy- ja raskasmetallinäytteet raportoidaan seuraavana arkipäivänä näytteiden vastaanottamisesta. PCB- ja PAH-analyysien tulokset raportoidaan yleensä 1–3 työpäivän kuluessa.</p>
            <p>Jos näytteellä on kiire, pyydämme ilmoittamaan asiasta näytteiden toimituksen yhteydessä, jolloin näyte pääsee välittömästi analysoitavaksi. Kiireestä emme veloita lisähintaa.</p>
            <p><strong>TAKLAB-tilausjärjestelmässä lähettämiesi tilausten tutkimustulokset lähetetään sinulle sähköpostilla</strong>. Jos sinulla on TAKLAB-yritystili tai olet rekisteröitynyt näytteenottajaksi, voit tarkastella valmistuneita tutkimuksia ja ladata tutkimustulokset TAKLAB-verkkosovelluksessa sekä TAKLAB-mobiilisovelluksissa.</p>
          </Segment>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 2}
          index={2}
          onClick={handleClick}
        >
          <Header size="large" color="yellow">
            <Icon name='dropdown' />
            Tilausarkisto
          </Header>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 2}>
          <Segment className="segment-rectangle">
            <p>TAKLAB-tilausarkistossa voit selata tilaamiasi tutkimuksia ja tallentamiasi luonnoksia. Voit hakea tutkimuksia tutkimuksen tyypin, päivämäärän ja sanahaun perusteella. TAKLAB-yritystilin haltijana voit tarkastella myös järjestelmään kutsumiesi näytteenottajien lähettämiä tilauksia.</p>
            <p>Valitsemalla yksittäisen tilauksen pääset tarkastelemaan tilauksen tietoja ja voit ladata tilauksen tulostettavaksi PDF-tiedostona.</p>
            <p>Valitsemalla valmistuneen tutkimuksen pääset tarkastelemaan tilauksen tietoja ja voit ladata tilauksen tulostettavaksi PDF-tiedostona. Mikä tärkeintä, voit ladata valmiit tutkimustulokset sekä tilausarkiston listauksesta että tilauksen omalta sivulta.</p>
            <p>Valitsemalla aiemmin tallentamasi lomakkeen pääset muokkaamaan luonnosta, minkä jälkeen voit lähettää tilauksen tai tallentaa luonnokseen tekemäsi muutokset. Tilausarkistossa voit myös poistaa aiemmin tallentamiasi lomakeluonnoksia.</p>
          </Segment>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 4}
          index={4}
          onClick={handleClick}
        >
          <Header size="large" color="yellow">
            <Icon name='dropdown' />
            Yhteystiedot
          </Header>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 4}>
          <Segment className="segment-rectangle">
            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <h3>Tampereen laboratorio</h3>
                  <p>
                    <address>
                      TAKLAB Tampere<br />
                      Kuokkamaantie 2<br />
                      33800 Tampere
                    </address>
                  </p>
                  <span><strong>Aukioloajat:</strong></span>
                  <p>ma - pe 8:00 - 17:00</p>
                  <p>
                    Asbesti ja kemia 050 320 4458<br />
                    Mikrobiologia 050 563 6543<br />
                    tampere@taklab.fi
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <h3>Jyväskylän laboratorio</h3>
                  <p>
                    <address>
                      TAKLAB Jyväskylä<br />
                      Vasarakatu 1<br />
                      40320 Jyväskylä
                    </address>
                  </p>
                  <span><strong>Aukioloajat:</strong></span>
                  <p>ma - pe 8:00 - 16:00</p>
                  <p>
                    Asbesti ja kemia 050 478 1628<br />
                    Mikrobiologia 050 563 6543
                  </p>
                  <p>
                    jyvaskyla@taklab.fi
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <p></p>
                  <h3>Helsingin Konalan laboratorio</h3>
                  <p>
                    <address>
                      TAKLAB Helsinki / Konala<br />
                      Ristipellontie 25<br />
                      00390 Helsinki
                    </address>
                  </p>
                  <span><strong>Aukioloajat:</strong></span>
                  <p>ma - pe 8:00 - 17:00</p>
                  <p>helsinki@taklab.fi</p>
                </Grid.Column>
                <Grid.Column>
                  <p></p>
                  <h3>Helsingin Roihupellon laboratorio</h3>
                  <p>
                    <address>
                      TAKLAB Helsinki / Roihupelto<br />
                      Laippatie 1<br />
                      00880 Helsinki
                    </address>
                  </p>
                  <span><strong>Aukioloajat:</strong></span>
                  <p>ma - pe 8:00 - 17:00</p>
                  <p>helsinki@taklab.fi</p>
                </Grid.Column>                
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <h3>Hintatiedustelut ja tarjouspyynnöt</h3>
                  <p>
                    <strong>Heli Knuutila</strong><br />
                    050 349 7041<br />
                    heli.knuutila@taklab.fi
                  </p>
                  <p>
                    <strong>Heikki Knuutila</strong><br />
                    045 144 6193<br />
                    heikki.knuutila@taklab.fi
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Accordion.Content>
      </Accordion>
    </Container>
  );
}
