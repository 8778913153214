import { cloneDeep, omit, partialRight } from 'lodash';
import FileSaver from 'file-saver';
import {
    INSERT_ORDER,
    INSERT_ORDER_SUCCESS,
    INSERT_ORDER_FAILURE,
    DELETE_ORDER,
    DELETE_ORDER_SUCCESS,
    DELETE_ORDER_FAILURE,
    ORDERS_REQUEST,
    COMPANY_ORDERS_REQUEST_SUCCESS,
    ORDERS_REQUEST_SUCCESS,
    ORDERS_REQUEST_FAILURE,
    SINGLE_ORDER_REQUEST,
    SINGLE_ORDER_REQUEST_SUCCESS,
    SINGLE_ORDER_REQUEST_FAILURE,
    FETCH_RESULT,
    FETCH_RESULT_SUCCESS,
    FETCH_RESULT_FAILURE,
    UPLOAD_RESULT,
    UPLOAD_RESULT_SUCCESS,
    UPLOAD_RESULT_FAILURE,
    PRINT_ORDER,
    PRINT_ORDER_SUCCESS,
    PRINT_ORDER_FAILURE,
    ORDER_REQUEST_END,
    ORDER_REQUEST,
    CLEAR_ORDERS
} from "./actions";
import { post, get, del, put, postFile } from "../api";
import { editOrder } from './order';


export function clearOrders() {
    console.log('[clearOrders]');
    return {
        type: CLEAR_ORDERS
    }
}

// Get all company orders by the logged in CUSTOMER user
export function fetchCompanyOrders(status) {
    return async dispatch => {
        let uri = `/company/orders`;
        /*
        TODO: status is not supported at backend, add it!
        if (status) {
            uri = `${uri}/${status}`;
        }
        */
        dispatch(onFetchUserOrders());
        try {
            const result = await get(uri, true);
            dispatch(onFetchCompanyOrdersSuccess(result));
        } catch(error) {
            dispatch(onFetchUserOrdersFailure(error));
        }
    }
}

// Get all orders by the logged in user
export function fetchOrders(status) {
    return async dispatch => {
        let uri = `/user/orders`;
        if (status) {
            uri = `${uri}/${status}`;
        }
        dispatch(onFetchUserOrders());
        try {
            const result = await get(uri, true);
            dispatch(onFetchUserOrdersSuccess(result));
        } catch(error) {
            dispatch(onFetchUserOrdersFailure(error));
        }
    }
}

// Get orders by all customers
export function fetchCustomerOrders(status) {
    return async dispatch => {
        let uri = `/orders`;
        if (status) {
            uri = `${uri}/${status}`;
        }
        dispatch(onFetchUserOrders());
        try {
            const result = await get(uri, true);
            dispatch(onFetchUserOrdersSuccess(result));
        } catch(error) {
            dispatch(onFetchUserOrdersFailure(error));
        }
    }
}

function onFetchUserOrders() {
    console.log('[onFetchUserOrders]');
    return {
        type: ORDERS_REQUEST
    }
}

function onFetchCompanyOrdersSuccess(result) {
    console.log('[onFetchCompanyOrdersSuccess]', result);
    const { companyOrders } = result;
    return {
        type: COMPANY_ORDERS_REQUEST_SUCCESS,
        companyOrders
    }
}

function onFetchUserOrdersSuccess(result) {
    console.log('[onFetchUserOrdersSuccess]', result);
    const { orders } = result;
    return {
        type: ORDERS_REQUEST_SUCCESS,
        orders
    }
}

function onFetchUserOrdersFailure(error) {
    console.log('[onFetchUserOrdersFailure]', error);
    return {
        type: ORDERS_REQUEST_FAILURE,
        error
    }
}

export const fetchDraft = partialRight(fetchOrder, true);

export function fetchOrder(id) {
    return async (dispatch, getState) => {
        const { isAdmin } = getState().auth;
        dispatch(onFetchOrder());
        try {
            let uri = isAdmin ? '/orders' : '/user/orders';
            const result = await get(`${uri}/${id}`, true);
            dispatch(onFetchOrderSuccess());
            return result.order;
        } catch(error) {
            dispatch(onFetchOrderFailure(error));
            throw new Error();
        }
    }
}

function onFetchOrder() {
    console.log('[onFetchOrder]');
    return {
        type: SINGLE_ORDER_REQUEST
    }
}

function onFetchOrderSuccess() {
    console.log('[onFetchOrderSuccess]');
    return {
        type: SINGLE_ORDER_REQUEST_SUCCESS
    }
}

function onFetchOrderFailure(error) {
    console.log('[onFetchOrderFailure]', error);
    return {
        type: SINGLE_ORDER_REQUEST_FAILURE,
        error
    }
}

export function downloadResult(id, building_address) {
    return async (dispatch, getState) => {
        const { isAdmin } = getState().auth;
        dispatch(onDownloadResult());
        try {
            const uri = isAdmin ? '/orders' : '/user/orders';
            const response = await get(`${ uri }/${ id }/result`, true);

            if(building_address) {
                building_address = building_address.slice(0,100);
                building_address = building_address.replace(/[\s\\\/\.\0\*:"<>|?]/g,"_");
            } else {
                building_address = "";
            }

            FileSaver.saveAs(response, `taklab_tilaus_${id}_${building_address}.pdf`);
            dispatch(onDownloadResultSuccess());
        } catch(error) {
            dispatch(onDownloadResultFailure(error));
        }
    }
}

function onDownloadResult() {
    console.log('[onDownloadResult]');
    return {
        type: FETCH_RESULT
    }
}

function onDownloadResultSuccess() {
    console.log('[onDownloadResultSuccess]');
    return {
        type: FETCH_RESULT_SUCCESS
    }
}

function onDownloadResultFailure(error) {
    console.log('[onDownloadResultFailure]', error);
    return {
        type: FETCH_RESULT_FAILURE,
        error
    }
}

export function printOrder(id) {
    return async (dispatch, getState) => {
        const { isAdmin } = getState().auth;
        dispatch(onPrintOrder());
        try {
            const uri = isAdmin ? '/orders' : '/user/orders';
            const response = await get(`${uri}/${id}/print`, true);
            FileSaver.saveAs(response, `taklab_tilaus_${id}.pdf`);
            dispatch(onPrintOrderSuccess());
        } catch(error) {
            dispatch(onPrintOrderFailure(error));
        }
    }
}

function onPrintOrder() {
    console.log('[onPrintOrder]');
    return {
        type: PRINT_ORDER
    }
}

function onPrintOrderSuccess() {
    console.log('[onPrintOrderSuccess]');
    return {
        type: PRINT_ORDER_SUCCESS
    }
}

function onPrintOrderFailure(error) {
    console.log('[onPrintOrderFailure]', error);
    return {
        type: PRINT_ORDER_FAILURE,
        error
    }
}

export function uploadResult(id, formData) {
    return async dispatch => {
        dispatch(onUploadResult());
        try {
            await postFile(`/orders/${id}/result`, formData, true);
            dispatch(onUploadResultSuccess());
        } catch(error) {
            dispatch(onUploadResultFailure(error));
            throw new Error();
        }
    }
}

function onUploadResult() {
    console.log('[onUploadResult]');
    return {
        type: UPLOAD_RESULT
    }
}

function onUploadResultSuccess() {
    console.log('[onUploadResultSuccess]');
    return {
        type: UPLOAD_RESULT_SUCCESS
    }
}

function onUploadResultFailure(error) {
    console.log('[onUploadResultFailure]', error);
    return {
        type: UPLOAD_RESULT_FAILURE,
        error
    }
}

// POST, PUT
export function insertOrder(data, status) {
    return async (dispatch, getState) => {
        let result;
        const { isAuthenticated } = getState().auth
        const order = cloneDeep(data);
        order.status = status;
        order.items = removeIds(order.items);
        dispatch(onInsertOrder());
        try {
            if (order.id) {
                result = await put(`/user/orders/${order.id}`, order);
            } else {
                const uri = isAuthenticated ? '/user/orders' : '/orders';
                result = await post(uri, order, isAuthenticated);
            }
            if (status === 'DRAFT' && result.id) {
                const resultOrder = await get(`/user/orders/${ result.id }`, true);
                dispatch(editOrder(resultOrder.order));
            }
            dispatch(onInsertOrderSuccess());
        } catch(error) {
            dispatch(onInsertOrderFailure(error));
            throw new Error();
        }

    }
}

export function onInsertOrder() {
    return {
        type: INSERT_ORDER
    }
}

export function onInsertOrderSuccess() {
    console.log('[onInsertOrderSuccess]');
    return {
        type: INSERT_ORDER_SUCCESS
    }
}

export function onInsertOrderFailure(error) {
    console.log('[onInsertOrderFailure]');
    return {
        type: INSERT_ORDER_FAILURE,
        error
    }
}

// DELETE
export function deleteOrder(id) {
    return async (dispatch, getState) => {
        const { isAdmin } = getState().auth;
        try {
            const uri = isAdmin ? `/orders/${id}` : `/user/orders/${id}`;
            dispatch(onDeleteOrder());
            await del(uri);
            dispatch(onDeleteOrderSuccess(id));
        } catch(error) {
            dispatch(onDeleteOrderFailure(error));
            throw new Error();
        }
    }
}

function onDeleteOrder() {
    return {
        type: DELETE_ORDER
    }
}

function onDeleteOrderSuccess(id) {
    return {
        type: DELETE_ORDER_SUCCESS,
        id
    }
}

function onDeleteOrderFailure(error) {
    return {
        type: DELETE_ORDER_FAILURE,
        error
    }
}

export function checkBusinessId(businessId) {
    return async dispatch => {
        try {
            dispatch(onOrderRequest());
            await get(`/business-id/${businessId}`);
            dispatch(onOrderRequestSuccess());
        } catch(error) {
            dispatch(onOrderRequestSuccess());
            throw new Error();
        }
    }
}

function onOrderRequest() {
    return {
        type: ORDER_REQUEST
    }
}

function onOrderRequestSuccess() {
    return {
        type: ORDER_REQUEST_END
    }
}

// utils
function removeIds(items) {
    const withoutIds = items.map(item => {
        return omit(item, 'id');
    });
    return withoutIds;
}
