import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import {
  Dropdown,
  Form,
  Icon,
  Segment,
  Header
} from "semantic-ui-react";
import { DatePicker } from './DatePicker';

/**
 * Form component to produce order search query parameters
 * @param {object} props Component props
 * @param {object} props.defaultFilters Default values for filter form fields
 * @param {function} props.onSubmit Form submit handler
 */
export function OrderFilterForm({
  defaultFilters,
  onSubmit
}) {

  const metadata = useSelector((state) => state.metadata.data);

  const initialState =  {
    startDate: undefined,
    endDate: undefined,
    string: '',
    orderTypeId: ''
  };

  const [filters, setFilters] = useState({
    ...initialState,
    ...defaultFilters,
    page: 1
  });

  const orderTypeOptions = metadata ?
    [{
      key: 0,
      text: 'Ei rajausta',
      value: ''
    }].concat(metadata.orderType.map((type) => ({
      key: type.id,
      text: type.description,
      value: type.id
    }))) :
    [];

  function handleUpdate(event, data) {
    const name = data.name;
    if (name === 'startDate') {
      console.log(data.value);
    }
    setFilters({
      ...filters,
      [name]: data.value
    });
  }

  return (
    <Segment
      vertical
    >
      <Form
        onSubmit={ (e) => e.preventDefault() }
      >
        <Header
          as="h4"
          style={{ marginTop: 0 }}
        >Suodatus</Header>
          <Form.Group widths="equal">
            <Form.Field width={7}>
              <label>Tutkimus</label>
              <Dropdown
                  fluid
                  selection
                  basic
                  name="orderTypeId"
                  placeholder="Tutkimus"
                  options={ orderTypeOptions }
                  value={ parseInt(filters.orderTypeId) || '' }
                  className="order-form-selector"
                  onChange={ handleUpdate }
              />
            </Form.Field>
            <Form.Input
              label="Sanahaku"
              placeholder="Sanahaku"
              width={7}
              name="string"
              value={ filters.string || '' }
              onChange={ handleUpdate }
              maxLength="100"
              // icon={ filters.string ?
              //   <Icon
              //     name="close"
              //     circular
              //     inverted
              //     link
              //     onClick={
              //       (event) => handleUpdate(event, { name: 'string', value: '' })
              //     }
              //   /> : false
              // }
            />
          </Form.Group>
          <DatePicker
            placeholder="Alkupvm"
            maxDate={ new Date() }
            size="small"
            width={4}
            value={ typeof filters.startDate === 'string' ? new Date(filters.startDate) : filters.startDate }
            name="startDate"
            onChange={ handleUpdate }
          />
          <Icon
            style={{ margin: '0 .5rem' }}
            name="minus"
            size="small"
          />
          <DatePicker
            placeholder="Loppupvm"
            maxDate={ new Date() }
            size="small"
            value={ typeof filters.endDate === 'string' ? new Date(filters.endDate) : filters.endDate }
            name="endDate"
            onChange={ handleUpdate }
          />
          <Form.Group>
            <Form.Button
              type="button"
              size="mini"
              primary
              onClick={ () => onSubmit(filters)}
            >
              Suodata
            </Form.Button>
            <Form.Button
                type="button"
                size="mini"
                onClick={ (event) => {
                  setFilters(initialState);
                  onSubmit(initialState);
                  event.stopPropagation();
                } }
              >
              Tyhjennä suodatus
            </Form.Button>
          </Form.Group>

      </Form>
    </Segment>
  );
}
