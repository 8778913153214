import React, { useEffect } from "react";
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { pickBy, identity } from 'lodash';
import {
  Container,
  Header,
  Segment,
  Tab
} from "semantic-ui-react";
import queryString from 'query-string';
import { fetchCompanyOrders, clearOrders} from '../actions/orders';
import { getVisibleOrders } from '../reducers/orders';
import OrderTable from "../components/UserOrderTable";
import { AppLoader } from '../components/AppLoader';
import { OrderFilterForm } from '../components/OrderFilterForm';
import { NavLink, useParams, useLocation, useHistory } from "react-router-dom";


/**
 * Customer company order archive page for displaying a tabbed view of customer drafts and orders.
 */
export function CompanyArchive() {

  const { status } = useParams();
  const location = useLocation();
  const history = useHistory();
  const orders = useSelector((state) => state.orders.companyOrders);
  const isFetching = useSelector((state) => state.orders.isFetching);
  const dispatch = useDispatch();

  const query = location.search;
  const filters = queryString.parse(query);
  const visibleOrders = getVisibleOrders(orders, filters);

  useEffect(() => {
    if (status) {
      dispatch(clearOrders());
      dispatch(fetchCompanyOrders(status));
    }
  }, [dispatch, status]);

  function handleUpdateFilters(filters) {
    const activeFilters = pickBy(filters, identity);
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(activeFilters)
    });
  }

  function handleResetFilter() {
    history.push(location.pathname);
  }

  const pane = () => (
    <Tab.Pane>
      <OrderFilterForm
        key={ status }
        defaultFilters={ filters }
        onSubmit={ handleUpdateFilters }
        onReset={ handleResetFilter }
      />
      <OrderTable
        orders={ visibleOrders }
        status={ status }
      />
    </Tab.Pane>
  );

  const panes = [
    {
      menuItem: {
        as: NavLink,
        id: 'submitted',
        content: 'Lähetetyt tilaukset',
        to: "submitted",
        exact: true,
        key: "submitted",
        active: status === 'submitted'
      },
      render: pane
    },
    {
      menuItem: {
        as: NavLink,
        id: 'completed',
        content: 'Valmistuneet tutkimukset',
        to: "completed",
        exact: true,
        key: "completed",
        active: status === 'completed'
      },
      render: pane
    }
  ]

  return (
    <Container className="page-wrapper">
      <Header size='huge' color='yellow'>Yrityksen tilausarkisto</Header>
      <Segment
        className="segment-rectangle"
      >
        <AppLoader
          active={ isFetching }
          page={ true }
        />
        <Tab
          menu={{
            attached: false,
            tabular: false
          }}
          className="order-archive-tabs"
          renderActiveOnly
          active={ status }
          panes={ panes }
        />
      </Segment>
    </Container>
  );
}
