import React from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

/**
 * A wrapper component for a SemanticDatePicker
 * @param {object} props Props to be forwarded to SemanticDatePicker (https://www.npmjs.com/package/react-semantic-ui-datepickers)
 */
export function DatePicker(props) {
    return (
        <SemanticDatepicker
            { ...props }
            datePickerOnly
            format="DD.MM.YYYY"
        />
    );
}