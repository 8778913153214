import React from 'react';
import { Header, Message, Checkbox, Button } from 'semantic-ui-react';
import { useState } from 'react';

const content = {
    admin: {
        warning: 'Jos poistat tämän näytteenottajan, käyttäjä ei voi enää tarkastella tekemiään tilauksia tai vastaanottamiaan tutkimustuloksia TAKLAB-tilausjärjestelmässä, eikä näytteenottajan kutsunut yritystilin haltija voi enää tarkastella näytteenottajan lähettämiä tilauksia tai niihin saapuneita tutkimustuloksia. Oletko varma, että haluat poistaa tämän näytteenottajan?',
        label: 'Kyllä, haluan poistaa tämän näytteenottajan',
        button: 'Poista näytteenottaja'
    
    },
    customer: {
        warning: 'Jos poistat tämän näytteenottajan, hän ei voi enää tarkastella tekemiään tilauksia tai vastaanottamiaan tutkimustuloksia TAKLAB-tilausjärjestelmässä, etkä sinä yritystilin haltijana voi enää tarkastella näytteenottajan lähettämiä tilauksia tai niihin saapuneita tutkimustuloksia. Oletko varma, että haluat poistaa tämän näytteenottajan?',
        label: 'Kyllä, haluan poistaa tämän näytteenottajan',
        button: 'Poista näytteenottaja'

    }
}

/**
 * User profile section with a delete account feature
 * @param {object} props Component props
 * @param {function} props.onSubmit Handler for the delete user account button
 */
export function DeleteSamplerAccountSection({
    onSubmit,
    type = 'admin'
}) {
    const [confirmed, setConfirmed] = useState(false);

    function handleChange(event, data) {
        setConfirmed(data.checked);
    }

    return (
        <>
            <Header color="red">Poista näytteenottaja</Header>
            <Message negative>
                { content[type].warning }
            </Message>
            <Checkbox
                name="confirm"
                color="red"
                checked={ confirmed }
                onChange={ handleChange }
                label={ content[type].label }
            />
            <div style={{marginTop: '1rem'}}>
                <Button
                    disabled={ !confirmed }
                    color="red"
                    onClick={ onSubmit}
                >
                    { content[type].button }
                </Button>
            </div>
        </>
    )
}