import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { Button, Form, Divider, Icon, Message } from "semantic-ui-react";
import { microbial } from "../enums";
import { OrderGeneral } from "./order-form/OrderGeneral";
import { OrderBuilding } from "./order-form/OrderBuilding";
import { OrderItems } from "./order-form/OrderItems";
import { MicrobeInfo } from "./order-form/MicrobeInfo";
import { AddOrderItemModal } from "./order-form/AddOrderItemModal";
import { OrderType } from "../enums";
import { addOrderItem, updateOrder, deleteOrderItem } from "../actions/order";
import { getFormFieldValue, getFirstAvailableSampleId } from "../utils";
import { ZeroPipe } from "./order-form/ZeroPipe";
import { FlecVoc } from "./order-form/FlecVoc";

/**
 * Component to display the form to create and submit new orders
 * @param {object} props Component props
 * @param {object} props.errors Order form field errors.
 * @param {boolean} props.isAuthenticated Indicates if the form is used by a logged in user.
 * @param {boolean} props.onBlur Form field onBlur event handler.
 * @param {boolean} props.onSubmit Handler for submitting a finished order.
 * @param {boolean} [props.onSaveDraft] Handler for saving a draft. Will display a save draft button for an authenticated user.
 * @param {boolean} [props.onShouldValidate] Handler for a request to validate the form values.
 */
export function OrderForm({ errors, isAuthenticated, onBlur, onSubmit, onSaveDraft }) {
    const [showAddOrderItemModal, setShowAddOrderItemModal] = useState(false);
    const [orderItemToEdit, editOrderItem] = useState();

    const order = useSelector((state) => state.order);
    const metadata = useSelector((state) => state.metadata.data);
    const dispatch = useDispatch();

    const { items, orderTypeId } = order;
    const hasOrderItems = items.length > 0;
    const hasErrors = !isEmpty(errors);

    function handleChange(event, data) {
        const name = data.name;
        if (Array.isArray(data.value)) {
            dispatch(updateOrder({ [name]: data.value }));
        } else {
            const value = getFormFieldValue(event, data);
            dispatch(updateOrder({ [name]: value }));
        }
    }

    function handleEditOrderItem(id) {
        const item = items.find((item) => item.id === id);
        if (item) {
            editOrderItem(item);
        }
    }

    return (
        <Form>
            <OrderGeneral
                order={order}
                errors={errors}
                onChange={handleChange}
                onBlur={onBlur}
                isAuthenticated={isAuthenticated}
            />
            {microbial.includes(orderTypeId) && (
                <MicrobeInfo errors={errors} onBlur={onBlur} orderTypeId={orderTypeId} />
            )}
            <Divider />
            <OrderBuilding
                order={order}
                errors={errors}
                metadata={metadata}
                onChange={handleChange}
                onBlur={onBlur}
                orderTypeId={orderTypeId}
            />
            <Divider />
            {orderTypeId === OrderType.VOLATILE_VOC && (
                <ZeroPipe order={order} errors={errors} onChange={handleChange} onBlur={onBlur} />
            )}
            {orderTypeId === OrderType.FLEC_VOC && (
                <FlecVoc order={order} errors={errors} onChange={handleChange} onBlur={onBlur} />
            )}
            {orderTypeId !== OrderType.FLEC_VOC && (
                <>
                    <OrderItems
                        errors={errors.items}
                        items={items}
                        orderTypeId={orderTypeId}
                        onDelete={(id) => dispatch(deleteOrderItem(id))}
                        onEdit={handleEditOrderItem}
                    />
                    <Button
                        type="button"
                        icon
                        labelPosition="right"
                        primary
                        onClick={() => setShowAddOrderItemModal(true)}
                    >
                        <Icon name="add" />
                        Lisää näyte
                    </Button>
                    <Divider />
                </>
            )}
            {!hasOrderItems && orderTypeId !== OrderType.FLEC_VOC && (
                <Message negative>
                    Et ole lisännyt yhtään näyteriviä. Voit lähettää tilauksen, kun olet lisännyt
                    näyterivejä.
                </Message>
            )}

            {hasErrors && (
                <Message negative>
                    Lomakkeessa on virheellisiä tai puuttuvia kenttiä. Ole hyvä ja tarkista lomake!
                </Message>
            )}
            <Form.Group>
                {isAuthenticated && onSaveDraft && (
                    <Form.Button type="button" onClick={onSaveDraft}>
                        Tallenna
                    </Form.Button>
                )}
                <Form.Button
                    disabled={!hasOrderItems && orderTypeId !== OrderType.FLEC_VOC}
                    type="button"
                    onClick={onSubmit}
                    primary
                >
                    Lähetä tilaus
                </Form.Button>
            </Form.Group>
            <AddOrderItemModal
                defaultSampleId={getFirstAvailableSampleId(
                    order.items.map((item) => item.sampleId)
                )}
                metadata={metadata}
                onClose={() => {
                    setShowAddOrderItemModal(false);
                    editOrderItem(null);
                }}
                onSubmit={(item) => {
                    dispatch(addOrderItem(item));
                    setShowAddOrderItemModal(false);
                    editOrderItem(null);
                }}
                edit={orderItemToEdit}
                open={showAddOrderItemModal || !!orderItemToEdit}
                orderTypeId={orderTypeId}
            />
        </Form>
    );
}
