import React, { useState, useEffect } from "react";
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Header,
  Grid,
  Form,
  Segment,
  Button
} from "semantic-ui-react";
import { AppLoader } from "../components/AppLoader";
import { validatePasswordReset } from "../validation/validate";
import { InfoModal } from "../components/InfoModal";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "../router/routes";
import { resetPassword, validateResetToken } from "../actions/auth";

/**
 * Password reset page for checking password reset token and setting a new password
 */
export function ResetPassword() {
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({});
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const history = useHistory();
    const { id, token } = useParams();

    const isFetching = useSelector((state) => state.auth.isFetching);
    const dispatch = useDispatch();

    useEffect(() => {
        async function validateToken() {
            try {
                await dispatch(validateResetToken({ id, token }));
                setIsValid(true);
            } catch(error) {
                history.replace(routes.HOME);
            }
        }
        validateToken();
    }, [dispatch, history, id, token]);

    async function handleSubmit() {
        const err = validatePasswordReset(data);
        setErrors(err || {});
        if (!err) {
            try {
                await dispatch(resetPassword({
                    ...data,
                    id,
                    token
                }));
                setShowSuccessModal(true);
            } catch(error) {
                // Something went wrong
            }
        }
    }

    function handleBlur() {
        if (!isEmpty(errors)) {
            const err = validatePasswordReset(data);
            setErrors(err || {});
        }
    }

  return (
    <Container>
      <AppLoader
        active={ isFetching }
        page={ true }
      />
      <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
          { isValid &&
            <Grid.Column style={{ maxWidth: 450 }}>
                <Header size="huge" color="yellow" textAlign="center">
                    Aseta uusi salasana
                </Header>
                <Form
                    size='large'
                    onSubmit={ handleSubmit }
                >
                    <Segment textAlign="left">
                        <Form.Field>
                            <Form.Input
                                type="password"
                                label="Uusi salasana"
                                error={ errors.password }
                                value={ data.password || '' }
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Uusi salasana'
                                onChange={ (event) => setData({
                                    ...data,
                                    password: event.target.value
                                }) }
                                onBlur={ handleBlur }
                                maxLength="100"
                            />
                        </Form.Field>
                        <Button
                            fluid
                        >
                            Lähetä
                        </Button>
                    </Segment>
                </Form>
            </Grid.Column>
          }
      </Grid>
      <InfoModal
            content="Salasanasi on vaihdettu."
            open={ showSuccessModal }
            onClose={  () => history.replace(routes.HOME) }
            onConfirm={ () =>  history.replace(routes.HOME) }
        />
    </Container>
  );
}
