import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";
import { routes } from './routes';
import { Home } from '../pages/Home';
import Login from '../pages/Login';
import NavBar from "../components/NavBar";
import { Order } from "../pages/Order";
import { Archive } from "../pages/Archive";
import { CompanyArchive } from "../pages/CompanyArchive";
import Signup from '../pages/Signup';
import { Dashboard } from "../pages/Dashboard";
import { SamplerDashboard } from "../pages/SamplerDashboard";
import PrivateCustomerRoute from "./PrivateCustomerRoute";
import PrivateSamplerRoute from "./PrivateSamplerRoute";
import PublicOnlyRoute from "./PublicOnlyRoute";
import { Help } from "../pages/Help";
import { SingleOrder } from "../pages/SingleOrder";
import { ErrorModal } from "../components/ErrorModal";
import { UserProfile } from "../pages/UserProfile";
import { SamplerProfile } from "../pages/SamplerProfile";
import { NotFound } from "../pages/NotFound";
import { AdminLogin } from "../pages/admin/Login";
import AdminRoute from "./AdminRoute";
import { AdminUsersView } from "../pages/admin/Users";
import { AdminOrdersView } from "../pages/admin/Orders";
import { SingleUser } from "../pages/admin/SingleUser";
import { SingleSampler } from "../pages/admin/SingleSampler";
import { ForgotPassword } from "../pages/ForgotPassword";
import { ResetPassword } from "../pages/ResetPassword";
import { SamplerSignup } from "../pages/SamplerSignup";
import ScrollToTop from "../components/ScrollToTop";
import { AdminProfile } from "../pages/AdminProfile";
import { Sampler } from "../pages/Sampler";

export function AppRouter() {
    return (
        <Router
            // basename={'/taklab'}
        >
            <ScrollToTop />
            <Switch>
                <Route
                    exact
                    path={ routes.ADMIN_LOGIN }
                    component={ AdminLogin }
                />
                <AdminRoute path={ routes.ADMIN }>
                    <NavBar
                        type='admin'
                        mobileBreakpoint={ 1024 }
                    />
                    <AdminRoutes />
                </AdminRoute>
                <PrivateCustomerRoute path={ routes.USER }>
                    <NavBar
                        type='customer'
                        mobileBreakpoint={ 1024 }
                    />
                    <CustomerRoutes />
                </PrivateCustomerRoute>
                <PrivateSamplerRoute path={ routes.SAMPLER }>
                    <NavBar
                        type='sampler'
                        mobileBreakpoint={ 1024 }
                    />
                    <SamplerRoutes />
                </PrivateSamplerRoute>                
                <Route path={ routes.HOME }>
                    <NavBar
                        type='public'
                        mobileBreakpoint={ 1024 }
                    />
                    <PublicRoutes />
                </Route>
                <Route component={ NotFound }>
                </Route>
            </Switch>
            <ErrorModal />
        </Router>
    );
}

function PublicRoutes() {
    return (
        <Switch>
            <PublicOnlyRoute
                exact
                path={ routes.HOME }
            >
                <Home />
            </PublicOnlyRoute>
            <Route
                exact
                path={ routes.FORGOT_PASSWORD }
                component={ ForgotPassword }
            />
            <Route
                exact
                path={ `${routes.PASSWORD_RESET}/:id/:token` }
                component={ ResetPassword }
            />
            <Route
                exact
                path={ `${routes.SAMPLER_SIGNUP}/:token` }
                component={ SamplerSignup }
            />            
            <Route
                exact
                path={ routes.USER_LOGIN }
                component={ Login }
            />
            <Route
                exact
                path={ routes.HELP }
                component={ Help }
            />
            <Route
                exact
                path={ routes.USER_SIGNUP }
                component={ Signup }
            />
            <PublicOnlyRoute
                exact
                path={ routes.CREATE_ORDER_PUBLIC }
            >
                <Order />
            </PublicOnlyRoute>
            <Route component={ NotFound }>
            </Route>
        </Switch>
    );
}

function CustomerRoutes() {
    return (
        <Switch>
            <Route
                exact
                path={routes.USER_DASHBOARD}
                component={ Dashboard }
            />
            <Route
                path={ routes.CREATE_ORDER }
                component={ Order }
            />
            <Route
                exact
                path={ `${routes.VIEW_ORDER}/:id`}
                component={ SingleOrder }
            />
            <Route
                path={routes.USER_HELP}
                component={ Help }
            />
            <Route
                path={routes.PROFILE}
                component={ UserProfile }
            />
            <Route
                path={ `${routes.ARCHIVE}/:status(submitted|completed|draft)` }
                component={ Archive }
            />
            <Route
                path={ `${routes.COMPANY_ARCHIVE}/:status(submitted|completed|draft)` }
                component={ CompanyArchive }
            />            
            <Route
                path={ `${routes.VIEW_SAMPLER}/:samplerId` }
                component={ Sampler }
            />            
            <Route component={ NotFound }>
            </Route>
        </Switch>
    )
}

function SamplerRoutes() {
    return (
        <Switch>
            <Route
                exact
                path={routes.SAMPLER_DASHBOARD}
                component={ SamplerDashboard }
            />
            <Route
                path={routes.SAMPLER_PROFILE}
                component={ SamplerProfile }
            />
            <Route
                path={ routes.SAMPLER_CREATE_ORDER }
                component={ Order }
            />
            <Route
                exact
                path={ `${routes.SAMPLER_VIEW_ORDER}/:id`}
                component={ SingleOrder }
            />
            <Route
                path={routes.SAMPLER_HELP}
                component={ Help }
            />
            <Route
                path={ `${routes.SAMPLER_ARCHIVE}/:status(submitted|completed|draft)` }
                component={ Archive }
            />
            <Route component={ NotFound }>
            </Route>
        </Switch>
    )
}

function AdminRoutes() {
    return (
        <Switch>
            <Route
                exact
                path={routes.ADMIN_DASHBOARD}
                component={ () => <Redirect to={ `${routes.ADMIN_ORDERS}/submitted`} /> }
            />
            <Route
                exact
                path={ `${routes.ADMIN_ORDERS}/:status(submitted|completed)` }
                component={ AdminOrdersView }
            />
            <Route
                exact
                path={ `${routes.ADMIN_VIEW_ORDER}/:id`}
                component={ SingleOrder }
            />
            <Route
                exact
                path={ `${routes.ADMIN_VIEW_USER}/:id`}
                component={ SingleUser }
            />
            <Route
                exact
                path={ `${routes.ADMIN_VIEW_SAMPLER}/:id`}
                component={ SingleSampler }
            />
            <Route
                exact
                path={ `${routes.ADMIN_PROFILE}`}
                component={ AdminProfile }
            />
            <Route
                path={routes.ADMIN_USER_VIEW}
                component={ AdminUsersView }
            />
            <Route component={ NotFound }>
            </Route>
        </Switch>
    )
}
