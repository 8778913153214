import React from 'react';
import {
    Checkbox,
    Form,
    Header
} from "semantic-ui-react";
import { DatePicker } from '../../DatePicker';
import { IconWithTooltip } from '../../IconWithTooltip';
import { fieldNames } from '../../../enums';
import { SampleTypeSelect } from '../../SampleTypeSelect';

export function MaterialVocForm({
    item,
    errors,
    onChange,
    onBlur,
}) {
    return (
        <>
            <BasicData
                item={ item }
                errors={ errors }
                onChange={ onChange }
                onBlur={ onBlur }
            />
            <Form.Input
                required
                error={ errors.description }
                name="description"
                value={ item.description || '' }
                label={ `${fieldNames.orderItem.DESCRIPTION}` }
                placeholder={ fieldNames.orderItem.DESCRIPTION }
                onChange={ onChange }
                onBlur={ onBlur }
                maxLength="255"
            />
            <Form.Input
                error={ errors.spaceNumber }
                name="spaceNumber"
                type="number"
                step="1"
                min="1"                
                value={ item.spaceNumber || '' }
                label={ `${fieldNames.orderItem.SPACE_NUMBER}` }
                placeholder={ fieldNames.orderItem.SPACE_NUMBER }
                onChange={ onChange }
                onBlur={ onBlur }
                maxLength="20"
            />
            <Form.Input
                error={ errors.otherNotes }
                name="otherNotes"
                value={ item.otherNotes || '' }
                label={ `${fieldNames.orderItem.OTHER_NOTES}` }
                placeholder={ fieldNames.orderItem.OTHER_NOTES }
                onChange={ onChange }
                onBlur={ onBlur }
                maxLength="255"
            />
        </>
    );
}

export function VolatileVocForm({
    item,
    errors,
    onChange,
    onBlur,
}) {
    return (
        <>
            <BasicData
                item={ item }
                errors={ errors }
                onChange={ onChange }
                onBlur={ onBlur }
            />
            <Form.Input
                required
                error={ errors.description }
                name="description"
                value={ item.description || '' }
                label={ `${fieldNames.orderItem.DESCRIPTION}` }
                placeholder={ fieldNames.orderItem.DESCRIPTION }
                onChange={ onChange }
                onBlur={ onBlur }
                maxLength="255"
            />
            <Form.Group widths={2}>
                <Form.Input
                    required
                    error={ errors.pipeNumber }
                    name="pipeNumber"
                    type="number"
                    step="1"
                    min="100000"
                    max="999999"
                    value={ item.pipeNumber || '' }
                    label={ `${fieldNames.orderItem.PIPE_NUMBER}` }
                    placeholder={ fieldNames.orderItem.PIPE_NUMBER }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    maxLength="6"
                />
                <Form.Input
                    required
                    error={ errors.samplingTime }
                    name="samplingTime"
                    type="number"
                    step="0.01"
                    min="0"
                    value={ item.samplingTime || '' }
                    label={ `${fieldNames.orderItem.SAMPLING_TIME}` }
                    placeholder={ fieldNames.orderItem.SAMPLING_TIME }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    maxLength="6"
                />
            </Form.Group>
            <Form.Group widths={2}>
                <Form.Input
                    required
                    error={ errors.pumpNumber }
                    name="pumpNumber"
                    value={ item.pumpNumber || '' }
                    label={ `${fieldNames.orderItem.PUMP_NUMBER}` }
                    placeholder={ fieldNames.orderItem.PUMP_NUMBER }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    maxLength="20"
                />
                <Form.Input
                    required
                    error={ errors.pumpFlow }
                    name="pumpFlow"
                    type="number"
                    step="0.1"
                    min="0"
                    value={ item.pumpFlow || '' }
                    label={ `${fieldNames.orderItem.PUMP_FLOW}` }
                    placeholder={ fieldNames.orderItem.PUMP_FLOW }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    maxLength="20"
                />
            </Form.Group>
            <Form.Input
                error={ errors.otherNotes }
                name="otherNotes"
                value={ item.otherNotes || '' }
                label={ `${fieldNames.orderItem.OTHER_NOTES}` }
                placeholder={ fieldNames.orderItem.OTHER_NOTES }
                onChange={ onChange }
                onBlur={ onBlur }
                maxLength="255"
            />
        </>
    );
}

export function MaterialForm({
    item,
    errors,
    onChange,
    onBlur,
    sampleTypes,
    maxSampleTypes = 0,
    microbe = false
}) {
    const options = sampleTypes.map(type => {
        const name = type.name !== type.label ? `, ${type.name}` : '';
        const text = `${type.label}${name}`;
        return {
            text,
            value: type.id
        }
    });
    return (
        <>
            { microbe &&
                <Form.Field>
                    <Checkbox
                        name="isControlSample"
                        checked={ item.isControlSample }
                        onChange={ onChange }
                        onBlur={ onBlur }
                        label={ fieldNames.orderItem.CONTROL_SAMPLE }
                    />
                </Form.Field>
            }
            <BasicData
                item={ item }
                errors={ errors }
                onChange={ onChange }
                onBlur={ onBlur }
            />
            { maxSampleTypes !== 0 &&
                <Form.Group widths={2}>
                    <SampleTypeSelect
                        required
                        multiple
                        maxSelection={ maxSampleTypes }
                        value={ item.sampleTypes }
                        error={ errors.sampleTypes }
                        name="sampleTypes"
                        label={
                            <>
                                <label style={{ display: 'inline' }}>{ fieldNames.orderItem.SAMPLE_TYPE } {
                                    maxSampleTypes > 1 ? `(valitse enintään ${ maxSampleTypes })` : '(valitse yksi)'
                                }</label>
                            </>
                        }
                        placeholder={ fieldNames.orderItem.SAMPLE_TYPE }
                        options={ options }
                        onChange={ onChange }
                        onBlur={ onBlur }
                    />
                </Form.Group>
            }

            <Form.Input
                required
                error={ errors.description }
                name="description"
                value={ item.description || '' }
                label={ `${fieldNames.orderItem.DESCRIPTION}${ microbe ? fieldNames.orderItem.DESCRIPTION_TIP : ''}` }
                placeholder={ fieldNames.orderItem.DESCRIPTION }
                onChange={ onChange }
                onBlur={ onBlur }
                maxLength="255"
            />
        </>
    );
}

export function VolatileFiberForm({
    errors,
    item,
    onBlur,
    onChange
}) {
    return (
        <>
            <BasicData
                item={ item }
                errors={ errors }
                onChange={ onChange }
                onBlur={ onBlur }
            />
            <Form.Group widths={2}>
                <Form.Input
                    required
                    fluid
                    error={ errors.sampleLocation }
                    name="sampleLocation"
                    value={ item.sampleLocation || '' }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    label={ fieldNames.orderItem.SAMPLE_LOCATION }
                    placeholder={ fieldNames.orderItem.SAMPLE_LOCATION }
                    maxLength="255"
                />
                <Form.Input
                    error={ errors.samplingPeriod }
                    value={ item.samplingPeriod || '' }
                    type="number"
                    step="1"
                    min="1"
                    label={ fieldNames.orderItem.SAMPLING_PERIOD }
                    name="samplingPeriod"
                    placeholder={ fieldNames.orderItem.SAMPLING_PERIOD }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    maxLength="10"
                />
            </Form.Group>
            <Form.Group widths={ 2 }>
                <Form.Input
                    error={ errors.airFlow }
                    value={ item.airFlow || '' }
                    type="number"
                    step="1"
                    min="0"
                    label={ fieldNames.orderItem.AIR_FLOW }
                    name="airFlow"
                    placeholder={ fieldNames.orderItem.AIR_FLOW }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    maxLength="10"
                />
                <Form.Input
                    required
                    error={ errors.airVolume }
                    value={ item.airVolume || '' }
                    type="number"
                    step="1"
                    min="0"
                    label={ fieldNames.orderItem.AIR_VOLUME }
                    name="airVolume"
                    placeholder={ fieldNames.orderItem.AIR_VOLUME }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    maxLength="10"
                />
            </Form.Group>
        </>
    );
}

export function VolatileMicrobeForm({
    errors,
    item,
    onBlur,
    onChange
}) {
    const {
        sampleLocation,
        samplingPeriod,
        sampleHeight,
        roomTemperature,
        extTemperature,
        previousWaterDamage,
        waterDamageDate,
        personsAnimals,
        relativeHumidity,
        additionalInfo
    } = item;
    return (
        <>
            <Form.Field>
                <Checkbox
                    name="isControlSample"
                    checked={ item.isControlSample }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    label={ fieldNames.orderItem.CONTROL_SAMPLE }
                />
            </Form.Field>
            <BasicData
                item={ item }
                errors={ errors }
                onChange={ onChange }
                onBlur={ onBlur }
            />
            <Form.Group widths={2}>
                <Form.Input
                    required
                    error={ errors.sampleLocation }
                    fluid
                    name="sampleLocation"
                    value={ sampleLocation || '' }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    label={ fieldNames.orderItem.SAMPLE_LOCATION }
                    placeholder={ fieldNames.orderItem.SAMPLE_LOCATION }
                    maxLength="255"
                />
                <Form.Input
                    required
                    error={ errors.samplingPeriod }
                    type="number"
                    min="1"
                    step="1"
                    label={ fieldNames.orderItem.SAMPLING_PERIOD }
                    name="samplingPeriod"
                    value={ samplingPeriod || '' }
                    placeholder={ fieldNames.orderItem.SAMPLING_PERIOD }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    maxLength="10"
                />
            </Form.Group>
            <Header as="h3">Mahdollisiin mikrobipitoisuuksiin vaikuttavat taustatekijät</Header>
            <Form.Group widths="equal">
                <Form.Field>
                    <label>{ fieldNames.orderItem.ROOM_TEMPERATURE } (&deg;C) <span style={{ color: 'red '}}>*</span></label>
                    <Form.Input
                        required
                        error={ errors.roomTemperature }
                        type="number"
                        step="0.1"
                        value={ roomTemperature || ''}
                        name="roomTemperature"
                        placeholder={ fieldNames.orderItem.ROOM_TEMPERATURE }
                        onChange={ onChange }
                        onBlur={ onBlur }
                        maxLength="10"
                    />
                </Form.Field>
                <Form.Field>
                    <label>{ fieldNames.orderItem.EXT_TEMPERATURE } (&deg;C) <span style={{ color: 'red '}}>*</span></label>
                    <Form.Input
                        required
                        error={ errors.extTemperature }
                        type="number"
                        step="0.1"
                        value={ extTemperature || ''}
                        name="extTemperature"
                        placeholder={ fieldNames.orderItem.EXT_TEMPERATURE }
                        onChange={ onChange }
                        onBlur={ onBlur }
                        maxLength="10"
                    />
                </Form.Field>
                <Form.Input
                    error={ errors.relativeHumidity }
                    type="number"
                    step="0.1"
                    min="0"
                    max="100"
                    value={ relativeHumidity || ''}
                    label={ fieldNames.orderItem.RELATIVE_HUMIDITY }
                    name="relativeHumidity"
                    placeholder={ fieldNames.orderItem.RELATIVE_HUMIDITY }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    maxLength="10"
                />
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Input
                    required
                    error={ errors.sampleHeight }
                    value={ sampleHeight || ''}
                    label={ fieldNames.orderItem.SAMPLE_HEIGHT }
                    name="sampleHeight"
                    placeholder={ fieldNames.orderItem.SAMPLE_HEIGHT }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    maxLength="10"
                />
                <Form.Input
                    error={ errors.personsAnimals }
                    min="0"
                    step="1"
                    value={ personsAnimals || ''}
                    label={ fieldNames.orderItem.PERSONS_ANIMALS }
                    name="personsAnimals"
                    placeholder={ fieldNames.orderItem.PERSONS_ANIMALS }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    maxLength="10"
                />
            </Form.Group>
            <Form.Group widths={1}>
                <Form.TextArea
                    name="additionalInfo"
                    value={ additionalInfo || '' }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    label={ fieldNames.orderItem.ADDITIONAL_INFO }
                    placeholder='Kirjoita tähän'
                    maxLength="255"
                />
            </Form.Group>
            <Form.Group widths={2}>
                <Form.Field>
                    <label>Aikaisemmat vesivauriot</label>
                    <Checkbox
                        name="previousWaterDamage"
                        checked={ previousWaterDamage }
                        onChange={ onChange }
                        onBlur={ onBlur }
                        label={ fieldNames.orderItem.PREVIOUS_WATER_DAMAGE }
                    />
                </Form.Field>
                <Form.Input
                    disabled={ !item.previousWaterDamage }
                    value={ waterDamageDate || ''}
                    label={ fieldNames.orderItem.WATER_DAMAGE_DATE }
                    name="waterDamageDate"
                    placeholder={ fieldNames.orderItem.WATER_DAMAGE_DATE }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    maxLength="255"
                />
            </Form.Group>
        </>
    );
}

export function BrushingMicrobeForm({
    errors,
    item,
    onChange,
    onBlur,
    sampleTypes
}) {
    const options = sampleTypes.map(type => {
        const name = type.name !== type.label ? `, ${type.name}` : '';
        const text = `${type.label}${name}`;
        return {
            text,
            value: type.id
        }
    });
    return (
        <>
            <Form.Field>
                <Checkbox
                    name="isControlSample"
                    checked={ item.isControlSample }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    label={ fieldNames.orderItem.CONTROL_SAMPLE }
                />
            </Form.Field>
            <BasicData
                item={ item }
                errors={ errors }
                onChange={ onChange }
                onBlur={ onBlur }
            />
            <Form.Group widths={2}>
                <SampleTypeSelect
                    required
                    multiple
                    value={ item.sampleTypes }
                    error={ errors.sampleTypes }
                    name="sampleTypes"
                    label={
                        <>
                            <label style={{ display: 'inline' }}>{ fieldNames.orderItem.SAMPLE_TYPE }</label>
                            <IconWithTooltip
                                link
                                tooltip={ fieldNames.orderItem.SAMPLE_TYPE }
                                name="help circle"
                            />
                        </>
                    }
                    placeholder={ fieldNames.orderItem.SAMPLE_TYPE }
                    options={ options }
                    onChange={ onChange }
                    onBlur={ onBlur }
                />
                <Form.Field>
                    <label>{ fieldNames.orderItem.SURFACE_AREA } cm<sup>2</sup> <span style={{ color: 'red '}}>*</span></label>
                    <Form.Input
                        required
                        error={ errors.surfaceArea }
                        value={ item.surfaceArea }
                        min="1"
                        step="1"
                        type="number"
                        name="surfaceArea"
                        placeholder={ fieldNames.orderItem.SURFACE_AREA }
                        onChange={ onChange }
                        onBlur={ onBlur }
                        maxLength="10"
                    />
                </Form.Field>
            </Form.Group>
            <Form.Input
                required
                error={ errors.description }
                name="description"
                value={ item.description || '' }
                label={ `${ fieldNames.orderItem.DESCRIPTION }${ fieldNames.orderItem.DESCRIPTION_TIP }` }
                placeholder={ fieldNames.orderItem.DESCRIPTION }
                onChange={ onChange }
                onBlur={ onBlur }
                maxLength="255"
            />
        </>
    );
}

function BasicData({
    item,
    errors,
    onChange,
    onBlur
}) {
    let date = item.date;
    if (date && typeof date === 'string') {
        date = new Date(date);
    }
    return (
        <Form.Group widths={2}>
            <Form.Input
                required
                fluid
                error={ errors.sampleId }
                name="sampleId"
                value={ item.sampleId || '' }
                onChange={ onChange }
                onBlur={ onBlur }
                label={ fieldNames.orderItem.SAMPLE_ID }
                placeholder={ fieldNames.orderItem.SAMPLE_ID }
                maxLength="100"
            />
            <DatePicker
                required
                maxDate={ new Date() }
                error={ errors.date }
                name="date"
                label={ fieldNames.orderItem.DATE }
                value={ date }
                onChange={ onChange }
                onBlur={ onBlur }
            />
        </Form.Group>
    )
}
