import React, { useState } from 'react';
import {
    Button,
    Form,
    Segment
} from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { validateLogin } from '../validation/validate';

/**
 * Login form component
 * @param {object} props Component props
 * @param {function} props.onSubmit Form submit handler
 */
export function LoginForm ({ onSubmit }) {
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({});

    function handleSubmit() {
        const err = validateLogin(data);
        setErrors(err || {});
        if (!err) {
          onSubmit(data);
        }
    }

    function handleBlur() {
        if (!isEmpty(errors)) {
            const err = validateLogin(data);
            setErrors(err || {});
        }
    }

    return (
        <Form
            size='large'
            onSubmit={ handleSubmit }
        >
            <Segment textAlign="left">
                <Form.Field>
                    <Form.Input
                        name="email"
                        error={ errors.email }
                        value={ data.email || '' }
                        fluid
                        icon='user'
                        iconPosition='left'
                        placeholder='Sähköpostiosoite'
                        onChange={ (event) => setData({
                            ...data,
                            email: event.target.value
                        }) }
                        onBlur={ handleBlur }
                        maxLength="100"
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        name="password"
                        error={ errors.password }
                        value={ data.password || '' }
                        fluid
                        icon='lock'
                        iconPosition='left'
                        placeholder='Salasana'
                        type='password'
                        onChange={ (event) => setData({
                            ...data,
                            password: event.target.value
                        }) }
                        onBlur={ handleBlur }
                        maxLength="100"
                    />
                </Form.Field>
                <Button
                    fluid
                >
                    Kirjaudu
                </Button>
            </Segment>
        </Form>
    )
}