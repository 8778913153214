import React from 'react';
import { Modal } from 'semantic-ui-react';
import { LoginForm } from './LoginForm';
import { login } from '../actions/auth';
import { useDispatch } from 'react-redux';

/**
 * Modal to display the login form
 * @param {object} props Component props
 * @param {function} props.onClose Modal close handler
 * @param {function} props.onLogin Handler to run after a successful login
 * @param {boolean} props.open Indicates whether to show the modal
 */
export function LoginModal({
    onClose,
    onLogin,
    open
}) {

    const dispatch = useDispatch();

    async function handleLogin(credentials) {
        try {
            await dispatch(login(credentials));
            onLogin();
        } catch(error) {
            // The error's been handled, this is just to let component know about it
        }
    }

    return (
        <Modal
            size="small"
            closeOnDimmerClick={ true }
            closeIcon={ true }
            open={ open }
            onClose={ onClose }
        >
            <Modal.Header>Kirjaudu sisään</Modal.Header>
            <Modal.Content>
                <LoginForm
                    onSubmit={ handleLogin }
                />
            </Modal.Content>
        </Modal>
    )
}

