import React from 'react';
import {
    partition,
    zip
} from 'lodash';
import {
    Form,
    Dropdown,
    Responsive,
    Segment,
    Grid
} from 'semantic-ui-react';
import { IconWithTooltip } from './IconWithTooltip';
import { UploadResultForm } from './UploadResultForm';
import { withIndex } from '../utils';

export function MobileList({
    columns,
    data,
    onDownload,
    onEdit,
    onView,
    onUpload,
    onDelete
}) {
    const hasActions = (onDownload || onDelete || onEdit || onUpload);

    return (
        <div style={{ marginBottom: '1rem' }}>
            { data.map(item => {
                let gridColumns = columns.map((dataColumn) => {
                    const name = dataColumn.name;
                    let data = item[name];
                    if(name==='itemsCount' && data == 0) {
                        data = "-";
                    }
                    if (['updatedAt', 'submittedAt'].includes(name)) {
                        data = data.slice(0, 10);
                    }
                    return (
                        <Grid.Column key={ name }>
                            <label
                                style={{
                                    fontWeight: "bold",
                                    marginBottom: ".5rem",
                                    whiteSpace: "pre"
                                }}
                            >{ dataColumn.content }</label>
                            <p>{ data }</p>
                        </Grid.Column>
                    );
                });

                gridColumns = partition(gridColumns, withIndex((column, index) => index % 2 !== 1));
                let gridRows = zip(...gridColumns);
                gridRows = gridRows.map((contents) => (
                    <Grid.Row key={contents[0].key}>
                        { contents }
                    </Grid.Row>
                ));

                return (
                    <Segment
                        className={ `mobile-list-item${(
                            (item.status === 'COMPLETED' && !item.resultSeen) ||
                            (item.hasOwnProperty('seen') && !item.seen)
                            ) ? ' not-seen' : ''}`
                        }
                        onClick={ () => {
                            item.status === 'DRAFT' ? onEdit(item.id) : onView(item.id)
                        } }
                        key={item.id}
                    >
                        <Responsive
                            maxWidth={ 575 }
                        >
                            <Grid
                                stackable
                                columns={ 2 }
                            >
                                { gridRows}
                            </Grid>
                        </Responsive>
                        <Responsive
                            minWidth={ 576 }
                        >
                            <Grid
                                columns={ 2 }
                            >
                                { gridRows}
                            </Grid>
                        </Responsive>
                        { hasActions &&
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    padding: "1rem"
                                }}
                            >
                                { onUpload && item.status === 'SUBMITTED' &&
                                    <UploadResultForm
                                        onSubmit={ (data) => onUpload(item.id, data) }
                                    />
                                }
                                { onDownload && item.status === 'COMPLETED' &&
                                    <IconWithTooltip
                                        tooltip="Lataa tulokset"
                                        link
                                        color="black"
                                        size="large"
                                        name="download"
                                        onClick={ (event) => {
                                            event.stopPropagation();
                                            onDownload(item.id, item.buildingAddress);
                                        } }
                                    />
                                }
                                { onDelete &&
                                    <IconWithTooltip
                                        tooltip="Poista"
                                        link
                                        color="black"
                                        size="large"
                                        name="trash"
                                        onClick={ (event) => {
                                            event.stopPropagation();
                                            onDelete(item.id);
                                        } }
                                    />
                                }
                            </div>
                        }
                    </Segment>
                    )
                })
            }
        </div>
    )
}