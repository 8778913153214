import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from '../router/routes';
import { InterActiveTable } from './InterActiveTable';

/**
 * Component to display a list of samplers for a customer
 * @param {object} props Component props
 * @param {object[]} props.samplers The samplers to display in the table
 */
export default function UserSamplerTable({
    samplers,
    missingSpecifier = ""
}) {

    const dispatch = useDispatch();

    const columns = [
        {
            content: "Nimi",
            name: "name",
            sortable: true
        },
        {
            content: "Sähköpostiosoite",
            name: 'email',
            sortable: true
        },
        {
            content: "Puhelinnumero",
            name: "phone",
            sortable: false
        }
    ];

    const history = useHistory();

    return (
        <>
            <InterActiveTable
                title={ 'Näytteenottajat' }
                onView={ (id) => history.push(`${routes.VIEW_SAMPLER}/${id}`) }
                columns={ columns }
                defaultSort={{
                    column: 'name',
                    direction: 'descending'
                }}
                data={ samplers }
                missingSpecifier = { missingSpecifier }
            />
        </>
    )
}

