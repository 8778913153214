import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Link,
    useHistory
} from "react-router-dom";
import {
    Container,
    Image,
    Menu,
    Dropdown,
    Responsive,
    Confirm,
} from "semantic-ui-react";
import { routes } from '../router/routes';
import { logout, confirmLogout, cancelLogout } from '../actions/auth';
import logo from '../assets/images/taklab-black-small.png';

const publicItems = [
    {
        to: routes.CREATE_ORDER_PUBLIC,
        label: 'Tilaa tutkimus'
    },
    {
        to: routes.HELP,
        label: 'Ohjeet'
    },
    {
        to: routes.USER_SIGNUP,
        label: 'Luo yritystili'
    },
    {
        to: routes.USER_LOGIN,
        label: 'Kirjaudu sisään'
    }
]

const customerItems = [
    {
        to: routes.USER_DASHBOARD,
        label: 'Etusivu'
    },
    {
        to: routes.CREATE_ORDER,
        label: 'Tilaa tutkimus'
    },
    {
        to: `${routes.COMPANY_ARCHIVE}/completed`,
        label: 'Yrityksen tilausarkisto'
    },
    {
        to: `${routes.ARCHIVE}/draft`,
        label: 'Oma tilausarkisto'
    },
    {
        to: routes.USER_HELP,
        label: 'Ohjeet'
    },
    {
        to: routes.PROFILE,
        label: 'Omat tiedot'
    }
];

const samplerItems = [
    {
        to: routes.SAMPLER_DASHBOARD,
        label: 'Etusivu'
    },
    {
        to: routes.SAMPLER_CREATE_ORDER,
        label: 'Tilaa tutkimus'
    },
    {
        to: `${routes.SAMPLER_ARCHIVE}/draft`,
        label: 'Tilausarkisto'
    },
    {
        to: routes.SAMPLER_HELP,
        label: 'Ohjeet'
    },
    {
        to: routes.SAMPLER_PROFILE,
        label: 'Omat tiedot'
    }    
];

const adminItems = [
    {
        to: routes.ADMIN_SUBMITTED,
        label: 'Saapuneet tilaukset'
    },
    {
        to: routes.ADMIN_COMPLETED,
        label: 'Valmiit tutkimukset'
    },
    {
        to: routes.ADMIN_USER_VIEW,
        label: 'Asiakkaat'
    },
    {
        to: routes.ADMIN_PROFILE,
        label: 'Oma tili'
    }
];

/**
 * User profile section with logout all devices feature
 * @param {object} props Component props
 * @param {number} [props.mobileBreakpoint=1024] Navigation bar will collapse to a hamburger icon at this breakpoint
 * @param {'admin' | 'customer'} [props.type=customer] Navigation bar type
 */
export default function NavBar({
    mobileBreakpoint = 1024,
    type = 'customer'
}) {
    const history = useHistory();

    const isAdmin = useSelector((state) => state.auth.isAdmin);
    const showConfirmLogout = useSelector((state) => state.auth.showConfirmLogout);
    const dispatch = useDispatch();

    async function handleLogout() {
        try {
            const route = isAdmin ? routes.ADMIN : routes.HOME;
            await dispatch(logout());
            history.push(route);
        } catch(error) {
            // Just to let this component know it failed
        }
    }

    let home;
    let items;
    switch(type) {
        case 'public': {
            home = routes.HOME;
            items = publicItems;
            break;
        }
        case 'customer': {
            home = routes.USER;
            items = customerItems;
            break;
        }
        case 'sampler': {
            home = routes.SAMPLER;
            items = samplerItems;
            break;
        }
        case 'admin': {
            home = routes.ADMIN;
            items = adminItems;
            break;
        }
        default: {
            home = routes.HOME;
            items = publicItems;
        }
    }

    return (
            <Menu
                borderless
                fixed='top'
            >
                <Container>
                    <Image
                        className="navbar-brand"
                        as={ Link }
                        to={ home }
                        size="small"
                        src={ logo }
                    />
                </Container>
                <Responsive
                    as={ React.Fragment }
                    maxWidth={ mobileBreakpoint }
                >
                    <Dropdown
                        style={{ marginRight: '.8rem' }}
                        className="mobile-menu-dropdown"
                        direction="left"
                        item
                        icon="sidebar"
                        size="large"
                    >
                        <Dropdown.Menu>
                            { items.map((item) => (
                                <Menu.Item
                                    key={ item.label }
                                    as={ Link }
                                    to={ item.to }
                                >{ item.label }</Menu.Item>
                                ))
                            }
                            { (type === 'admin' || type === 'customer' || type === 'sampler') && (
                                <Menu.Item
                                    onClick={ () => dispatch(confirmLogout()) }
                                >
                                    Kirjaudu ulos
                                </Menu.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Responsive>
                <Responsive
                    as={ Container }
                    style={{ justifyContent: 'flex-end', paddingRight: '1.1rem'}}
                    minWidth={ mobileBreakpoint + 1 }
                >
                    { items.map((item) => (
                        <Menu.Item
                            key={ item.label }
                            as={ Link }
                            to={ item.to }
                        >{ item.label }</Menu.Item>
                        ))
                    }
                    { (type === 'admin' || type === 'customer' || type === 'sampler') && (
                        <Menu.Item
                            onKeyPress={ (ev) => {if(ev.key === "Enter"){
                                dispatch(confirmLogout());
                            } } }
                            tabIndex={0}
                            onClick={ () => dispatch(confirmLogout()) }
                        >
                            Kirjaudu ulos
                        </Menu.Item>
                    )}
                </Responsive>
                <Confirm
                    open={ showConfirmLogout }
                    content={ `Haluatko varmasti kirjautua ulos?`}
                    confirmButton="Kirjaudu ulos"
                    onConfirm={ handleLogout }
                    cancelButton="Peruuta"
                    onCancel={ () => dispatch(cancelLogout()) }
                />
            </Menu>
    )
}
