import React, { useState } from "react";
import {
  Form,
  Segment
} from "semantic-ui-react";

/**
 * Form component to create a set of user search query parameters
 * @param {object} props Component props
 * @param {object} props.defaultFilters The default form field values
 * @param {object} props.onSubmit Form submit handler
 */
export function UserFilterForm({
  defaultFilters,
  onSubmit
}) {

  const initialState =  {
    string: ''
  };

  const [filters, setFilters] = useState({
    ...initialState,
    ...defaultFilters
  });

  function handleUpdate(event, data) {
    const name = data.name;
    setFilters({
      ...filters,
      [name]: data.value
    });
  }

  return (
    <Segment
      vertical
    >
      <Form
        onSubmit={ (e) => {
            e.preventDefault();
            onSubmit(filters);
        }}
      >
        {/* <Header
          as="h4"
          style={{ marginTop: 0 }}
        >Haku</Header> */}
            <Form.Input
              label="Haku"
              placeholder="Kirjoita hakusana..."
              width={7}
              name="string"
              value={ filters.string || '' }
              onChange={ handleUpdate }
              maxLength="100"
              // icon={ filters.string ?
              //   <Icon
              //     name="close"
              //     circular
              //     inverted
              //     link
              //     onClick={
              //       (event) => handleUpdate(event, { name: 'string', value: '' })
              //     }
              //   /> : false
              // }
            />
          <Form.Group>
            <Form.Button
              type="button"
              size="mini"
              primary
              onClick={ () => onSubmit(filters)}
            >
              Hae
            </Form.Button>
            <Form.Button
                type="button"
                size="mini"
                onClick={ (event) => {
                  setFilters(initialState);
                  onSubmit(initialState);
                  event.stopPropagation();
                } }
              >
              Tyhjennä haku
            </Form.Button>
          </Form.Group>

      </Form>
    </Segment>
  );
}
