import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Container,
  Grid,
  Header,
  Segment,
  Divider
} from "semantic-ui-react";
import { updateUser } from '../../actions/users';
import { fetchUser, clearUser } from '../../actions/users';
import { PageContent } from "../../components/PageContent";
import { useState } from "react";
import { InfoModal } from "../../components/InfoModal";
import { AppLoader } from '../../components/AppLoader';
import { UserProfileForm } from "../../components/UserProfileForm";
import { useEffect } from "react";
import { routes } from "../../router/routes";
import { DeleteAccountSection } from "../../components/DeleteAccountSection";
import { deleteCustomerProfile } from "../../actions/auth";


/**
 * Admin single user page with user profile
 */
export function SingleUser() {

  const [editUser, setEditUser] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  const user = useSelector((state) => state.users.user);
  const isFetching = useSelector((state) => state.users.isFetching);

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchUser(id));
    }
    return function cleanup() {
        dispatch(clearUser());
    }
  }, [dispatch, id]);


  async function handleUpdateProfile(update) {
    try {
      await dispatch(updateUser(id, update));
      setEditUser(false);
      setShowConfirmation(true);
    } catch(error) {
      // Let this component know it failed
    }
  }

  function handleDeleteConfirmClose() {
    history.push(routes.ADMIN_USER_VIEW)
  }

  async function handleDeleteAccount() {
    try {
      await dispatch(deleteCustomerProfile(id));
      setShowDeleteConfirmation(true);
    } catch(error) {
      // Let this component know it failed
    }
  }

  return (
    <Container className="page-wrapper">
      <AppLoader
        active={ isFetching }
        page={ true }
      />
      <PageContent>
          <Grid.Row>
              <Grid.Column width={ 9 }>
                <Header
                  size='huge'
                  color='yellow'>
                    Yritystilin haltija
                </Header>
              </Grid.Column>
              <Grid.Column
                textAlign="right"
                width={ 7 }>
                <Button
                  onClick={ history.goBack }>
                    Takaisin
                </Button>
              </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={ 16 }>
              <Segment
                className="segment-rectangle"
                padded="very"
              >
                <Header as="h2">Asiakkaan tiedot</Header>
                { user &&
                    <UserProfileForm
                        editProfile={ editUser }
                        onCancel={ () => setEditUser(false) }
                        user={ user }
                        onUpdate={ handleUpdateProfile }
                    />
                }
                { user && !editUser &&
                    <Button
                        primary
                        onClick={ () => setEditUser(true)}>
                        Muokkaa tietoja
                    </Button>
                }
                <Divider />
                <DeleteAccountSection
                  onSubmit={ handleDeleteAccount }
                  type="admin"
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>

      </PageContent>
      <InfoModal
          content={ 'Yritystilin tiedot tallennettu' }
          open={ showConfirmation }
          onClose={  () => setShowConfirmation(false) }
          onConfirm={ () => setShowConfirmation(false) }
        />
      <InfoModal
          content={ 'Yritystili on poistettu' }
          open={ showDeleteConfirmation }
          onClose={  handleDeleteConfirmClose }
          onConfirm={ handleDeleteConfirmClose }
        />
    </Container>
  );
}
