import React from 'react';
import {
    Header,
    Grid
} from "semantic-ui-react";
import { fieldNames } from '../../enums';

export function OrderGeneralView({ order }) {
    const {
        additionalEmails,
        updatedAt,
        submittedAt,
        customerName,
        businessId,
        contactName,
        contactEmail,
        contactPhone,
        billingAddress,
        deliveryType,
        status,
        additionalInformation
    } = order;

    const updatedAtDate = new Date(updatedAt);
    const submittedAtDate = new Date(submittedAt);

    return (
        <Grid padded="vertically">
            <Header as="h2">Tilauksen tiedot</Header>
            <Grid.Row columns="2">
                <Grid.Column>
                    <label>{ fieldNames.general.SUBMIT_DATE }</label>
                    <p>{ submittedAtDate.toLocaleDateString('fi-FI') }</p>
                </Grid.Column>
                { status === 'COMPLETED' &&
                    <Grid.Column>
                        <label>{ fieldNames.general.COMPLETED_DATE }</label>
                        <p>{ updatedAtDate.toLocaleDateString('fi-FI') || '-' }</p>
                    </Grid.Column>
                }
            </Grid.Row>
            <Grid.Row columns="2">
                <Grid.Column>
                    <label>{ fieldNames.general.CUSTOMER_NAME }</label>
                    <p>{ customerName }</p>
                </Grid.Column>
                <Grid.Column>
                    <label>{ fieldNames.general.BUSINESS_ID }</label>
                    <p>{ businessId || '-' }</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
                <Grid.Column>
                    <label>{ fieldNames.general.CONTACT_NAME }</label>
                    <p>{ contactName }</p>
                </Grid.Column>
                <Grid.Column>
                    <label>{ fieldNames.general.CONTACT_EMAIL }</label>
                    <p>{ contactEmail }</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
                <Grid.Column>
                    <label>{ fieldNames.general.ADDITIONAL_EMAILS_READONLY }</label>
                    { additionalEmails.length > 0 ? additionalEmails.map((email, index) => <p key={ index }>{ email }</p>) : <p>-</p> }
                </Grid.Column>
                <Grid.Column>
                    <label>{ fieldNames.general.CONTACT_PHONE }</label>
                    <p>{ contactPhone }</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
                <Grid.Column>
                    <label>{ fieldNames.general.BILLING_ADDRESS }</label>
                    <p>{ billingAddress }</p>
                </Grid.Column>
                <Grid.Column>
                    <label>{ fieldNames.general.ADDITIONAL_INFORMATION }</label>
                    <p>{ additionalInformation || '-' }</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="1">
                <Grid.Column>
                    <label>{ fieldNames.general.DELIVERY_TYPE }</label>
                    <p>{ deliveryType }</p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}