import { STORE_UNREGISTERED_ORDER, CLEAR_UNREGISTERED_ORDER } from '../actions/actions'

export const unregisteredOrder = (
    state = {},
    action
) => {
    switch (action.type) {
        case STORE_UNREGISTERED_ORDER: {
            const { order } = action;
            return {
                ...order
            };
        }
        case CLEAR_UNREGISTERED_ORDER: {
            return {};
        }
        default: {
            return state;
        }
    }
}