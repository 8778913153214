import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Checkbox,
    Form,
    Header
} from "semantic-ui-react";
import { updateOrder } from '../../actions/order';
import { getFormFieldValue } from '../../utils';
import { OrderType, labels } from '../../enums';

/**
 * Modal component for displaying a form to add sample items to an order
 * @param {object} props Component props
 * @param {object} props.errors Form field errors.
 * @param {function} props.onBlur Form field blur event handler.
 * @param {number} props.orderTypeId Indicates the order type.
 */
export function MicrobeInfo({
    errors,
    onBlur,
    orderTypeId
}) {

    const threeDays = useSelector((state) => state.order.threeDays);
    const petriDishTemperature = useSelector((state) => state.order.petriDishTemperature);
    const dispatch = useDispatch();

    function handleChange(event, data) {
        const name = data.name;
        const value = getFormFieldValue(event, data);
        dispatch(updateOrder({ [name]: value }));
    }

    return (
        <>
        <Header as="h3">Mikrobiologisen tutkimuksen lisätiedot</Header>
            { orderTypeId !== OrderType.VOLATILE_MICROBE &&
                <Form.Group widths='equal'>
                    <Form.Field>
                            <Checkbox
                                name="threeDays"
                                checked={ threeDays}
                                onChange={ handleChange }
                                label={ labels[orderTypeId] }
                            />
                    </Form.Field>
                </Form.Group>
            }
            { orderTypeId === OrderType.VOLATILE_MICROBE &&
                <Form.Group>
                    <Form.Field>
                        <Form.Select
                            required
                            label="Elatusainemaljoja on säilytetty ennen laboratorioon toimittamista:"
                            error={ errors.petriDishTemperature }
                            value={ petriDishTemperature || '' }
                            name="petriDishTemperature"
                            onChange={ handleChange }
                            onBlur={ onBlur }
                            placeholder='Valitse lämpötila'
                            options={[
                                {
                                    text: 'huoneenlämmössä',
                                    value: 1
                                },
                                {
                                    text: 'viileässä',
                                    value: 2
                                }
                            ]}
                        />
                    </Form.Field>
                </Form.Group>
            }
        </>
    )
}