import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import {
    Form,
    Header
} from "semantic-ui-react";
import { isEmpty } from 'lodash';
import { updatePassword } from '../actions/auth';
import { validatePasswordUpdate } from '../validation/validate';
import { InfoModal } from "./InfoModal";

/**
 * User profile section with a password update feature
 */
export function UpdatePasswordForm() {
    const [previousPassword, setPreviousPassword] = useState();
    const [password, setPassword] = useState();
    const [repeatPassword, setRepeatPassword] = useState();
    const [errors, setErrors] = useState({});
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

    const dispatch = useDispatch();

    async function handleSubmit() {
        const data = {
            previousPassword,
            password,
            repeatPassword
        };
        const isValid = validate(data);
        if (isValid) {
            const update = {
                password,
                previousPassword
            };
            setPreviousPassword();
            setPassword();
            setRepeatPassword();
            try {
                await dispatch(updatePassword(update));
                setShowPasswordConfirmation(true);
            } catch(error) {
                // Let the compnent know it failed
            }
        }
    }

    function handleBlur() {
        if (!isEmpty(errors)) {
            const passwordUpdate = {
                previousPassword,
                password,
                repeatPassword
            }
            validate(passwordUpdate);
        }
    }

    function validate(update) {
        const errors = validatePasswordUpdate(update);
        if (errors) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
            return true;
        }
    }

    return (
        <>
            <Header>Vaihda salasana</Header>
            <Form>
                <Form.Group widths="2">
                    <Form.Input
                        fluid
                        type="password"
                        error={ errors.previousPassword }
                        name="previousPassword"
                        value={ previousPassword || ''}
                        onChange={ (event, { value }) => setPreviousPassword(value) }
                        onBlur={ handleBlur }
                        label='Nykyinen salasana'
                        placeholder='Nykyinen salasana'
                        maxLength="100"
                    />
                </Form.Group>
                <Form.Group widths="2">
                    <Form.Input
                        fluid
                        type="password"
                        error={ errors.password }
                        name="password"
                        value={ password || '' }
                        onChange={ (event, { value }) => setPassword(value) }
                        onBlur={ handleBlur }
                        label='Uusi salasana'
                        placeholder='Uusi salasana'
                        maxLength="100"
                    />
                </Form.Group>
                <Form.Group widths="2">
                    <Form.Input
                        fluid
                        type="password"
                        error={ errors.repeatPassword }
                        name="repeatPassword"
                        value={ repeatPassword || '' }
                        onChange={ (event, { value }) => setRepeatPassword(value) }
                        onBlur={ handleBlur }
                        label='Uusi salasana uudelleen'
                        placeholder='Uusi salasana uudelleen'
                        maxLength="100"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Button
                        disabled={ !previousPassword || !password || !repeatPassword }
                        type="button"
                        onClick={ handleSubmit }
                        primary
                    >
                        Vaihda salasana
                    </Form.Button>
                </Form.Group>
            </Form>
            <InfoModal
                content={ 'Salasana vaihdettu' }
                open={ showPasswordConfirmation }
                onClose={  () => setShowPasswordConfirmation(false) }
                onConfirm={ () => setShowPasswordConfirmation(false) }
            />
        </>
    );
}