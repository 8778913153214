import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { capitalize, upperFirst } from 'lodash';
import {
  Button,
  Container,
  Grid,
  Header,
  Segment,
  Image,
  Confirm
} from "semantic-ui-react";
import { routes } from '../router/routes';
import { deleteOrder, downloadResult, fetchOrder, printOrder } from '../actions/orders';
import { OrderView } from '../components/OrderView';
import { PageContent } from '../components/PageContent';
import { AppLoader } from '../components/AppLoader';
import logo from '../assets/images/taklab-logo-tarkenne-musta.png';
import { InfoModal } from "../components/InfoModal";

/**
 * Component to display a single TAKLAB order
 * @param {object} props Component props
 */
function SingleOrderPage() {

  const [order, setOrder] = useState();
  const [orderToDelete, setOrderToDelete] = useState();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  const isFetching = useSelector((state) => state.orders.isFetching);
  const isAdmin = useSelector((state) => state.auth.isAdmin);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData(id) {
      try {
        let order;
        order = await dispatch(fetchOrder(id));
        setOrder(order);
      } catch(error) {
        history.replace(routes.HOME);
      }
    }
    fetchData(id);
  }, [dispatch, history, id, isAdmin]);

  function handleDeleteOrder(id) {
    setOrderToDelete(id);
    setShowConfirmDelete(true);
  }

  async function handleConfirmDeleteOrder() {
    try {
      await dispatch(deleteOrder(orderToDelete));
      setShowConfirmDelete(false);
      setShowDeleteSuccessModal(true);
    } catch(error) {
    }
    setOrderToDelete(null);
  }

  function handleCloseDeleteSuccessModal() {
    history.goBack();
  }

  return (
    <Container className="page-wrapper">
        <AppLoader
          active={ isFetching }
          page={ true }
        />
        { order &&
          <PageContent
            maxWidth={ 1000 }
          >
            <Grid.Row>
              <Grid.Column width={ 6 }>
                <Header
                  size='huge'
                  color='yellow'>
                    Tilaus #{ order.id }
                </Header>
              </Grid.Column>
              <Grid.Column
                className="single-order-actions"
                width={ 10 }
                textAlign="right"
              >
                <Button
                  onClick={ history.goBack }>
                    Takaisin
                </Button>
                <Button
                    primary
                    onClick={ () => dispatch(printOrder(id)) }
                  >
                    Tulosta
                </Button>
                { order.status === 'COMPLETED' &&
                  <Button
                      primary
                      onClick={ () => dispatch(downloadResult(id, order.buildingAddress)) }
                    >
                      Lataa tulokset
                  </Button>
                }
                { isAdmin &&
                  <Button
                    negative
                    onClick={ () => handleDeleteOrder(id) }>
                      Poista
                  </Button>
                }
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={ 16 }>
                <Segment
                  className="segment-rectangle single-order-view"
                  padded
                >
                  <Grid stackable>
                    <Grid.Row>
                      <Grid.Column width={ 4 }>
                        <Image src={ logo } />
                      </Grid.Column>
                      <Grid.Column
                        width={ 12 }
                        textAlign="right"
                        verticalAlign={ "bottom" }
                      >
                        <Header as="h1">{ upperFirst(order.description) }</Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                    <OrderView order={ order } />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </PageContent>
        }
        { isAdmin &&
          <>
            <Confirm
                open={ showConfirmDelete }
                content={ `Haluatko varmasti poistaa tilauksen #${ orderToDelete }?`}
                confirmButton="Poista"
                onConfirm={ handleConfirmDeleteOrder }
                cancelButton="Peruuta"
                onCancel={ () => setShowConfirmDelete(false) }
            />
            <InfoModal
                content={ `Tilaus poistettu onnistuneesti.` }
                open={ showDeleteSuccessModal }
                onClose={  handleCloseDeleteSuccessModal }
                onConfirm={ handleCloseDeleteSuccessModal }
            />
          </>
        }
    </Container>
  );
}

export const SingleOrder = connect(null)(SingleOrderPage);