import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Container,
  Grid,
  Header,
  Segment,
  Divider
} from "semantic-ui-react";
import { deleteProfile, updateProfile, logoutClient } from '../actions/auth';
import { PageContent } from "../components/PageContent";
import { useState } from "react";
import { routes } from '../router/routes';
import { InfoModal } from "../components/InfoModal";
import { AppLoader } from '../components/AppLoader';
import { UpdatePasswordForm } from "../components/UpdatePasswordForm";
import { UserProfileForm } from "../components/UserProfileForm";
import { DeleteAccountSection } from "../components/DeleteAccountSection";
import { useHistory } from "react-router-dom";
import { LogoutSection } from "../components/LogoutSection";

/**
 * User profile page component.
 */
export function UserProfile() {

  const [editProfile, setEditProfile] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const isFetching = useSelector((state) => state.auth.isFetching);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const history = useHistory();

  async function handleUpdateProfile(update) {
    try {
      await dispatch(updateProfile(update));
      setEditProfile(false);
      setShowConfirmation(true);
    } catch(error) {
      // Let this component know it failed
    }
  }

  async function handleDeleteAccount() {
    try {
      await dispatch(deleteProfile());
      setShowDeleteConfirmation(true);
    } catch(error) {
      // Let this component know it failed
    }
  }

  function handleDeleteConfirmClose() {
    localStorage.removeItem('token');
    dispatch(logoutClient());
    history.push(routes.HOME)
  }

  return (
    <Container className="page-wrapper">
      <AppLoader
        active={ isFetching }
        page={ true }
      />
      <PageContent>
          <Grid.Row>
              <Grid.Column width={ 9 }>
                <Header
                  size='huge'
                  color='yellow'>
                    Yritystilin tiedot
                </Header>
              </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={ 16 }>
              <Segment
                className="segment-rectangle"
                padded="very"
              >
              <UserProfileForm
                editProfile={ editProfile }
                onCancel={ () => setEditProfile(false) }
                user={ user }
                onUpdate={ handleUpdateProfile }
              />
              { !editProfile &&
                <Button
                  primary
                  onClick={ () => setEditProfile(true)}>
                      Muokkaa tietoja
                </Button>
              }
              <Divider />
              <LogoutSection />
              <Divider />
              <UpdatePasswordForm />
              <Divider />
              <DeleteAccountSection onSubmit={ handleDeleteAccount } />
              </Segment>
            </Grid.Column>
          </Grid.Row>
      </PageContent>
      <InfoModal
          content={ 'Yritystilin tiedot tallennettu' }
          open={ showConfirmation }
          onClose={  () => setShowConfirmation(false) }
          onConfirm={ () => setShowConfirmation(false) }
        />
      <InfoModal
          content={ 'Yritystilisi on poistettu' }
          open={ showDeleteConfirmation }
          onClose={  handleDeleteConfirmClose }
          onConfirm={ handleDeleteConfirmClose }
        />
    </Container>
  );
}
