import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Header, Button, Confirm } from 'semantic-ui-react';
import { logout } from '../actions/auth';
import { routes } from '../router/routes';
import { useHistory } from 'react-router-dom';

/**
 * User profile section with logout all devices feature
 * @param {object} props Component props
 * @param {function} props.onClose Modal close handler
 * @param {function} props.onLogin Handler to run after a successful login
 * @param {boolean} props.open Indicates whether to show the modal
 */
export function LogoutSection() {

    const history = useHistory();
    const dispatch = useDispatch()

    const [showConfirmLogout, setShowConfirmLogout] = useState(false);

    async function handleLogout() {
        try {
            const route = routes.HOME;
            await dispatch(logout(true));
            history.push(route);
        } catch(error) {
            // Just to let this component know it failed
        }
    }

    return (
        <>
            <Header>Kirjaudu ulos kaikilta laitteilta</Header>
            <Button
                primary
                onClick={ () => setShowConfirmLogout(true) }
            >
                Kirjaudu ulos kaikilta laitteilta
            </Button>
            <Confirm
                open={ showConfirmLogout }
                content={ `Haluatko varmasti kirjautua ulos kaikilta laitteilta?`}
                confirmButton="Kirjaudu ulos"
                onConfirm={ handleLogout }
                cancelButton="Peruuta"
                onCancel={ () => setShowConfirmLogout(false) }
            />
        </>
    )
}