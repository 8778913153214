import { businessId, email, required } from './rules';
import { omit } from 'lodash';

export const constraints = {
    email: {
        presence: {
            ...required
        },
        ...email
    },
    password: {
        presence: {
            ...required
        },
        length: {
            minimum: 6,
            message: 'Salasanan pitää olla vähintään 6 merkin mittainen'
        }
    },
    repeatPassword: {
        presence: {
            ...required
        },
        equality: {
            attribute: 'password',
            message: 'Salasanat eivät täsmää'
        }
    },
    companyName: {
        presence: {
            ...required
        }
    },
    businessId: {
        presence: {
            ...required
        },
        ...businessId
    },
    billingAddress: {
        presence: {
            ...required
        }
    },
    phone: {
        presence: {
            ...required
        }
    },
    userName: {
        presence: {
            ...required
        }
    }    
}

export const updateConstraints = omit(constraints, ['password', 'repeatPassword']);

export const samplerUpdateConstraints = omit(constraints, ['password','repeatPassword','billingAddress','businessId','companyName']);

export const samplerSignupConstraints  = omit(constraints, ['billingAddress','businessId','companyName','email']);

export const passwordUpdateConstraints = {
    previousPassword: {
        presence: {
            ...required
        }
    },
    password: {
        presence: {
            ...required
        },
        length: {
            minimum: 6,
            message: 'Salasanan pitää olla vähintään 6 merkin mittainen'
        }
    },
    repeatPassword: {
        presence: {
            ...required
        },
        equality: {
            attribute: 'password',
            message: 'Salasanat eivät täsmää'
        }
    },
}

export const initPasswordResetConstraints = {
    email: {
        presence: {
            ...required
        },
        ...email
    }
}

export const passwordResetConstraints = {
    password: {
        presence: {
            ...required
        },
        length: {
            minimum: 6,
            message: 'Salasanan pitää olla vähintään 6 merkin mittainen'
        }
    }
}