import React from 'react';
import {
    Form,
    Header
} from "semantic-ui-react";
import { IconWithTooltip } from '../IconWithTooltip';
import { fieldNames } from '../../enums';
import { AdditionalEmailsInput } from './AdditionalEmailsInput';

/**
 * Order form section with the building data form fields
 * @param {object} props Component props
 * @param {object} props.errors Form field errors.
 * @param {function} props.isAuthenticated Indicates whether the
 * @param {number} props.onBlur Form field blur event handler.
 * @param {number} props.onChange Form field change event handler.
 * @param {number} props.order The order to edit.
 */
export function OrderGeneral({
    errors,
    isAuthenticated,
    onBlur,
    onChange,
    order
}) {

    const {
        additionalEmails,
        customerName,
        businessId,
        contactName,
        contactEmail,
        contactPhone,
        billingAddress,
        additionalInformation
    } = order;

    return (
        <>
        <Header as="h2">Tilauksen tiedot</Header>
        <Form.Group widths='equal'>
            <Form.Input
                required
                readOnly={ isAuthenticated }
                fluid
                error={ errors.customerName }
                name="customerName"
                value={ customerName || '' }
                onChange={ onChange }
                onBlur={ onBlur }
                label={
                    <>
                        <label style={{ display: 'inline' }}>{ fieldNames.general.CUSTOMER_NAME}</label>
                        <IconWithTooltip
                            link
                            tooltip={ fieldNames.general.CUSTOMER_NAME }
                            name="help circle"
                        />
                    </>
                }
                placeholder={ fieldNames.general.CUSTOMER_NAME }
                maxLength="100"
            />
            <Form.Input
                fluid
                readOnly={ isAuthenticated }
                error={ errors.businessId }
                name="businessId"
                value={ businessId || ''}
                onChange={ onChange }
                onBlur={ onBlur }
                label={ fieldNames.general.BUSINESS_ID }
                placeholder={ fieldNames.general.BUSINESS_ID }
                maxLength="15"
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Input
                required
                fluid
                error={ errors.contactName }
                name="contactName"
                value={ contactName || '' }
                onChange={ onChange }
                onBlur={ onBlur }
                label={ fieldNames.general.CONTACT_NAME }
                placeholder={ fieldNames.general.CONTACT_NAME }
                maxLength="100"
            />
            <Form.Input
                required
                fluid
                error={ errors.contactEmail }
                name="contactEmail"
                value={ contactEmail || '' }
                onChange={ onChange }
                onBlur={ onBlur }
                type="email"
                label={ fieldNames.general.CONTACT_EMAIL }
                placeholder={ fieldNames.general.CONTACT_EMAIL }
                maxLength="100"
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <AdditionalEmailsInput
                defaultValue={ additionalEmails }
                error={ errors.additionalEmails }
                name="additionalEmails"
                onChange={ onChange }
                onBlur={ onBlur }
                label={ fieldNames.general.ADDITIONAL_EMAILS }
                placeholder={ fieldNames.general.ADDITIONAL_EMAILS }
            />
            <Form.Input
                required
                fluid
                error={ errors.contactPhone }
                name="contactPhone"
                value={ contactPhone || '' }
                onChange={ onChange }
                onBlur={ onBlur }
                label={ fieldNames.general.CONTACT_PHONE }
                placeholder={ fieldNames.general.CONTACT_PHONE }
                maxLength="50"
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.TextArea
                required
                rows="5"
                readOnly={ isAuthenticated }
                error={ errors.billingAddress }
                name="billingAddress"
                value={ billingAddress || '' }
                onChange={ onChange }
                onBlur={ onBlur }
                label={ fieldNames.general.BILLING_ADDRESS }
                placeholder={ fieldNames.general.BILLING_ADDRESS }
                maxLength="255"
            />
            <Form.TextArea
                rows="5"
                readOnly={ false }
                name="additionalInformation"
                value={ additionalInformation || '' }
                onChange={ onChange }
                onBlur={ onBlur }
                label={ fieldNames.general.ADDITIONAL_INFORMATION }
                placeholder={ fieldNames.general.ADDITIONAL_INFORMATION }
                maxLength="600"
            />
        </Form.Group>
        </>
    )
}
