import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  useHistory,
  useLocation,
  Link
} from "react-router-dom";
import {
  Container,
  Grid,
  Header
} from "semantic-ui-react";
import {
    login
} from '../../actions/auth';
import { LoginForm } from "../../components/LoginForm";
import { AppLoader } from "../../components/AppLoader";
import { routes } from "../../router/routes";

/**
 * Admin login page
 */
export function AdminLogin() {
  const location = useLocation();
  const history = useHistory();

  const isFetching = useSelector((state) => state.auth.isFetching);
  const dispatch = useDispatch();

  async function handleLogin(credentials) {
    const { from } = location.state || { from: { pathname: routes.ADMIN } };
    try {
      await dispatch(login(credentials, true));
      history.replace(from);
    } catch(error) {
      // The error's been handled, this is just to let login page know about it
    }
  }

  return (
    <Container>
      <AppLoader
        active={ isFetching }
        page={ true }
      />
      <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header size="huge" color="yellow" textAlign="center">
            Kirjaudu TAKLAB-järjestelmänhallintaan
          </Header>
          <LoginForm onSubmit={ handleLogin } />
          <p style={{ marginTop: '1rem' }}>
            <Link to={ routes.FORGOT_PASSWORD }>
                Unohtuiko salasana? Uusi salasanasi täällä
            </Link>
          </p>
        </Grid.Column>
      </Grid>
    </Container>
  );
}
