import React from "react";
import { Form, Header } from "semantic-ui-react";
import {
    fieldNames,
    flecVocDeliveryTypeOptions,
    flecVocFetchingSites,
    FlecVocDeliveryType,
} from "../../enums";

/**
 * Order form section with the building data form fields
 * @param {object} props Component props
 * @param {object} props.errors Form field errors.
 * @param {function} props.metadata Order metadata to use for displaying form field options.
 * @param {number} props.onBlur Form field blur event handler.
 * @param {number} props.onChange Form field change event handler.
 * @param {number} props.order The order to edit.
 * @param {number} props.orderTypeId Type of the order to edit.
 */

export function FlecVoc({ errors, onBlur, onChange, order }) {
    return (
        <>
            <Header as="h2">Flec-laitteiston toimitus</Header>
            <Form.Group widths="equal">
                <Form.Input
                    required
                    fluid
                    error={errors.flecVocSampleCount}
                    name="flecVocSampleCount"
                    type="number"
                    step="1"
                    min="1"
                    value={order.flecVocSampleCount || ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    label={fieldNames.voc.FLEC_VOC_SAMPLE_COUNT}
                    placeholder={fieldNames.voc.FLEC_VOC_SAMPLE_COUNT}
                    maxLength="10"
                />
                <Form.Select
                    required
                    fluid
                    error={errors.flecVocDeliveryTypeId}
                    name="flecVocDeliveryTypeId"
                    value={order.flecVocDeliveryTypeId}
                    onChange={onChange}
                    onBlur={onBlur}
                    label={fieldNames.voc.FLEC_VOC_DELIVERY_TYPE}
                    placeholder={fieldNames.voc.FLEC_VOC_DELIVERY_TYPE}
                    options={flecVocDeliveryTypeOptions}
                />
            </Form.Group>
            <Form.Group widths="2">
                <Form.TextArea
                    rows="5"
                    disabled={order.flecVocDeliveryTypeId !== FlecVocDeliveryType.TAKLAB_TOIMITTAA}
                    required
                    fluid
                    error={errors.flecVocEquipmentDeliveryAddress}
                    name="flecVocEquipmentDeliveryAddress"
                    value={order.flecVocEquipmentDeliveryAddress || ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    label={fieldNames.voc.FLEC_VOC_EQUIPMENT_DELIVERY_ADDRESS}
                    placeholder={fieldNames.voc.FLEC_VOC_EQUIPMENT_DELIVERY_ADDRESS}
                    maxLength="255"
                />
                <Form.Select
                    disabled={order.flecVocDeliveryTypeId !== FlecVocDeliveryType.ASIAKAS_NOUTAA}
                    required
                    fluid
                    error={errors.flecVocFetchingSiteId}
                    name="flecVocFetchingSiteId"
                    value={order.flecVocFetchingSiteId}
                    onChange={onChange}
                    onBlur={onBlur}
                    label={fieldNames.voc.FLEC_VOC_FETCHING_SITE}
                    placeholder={fieldNames.voc.FLEC_VOC_FETCHING_SITE}
                    options={flecVocFetchingSites}
                />
            </Form.Group>
        </>
    );
}
