import {
    CLEAR_ORDER,
    CREATE_ORDER,
    UPDATE_ORDER,
    EDIT_ORDER,
    ADD_ORDER_ITEM,
    DELETE_ORDER_ITEM,
    UPDATE_ORDER_ITEM,
    MIXIN_ORDER
} from '../actions/actions'
import { pickBy, omit } from 'lodash';
import { OrderType } from '../enums';

const orderTemplate = {
    items: [],
    additionalEmails: [],
    additionalInformation: ""
};

const omitFromEdit = [
    'buildingType',
    'buildingMaterial',
    'locationType',
    'description',
    'deliveryType',
    'ventilationType',
    'customerId',
    'sampleTypeLabel',
    'sampleTypeName'
];

const defaultOrder = {
    [OrderType.MATERIAL_FIBER]: {
        ...orderTemplate
    },
    [OrderType.VOLATILE_FIBER]: {
        ...orderTemplate
    },
    [OrderType.BRUSHING_FIBER]: {
        ...orderTemplate
    },
    [OrderType.MATERIAL_MICROBE]: {
        ...orderTemplate,
        threeDays: false
    },
    [OrderType.VOLATILE_MICROBE]: {
        ...orderTemplate,
        winterConditions: false,
        ventilationRunning: false
    },
    [OrderType.BRUSHING_MICROBE]: {
        ...orderTemplate,
        threeDays: false
    },
    [OrderType.MATERIAL_VOC]: {
        ...orderTemplate,
    },
    [OrderType.VOLATILE_VOC]: {
        ...orderTemplate
    },
    [OrderType.FLEC_VOC]: {
        ...orderTemplate
    }            
};

let orderIdCount = 1;

export const order = (
    state = orderTemplate,
    action
) => {
    switch (action.type) {
        case CLEAR_ORDER: {
            return {
                ...orderTemplate
            };
        }
        case CREATE_ORDER: {
            const {
                orderTypeId,
                user,
                unregisteredOrder
            } = action;
            let initialOrder = {
                ...defaultOrder[orderTypeId],
                ...unregisteredOrder,
                orderTypeId
            }
            if (user) {
                const {
                    companyName,
                    businessId,
                    billingAddress,
                    phone,
                    email,
                    userName
                } = user;
                initialOrder = {
                    ...initialOrder,
                    customerName: companyName,
                    contactEmail: email,
                    contactName: userName,
                    contactPhone: phone,
                    businessId,
                    billingAddress
                }
            }
            return {
                ...initialOrder
            }
        }
        case EDIT_ORDER: {
            let { order } = action;
            order = pickBy(order, (value) => {
                return value !== null;
            });
            order = omit(order, omitFromEdit);
            order.items = order.items.map(item =>{
                let editItem = omit(item, [
                    'orderId',
                    'sampleTypeLabel',
                    'sampleTypeName'
                ]);
                editItem = pickBy(editItem, (value) => {
                    if (Array.isArray(value)) {
                        return value.length > 0;
                    }
                    return value !== null;
                });
                return editItem;
            });
            console.log('edit order', order);
            return {
                ...order
            }
        }
        case MIXIN_ORDER: {
            const { user, order } = action;
            let mixinOrder = {
                ...order
            };
            if (user) {
                const {
                    companyName,
                    businessId,
                    billingAddress,
                    phone,
                    email
                } = user;
                mixinOrder = {
                    ...mixinOrder,
                    customerName: companyName,
                    contactEmail: email,
                    contactPhone: phone,
                    businessId,
                    billingAddress
                }
            }
            return {
                ...mixinOrder
            }
        }
        case UPDATE_ORDER: {
            const {
                update
            } = action;

            if ('buildingTypeId' in update && update.buildingTypeId !== 1) {
                update.buildingTypeStr = '';
            }
            if ('buildingMaterialId' in update && update.buildingMaterialId !== 1) {
                update.buildingMaterialStr = '';
            }

            return {
                ...state,
                ...update
            }
        }
        // If the order item has an id, update the item. Otherwise add a new order item.
        case ADD_ORDER_ITEM: {
            const { item } = action;
            let items;

            if (item.id) {
                const id = item.id;
                items = state.items.map(currentItem => {
                    if (currentItem.id === id) {
                        return {
                            ...item
                        };
                    }
                    return currentItem;
                })
            } else {
                item.id = orderIdCount++;
                items = [
                    ...state.items,
                    item
                ];
            }
            return {
                ...state,
                items
            }
        }
        case DELETE_ORDER_ITEM: {
            const { id } = action;
            const items = state.items.filter(item => item.id !== id);
            return {
                ...state,
                items
            }
        }
        case UPDATE_ORDER_ITEM: {
            const {
                id,
                update
            } = action;

            if ('previousWaterDamage' in update && !update.previousWaterDamage) {
                update.waterDamageDate = '';
            }
            const items = state.items.map(item => {
                if (item.id === id) {
                    return {
                        ...item,
                        ...update
                    };
                }
                return item;
            })
            return {
                ...state,
                items
            }
        }
        default: {
            return state;
        }
    }
}
