import React from 'react';
import { Form, TextArea } from 'semantic-ui-react';

export function AdditionalEmailsInput({
    defaultValue,
    onChange,
    ...rest
}) {
    return  (
        <Form.Field>
            <Form.TextArea
                maxLength="255"
                defaultValue={ defaultValue.join(',\n') }
                onChange={ (event, data) => {
                    let { value } = data;
                    value = value ? value.split(',')
                        .map(email => email.trim())
                        .filter(email => !!email)
                        : [];
                    onChange(event, {
                        ...data,
                        value
                    });
                }}
                { ...rest }
            />
        </Form.Field>
    );
}