import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-less/semantic.less'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import taklabStore from './store';
import './index.css';
import App from './App';
import { fetchMetadata } from './actions/metadata';
import { getMe } from './actions/auth';

function noop() {}

if (process.env.NODE_ENV !== 'development') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

taklabStore.dispatch(fetchMetadata());
if (localStorage.getItem('token')) {
    taklabStore.dispatch(getMe());
}

const jsx = (
    <Provider store={ taklabStore }>
        <App />
    </Provider>
);

ReactDOM.render(jsx , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

