import React from 'react';
import { connect } from 'react-redux';
import {
    Checkbox,
    Form,
    Header,
    Label
} from "semantic-ui-react";
import { fieldNames } from '../../../enums';
import { getSampleTypeLabels, isNumeric } from '../../../utils';

function MaterialItemComponent({
    item,
    sampleTypes
}) {
    return (
        <>
            <BasicData
                item={ item }
            />
            { item.sampleTypes && item.sampleTypes.length > 0 &&
                <Form.Group widths={2}>
                    <Form.Field>
                        <label>{ fieldNames.orderItem.SAMPLE_TYPE }</label>
                        <p>{ getSampleTypeLabels(item, sampleTypes).join('/') }</p>
                    </Form.Field>
                </Form.Group>
            }
            <Form.Field>
                <label>{ fieldNames.orderItem.DESCRIPTION }</label>
                <p>{ item.description }</p>
            </Form.Field>
        </>
    );
}

export const MaterialItem = connect((state) => {
    return {
        sampleTypes: state.metadata.data.sampleType
    }
})(MaterialItemComponent)

export function MaterialVocItem({ item, onChange }) {

    //console.log("item.spaceNumber");
    //console.log(item.spaceNumber);
    //console.log("typeof item.spaceNumber");
    //console.log(typeof item.spaceNumber);


    return (
        <>
            <BasicData
                item={ item }
            />
            <Form.Field>
                <label>{ fieldNames.orderItem.DESCRIPTION }</label>
                <p>{ item.description }</p>
            </Form.Field>
            <Form.Field>
                <label>{ fieldNames.orderItem.SPACE_NUMBER }</label>
                <p>{ isNumeric(item.spaceNumber) ? item.spaceNumber : '-' }</p>
            </Form.Field>
            <Form.Field>
                <label>{ fieldNames.orderItem.OTHER_NOTES }</label>
                <p>{ item.otherNotes ? item.otherNotes : "-" }</p>
            </Form.Field>            
        </>
    );
}

export function VolatileVocItem({ item, onChange }) {
    return (
        <>
            <BasicData
                item={ item }
            />
            <Form.Field>
                <label>{ fieldNames.orderItem.DESCRIPTION }</label>
                <p>{ item.description }</p>
            </Form.Field>
            <Form.Group widths={2}>
                <Form.Field>
                    <label>{ fieldNames.orderItem.PIPE_NUMBER }</label>
                    <p>{ item.pipeNumber }</p>
                </Form.Field>
                <Form.Field>
                    <label>{ fieldNames.orderItem.SAMPLING_TIME }</label>
                    <p>{ item.samplingTime }</p>
                </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
                <Form.Field>
                    <label>{ fieldNames.orderItem.PUMP_NUMBER }</label>
                    <p>{ item.pumpNumber }</p>
                </Form.Field>
                <Form.Field>
                    <label>{ fieldNames.orderItem.PUMP_FLOW }</label>
                    <p>{ item.pumpFlow }</p>
                </Form.Field>
            </Form.Group>
            <Form.Field>
                <label>{ fieldNames.orderItem.OTHER_NOTES }</label>
                <p>{ item.otherNotes ? item.otherNotes : "-" }</p>
            </Form.Field>            
        </>
    );
}

export function VolatileFiberItem({ item, onChange }) {
    return (
        <>
            <BasicData
                item={ item }
            />
            <Form.Group widths={2}>
                <Form.Field>
                    <label>{ fieldNames.orderItem.SAMPLE_LOCATION }</label>
                    <p>{ item.sampleLocation }</p>
                </Form.Field>
                <Form.Field>
                    <label>{ fieldNames.orderItem.SAMPLING_PERIOD }</label>
                    <p>{ item.samplingPeriod || '-' }</p>
                </Form.Field>
            </Form.Group>
            <Form.Group widths={ 2 }>
                <Form.Field>
                    <label>{ fieldNames.orderItem.AIR_FLOW }</label>
                    <p>{ item.airFlow || '-'}</p>
                </Form.Field>
                <Form.Field>
                    <label>{ fieldNames.orderItem.AIR_VOLUME }</label>
                    <p>{ item.airVolume }</p>
                </Form.Field>
            </Form.Group>
        </>
    );
}

export function VolatileMicrobeItem({ item, onChange }) {
    const {
        sampleLocation,
        samplingPeriod,
        sampleHeight,
        roomTemperature,
        extTemperature,
        previousWaterDamage,
        waterDamageDate,
        personsAnimals,
        relativeHumidity,
        additionalInfo,
        isControl
    } = item;
    return (
        <>
            <BasicData
                item={ item }
            />
            <Form.Group widths={2}>
                <Form.Field>
                    <label>{ fieldNames.orderItem.SAMPLE_LOCATION }</label>
                    <p>{ sampleLocation }</p>
                </Form.Field>
                <Form.Field>
                    <label>{ fieldNames.orderItem.SAMPLING_PERIOD }</label>
                    <p>{ samplingPeriod }</p>
                </Form.Field>
            </Form.Group>
            <Header as="h3">Mahdollisiin mikrobipitoisuuksiin vaikuttavat taustatekijät</Header>
            <Form.Group widths="equal">
                <Form.Field>
                    <label>{ fieldNames.orderItem.ROOM_TEMPERATURE }</label>
                    <p>{ roomTemperature }</p>
                </Form.Field>
                <Form.Field>
                    <label>{ fieldNames.orderItem.EXT_TEMPERATURE }</label>
                    <p>{ extTemperature }</p>
                </Form.Field>
                <Form.Field>
                    <label>{ fieldNames.orderItem.RELATIVE_HUMIDITY }</label>
                    <p>{ relativeHumidity }</p>
                </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Field>
                    <label>{ fieldNames.orderItem.SAMPLE_HEIGHT }</label>
                    <p>{ sampleHeight }</p>
                </Form.Field>
                <Form.Field>
                    <label>{ fieldNames.orderItem.PERSONS_ANIMALS }</label>
                    <p>{ personsAnimals || '-' }</p>
                </Form.Field>
            </Form.Group>
            <Form.Group widths={1}>
                <Form.Field>
                    <label>
                        { fieldNames.orderItem.ADDITIONAL_INFO }
                    </label>
                    <p>{ additionalInfo || '-' }</p>
                </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
                <Form.Field>
                    <label>Aikaisemmat vesivauriot</label>
                    <Checkbox
                        readOnly
                        checked={ previousWaterDamage }
                        label={ fieldNames.orderItem.PREVIOUS_WATER_DAMAGE }
                    />
                </Form.Field>
                <Form.Field>
                    <label>{ fieldNames.orderItem.WATER_DAMAGE_DATE }</label>
                    <p>{ waterDamageDate || '-' }</p>
                </Form.Field>
            </Form.Group>
        </>
    );
}

export function BrushingMicrobeItemComponent({ item, sampleTypes }) {
    const itemSampleTypes = getSampleTypeLabels(item, sampleTypes);

    return (
        <>
            <BasicData
                item={ item }
            />
            <Form.Group widths={2}>
                <Form.Field>
                    <label>{ fieldNames.orderItem.SAMPLE_TYPE }</label>
                    <p>{ itemSampleTypes.join('/') }</p>
                </Form.Field>
                <Form.Field>
                    <label>{ fieldNames.orderItem.SURFACE_AREA } cm<sup>2</sup></label>
                    <p>{ item.surfaceArea }</p>
                </Form.Field>
            </Form.Group>
            <Form.Field>
                <label>{ fieldNames.orderItem.DESCRIPTION }</label>
                <p>{ item.description }</p>
            </Form.Field>
        </>
    );
}

export const BrushingMicrobeItem = connect((state) => {
    return {
        sampleTypes: state.metadata.data.sampleType
    }
})(BrushingMicrobeItemComponent)

function BasicData({
    item
}) {
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                { item.isControlSample &&
                    <Label color="yellow" size="large" className="is-control-label">Vertailunäyte</Label>
                }
            </div>
            <Form.Group widths={2}>
                <Form.Field>
                    <label>{ fieldNames.orderItem.SAMPLE_ID }</label>
                    <p>{ item.sampleId }</p>
                </Form.Field>
                <Form.Field>
                    <label>{ fieldNames.orderItem.DATE }</label>
                    <p>{ item.date.toLocaleDateString('fi-FI') }</p>
                </Form.Field>
            </Form.Group>
        </>


    )
}
